import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { PostCpfCnsRequest } from '../../requests/orchestrator-integration/post-cpf-cns.request';
import { UBSCreatePatientRequest } from '../../requests/orchestrator-integration/ubs-create-patient.request';
import { UBSSearchPatientRequest } from '../../requests/orchestrator-integration/ubs-search-patient.request';
import { PostCpfCnsResponse } from '../../responses/orchestrator-integration/post-cpf-cns.response';
import { UBSSearchResponse } from '../../responses/orchestrator-integration/ubs-search.response';
import { WebhookStruct } from '../../structs/Integration/webhook-struct';

@Injectable({
  providedIn: 'root'
})
export class WebhookCpfService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }
  private integrationKey: string = '4c5dcb33-dbb1-42a1-95d0-9d8af8cfca17'

  public getPatientByCpfCns(body: PostCpfCnsRequest, webhookStruct: WebhookStruct): Observable<PostCpfCnsResponse> {

    let uri = `api/v1/Webhook/search-by-cpf-cns`;

    body.url = webhookStruct.url;
    body.token = webhookStruct.token;

    return this.httpClient.post<PostCpfCnsResponse>(environment.urlApiOrchestratorIntegration + uri, body, this.addHeaderKey(webhookStruct.guid))
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatientSearchComplete(body: UBSSearchPatientRequest, webhookStruct: WebhookStruct): Observable<UBSSearchResponse> {

    let uri = `api/v1/Webhook/uBSSearchPatient`;

    body.url = webhookStruct.url;
    body.token = webhookStruct.token;

    return this.httpClient.post<UBSSearchResponse>(environment.urlApiOrchestratorIntegration + uri, body, this.addHeaderKey(webhookStruct.guid))
      .pipe(
        catchError(this.handleError)
      )
  }
}
