import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-patient-modal',
  templateUrl: './confirm-patient-modal.component.html',
  styleUrls: ['./confirm-patient-modal.component.css']
})
export class ConfirmPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmPatientModalComponent>) { }

  ngOnInit(): void {
  }

  clickConfirm() {
    this.matDialogRef.close({ confirm: true });
  }

  clickIsNotMe() {
    this.matDialogRef.close({ isNotMe: true });
  }

  reviewMyData() {
    this.matDialogRef.close({ review: true });
  }

  close() {
    this.matDialogRef.close();
  }
}