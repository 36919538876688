import { Component, Input, OnInit } from '@angular/core';
import { JourneyStageEnum } from '../../enum/journey-stage.enum';

@Component({
  selector: 'app-journey-stage' ,
  templateUrl: './journey-stage.component.html',
  styleUrls: ['./journey-stage.component.css']
})
export class JourneyStageComponent implements OnInit {

  constructor() { }
  public img1: string = `../assets/images/journey-step1.png`;
  public img2: string = `../assets/images/journey-step2.png`;
  public img3: string = `../assets/images/journey-step3.png`;
  public img4: string = `../assets/images/journey-step4.png`;

  @Input() journeyStageId: number;

  ngOnInit(): void { 
  }
}