import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cpf-not-finded-modal',
  templateUrl: './cpf-not-finded-modal.component.html',
  styleUrls: ['./cpf-not-finded-modal.component.css']
})
export class CPFNotFindedModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CPFNotFindedModalComponent>) { }

  ngOnInit(): void {
  }

  clickRegister() {
    this.matDialogRef.close({ register: true });
  }

  clickTryAgain() {
    this.matDialogRef.close();
  }
}