<div class="box-in-sharing ubs">
    <div class="checkbox-group">
        <div id="txt-decription">
            <p class="title" i18n="@@titleSharingResults">
                <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                    (click)="playSound()" />
                Envie seu resultado para o seu e-mail ou celular:
            </p>
            <form class="form form-content sharing-results" [formGroup]="model" (ngSubmit)="submit()">
                <div class="form-input">
                    <div class="input-icon">
                        <mat-icon mat-icon aria-hidden="false" aria-label="drafts">
                            <img src="assets/icons/email-icon.png" aria-hidden="false" aria-label="email"/>
                        </mat-icon>
                    </div>
                    <mat-form-field>
                        <p i18n="@@emailharingResults">E-mail:</p>
                        <input (keyup)="verifyInput()" matInput type="email" formControlName="email"
                            placeholder="email@exemplo">
                        <mat-error i18n="@@emailInvalid">E-mail inválido</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-input">
                    <div class="input-icon">
                        <mat-icon mat-icon aria-hidden="false" aria-label="comment">
                            <img src="assets/icons/message-icon.png" aria-hidden="false" aria-label="email"/>
                        </mat-icon>
                    </div>
                    <mat-form-field>
                        <p i18n="@@phoneSharingResults">Celular (SMS):</p>
                        <input (keyup)="verifyInput()" matInput type="text" formControlName="phone" [mask]="masks.phone"
                            inputmode="numeric" placeholder="(00) 0 0000-0000">
                        <mat-error i18n="@@phoneInvalid">Celular inválido</mat-error>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="button-content">
            <button mat-flat-button class="block btn-send btn-ubs" (click)="submit()">
                <span *ngIf="!isLoading" i18n="@@btnSend">Enviar</span>
                <div>
                    <mat-icon aria-hidden="false" *ngIf="!isLoading">arrow_forward</mat-icon>
                </div>
                <div class="loading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                </div>
            </button>
        </div>
    </div>
</div>