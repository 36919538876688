import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { MeasurerComponent } from './pages/measurer/measurer.component';
import { AnamneseComponent } from './pages/anamnese/anamnese.component';
import { RegisterComponent } from './pages/register/register.component';
import { FinishComponent } from './pages/anamnese/finish/finish.component';

const routes: Routes = [
  { path: 'company/login', component: LoginComponent },
  { path: 'company/measure', component: MeasurerComponent },
  { path: 'company/register', component: RegisterComponent },
  { path: 'company/anamnese', component: AnamneseComponent },
  { path: 'company/anamnese/finish', component: FinishComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
