import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Masks } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { UbsErrorOutdatedDataModalComponent } from 'src/app/shared/components/ubs-error-outdated-data-modal/ubs-error-outdated-data-modal.component';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-birth-date-verifier',
  templateUrl: './birth-date-verifier.component.html',
  styleUrls: ['./birth-date-verifier.component.css']
})
export class BirthDateVerifierComponent implements OnInit, OnDestroy {

  private title: string = 'birth-date-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public listDates: number[] = [];
  public listRandomNumbers: number[] = [1,2,3,4,5];
  public yearDate: number;
  public tries: number = 0;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) 
      this.router.navigate(['/ubs/erro']);
    
    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.BirthDateRegister;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1) 
      this.router.navigate(['/ubs/erro']);
    
    this.model = this.formBuilder.group({
      birthDate: ['', [Validators.required]],
    });

    this.patientData = this.utilsUBSService.getPatientData();
    let birthDate = new Date(this.patientData.birthDate);
    this.yearDate = birthDate.getFullYear();
    this.randomDates();

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid) 
      return;
    
    this.audio.pause();
    let yearbirthDate = parseInt(this.model.get('birthDate').value);

    if (yearbirthDate == this.yearDate) {
      this.downtimeService.stopTimer();
      this.saveLastPage();
      (this.patientData.motherName)
        ? this.router.navigate([`ubs/mother-name-verifier`])
        : this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);
    } else {
      this.openErrorModal();
    }
  }

  playAudio(){
    this.audio.src = `../assets/audio/${environment.locale}/birth-date-confirm.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  openErrorModal() {
    this.audio.pause();
    const dialogRef = this.dialog.open(UbsErrorOutdatedDataModalComponent, {
      disableClose: true,
      data: {
        tryAgain: (this.tries < 1) ? true : false
      },
      panelClass: 'error-outdated-modal',
      backdropClass: 'backdrop-error-outdated'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result == 'finish') {
        this.downtimeService.stopTimer();
        this.utilsUBSService.removeListVisitidePages();
        this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
      } else {
        this.tries++;
        this.model.get('birthDate').setValue(null);
        this.randomDates();
      }
    });
  }
  
  randomDates() {
    this.listDates = [];
    this.listRandomNumbers = this.shuffleArray(this.listRandomNumbers);
    this.listDates.push(this.yearDate);
    this.listDates.push(this.yearDate - this.listRandomNumbers[0]);
    this.listDates.push(this.yearDate - this.listRandomNumbers[1]);
    this.listDates.push(this.yearDate + (Math.floor(Math.random() * 5)+1));
    this.listDates = this.shuffleArray(this.listDates);
  }

  shuffleArray(arr: number[]) {
    // Loop em todos os elementos
    for (let i = arr.length - 1; i > 0; i--) {
            // Escolhendo elemento aleatório
        const j = Math.floor(Math.random() * (i + 1));
        // Reposicionando elemento
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    // Retornando array com aleatoriedade
    return arr;
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}