import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetWebhookResponse } from '../../responses/integration/get-webhook.response';

@Injectable({
  providedIn: 'root'
})
export class WebhookService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getWebhook(idHealthUnit: number, idWebhookType: number): Observable<GetWebhookResponse> {
    
    let uri = `Webhook/idHealthUnit/${idHealthUnit}/idWebhookType/${idWebhookType}`;
    
    return this.httpClient.get<GetWebhookResponse>(environment.urlApiIntegration + uri)
    .pipe(
      catchError(this.handleError)
    )
  }
}
