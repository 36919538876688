import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { SyncMeasurerHashResponse } from '../../responses/sync-measurer/sync-measurer-hash.response';


@Injectable({
  providedIn: 'root'
})
export class SyncMeasurerHashService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getHash(): Observable<SyncMeasurerHashResponse> {

    return this.httpClient.get<SyncMeasurerHashResponse>(environment.urlApiSensors + "SyncMeasurerHash")
    .pipe(
      catchError(this.handleError)
    )
  }
}