<div class="journey-container">
    <div class="img-container">
        <img *ngIf="journeyStageId == 1" src="{{img1}}">
        <img *ngIf="journeyStageId == 2" src="{{img2}}">
        <img *ngIf="journeyStageId == 3" src="{{img3}}">
        <img *ngIf="journeyStageId == 4" src="{{img4}}">
    </div>

    <div class="img-label-container">
        <div class="img-label" [ngClass]="{'selected':journeyStageId == 1, 'concluded':journeyStageId > 1}">
            <span i18n>
                Identificação
            </span>
        </div>
        <div class="img-label" [ngClass]="{'selected':journeyStageId == 2, 'concluded':journeyStageId > 2}">
            <span i18n>
                Medir a pressão arterial
            </span>
        </div>
        <div class="img-label" [ngClass]="{'selected':journeyStageId == 3, 'concluded':journeyStageId > 3}">
            <span i18n>
                Medir peso
            </span>
        </div>
        <div class="img-label" [ngClass]="{'selected':journeyStageId == 4, 'concluded':journeyStageId > 4}">
            <span i18n>
                Resultado
            </span>
        </div>
    </div>
    <!-- <div class="journey-info">
        <mat-icon *ngIf="id === 0" color="accent" class="material-symbols-filled">
            check_circle
        </mat-icon>
        <mat-icon *ngIf="id !== 0" color="accent" class="material-symbols-outlined">
            radio_button_unchecked
        </mat-icon>
        <span i18n>Identificação</span>
    </div>
    <div class="line1"></div>
    <div class="journey-info">
        <mat-icon *ngIf="id === 1" color="accent" class="material-symbols-filled">
            check_circle
        </mat-icon>
        <mat-icon *ngIf="id !== 1" color="accent" class="material-symbols-outlined">
            radio_button_unchecked
        </mat-icon>
        <span i18n>Medir pressão arterial</span>
    </div>
    <div class="line2"></div>
    <div class="journey-info">
        <mat-icon *ngIf="id === 2" color="accent" class="material-symbols-filled">
            check_circle
        </mat-icon>
        <mat-icon *ngIf="id !== 2" color="accent" class="material-symbols-outlined">
            radio_button_unchecked
        </mat-icon>
        <span i18n>Medir peso</span>
    </div>
    <div class="line3"></div>
    <div class="journey-info">
        <mat-icon *ngIf="id === 3" color="accent" class="material-symbols-filled">
            check_circle
        </mat-icon>
        <mat-icon *ngIf="id !== 3" color="accent" class="material-symbols-outlined">
            radio_button_unchecked
        </mat-icon>
        <span i18n>Resultado</span>
    </div> -->
</div>