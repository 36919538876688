import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyTwoNames(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        
        let error = { invalidName: {value: control.value} }
        if(value){
            let nameVector = value.split(' ')
        
        if(nameVector && nameVector.length >1 && nameVector[1] != ''){
            return null
                 
        }else{
            return error
        } 
        }
             
    }
}



/*
Função para validar número do CNS - Cartão Nacional de Saúde ou Cartão do SUS

:param cns: Número de CNS que será validado
:type cns: String (Caso não seja, será transformado)
:rtype: Boolean
*/

