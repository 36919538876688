import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { PatientAnamnesisService } from 'src/app/shared/services/API/sync-measurer/patient-anamnesis.service';
import { Answer } from 'src/app/shared/services/models/sync-measurer/answer.model';
import { Question } from 'src/app/shared/services/models/sync-measurer/questions.model';
import { AnamnesisRequest } from 'src/app/shared/services/requests/sync-measurer/anamnesis.request';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { AnswerStruct, QuestionAnswerStruct } from 'src/app/shared/services/structs/sync-measurer/patient-anamnesis.struct';
import { ConfirmExitModalComponent } from './confirm-exit-modal/confirm-exit-modal.component';
import { QuestionEnum } from './questions.enum';
import { PaginationEnum } from './pagination.enum';
import { CareLineService } from 'src/app/shared/services/API/sync-measurer/care-line.service';
import { ListedCareLineStruct } from 'src/app/shared/services/structs/sync-measurer/list-care-line.struct';
import { PatientCareLineService } from 'src/app/shared/services/API/sync-measurer/patient-care-line.service';
import { PatientCareLineRequest } from 'src/app/shared/services/requests/sync-measurer/patient-care-line.request';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-anamnese',
  templateUrl: './anamnese.component.html',
  styleUrls: ['./anamnese.component.css']
})
export class AnamneseComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private utilsWelcomeService: UtilsWelcomeService,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    public patientAnamnesisService: PatientAnamnesisService,
    private careLineService: CareLineService,
    private patientCareLineService: PatientCareLineService
  ) { }


  public page1Enum = PaginationEnum.page1;
  public page2Enum = PaginationEnum.page2;
  public page3Enum = PaginationEnum.page3;
  public page4Enum = PaginationEnum.page4;
  public page5Enum = PaginationEnum.page5;
  public healthStatusEnum: number = QuestionEnum.healthStatus;
  public deseasesMedicineEnum: number = QuestionEnum.deseasesMedicine;
  public painEnum: number = QuestionEnum.pain;
  public sleepDificultsEnum: number = QuestionEnum.sleepDificults;
  public sleepMedicineEnum: number = QuestionEnum.sleepMedicine;
  public useAlcoholEnum: number = QuestionEnum.useAlcohol;
  public smokeEnum: number = QuestionEnum.smoke;
  public phisicalActivitiesEnum: number = QuestionEnum.phisicalActivities;
  public doctorFrequencyEnum: number = QuestionEnum.doctorFrequency;

  public setQuestionEnumActive: number;

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isLoadingCep: boolean;
  public isLoadingCpf: boolean;
  public patientAlreadyExists: boolean = false;
  public datetimeStartRegister: Date;
  public idPatientToNext: number;
  public healthUnitName: string = "";
  public listAnswers: Answer[];
  public listQuestions: Question[];
  public questionName: string;
  public nextQuestionName: string = "";
  public question1Answer: Answer[];
  public question2Answer: Answer[];
  public patient: PatientStruct;
  public listCareLine: ListedCareLineStruct[];

  ngOnInit(): void {
    this.patient = this.utilsWelcomeService.getPatient();
    this.isLoading = true;
    this.isFirstLoading = true;
    this.setQuestionEnumActive = this.page1Enum;
    this.healthUnitName = this.utilsWelcomeService.gethealthUnit().healthUnitName;
    this.model = this.formBuilder.group({
      healthStatusAnswers: [''],
      othersDeseaseMedicine: [''],
      painAnswers: [''],
      sleepDificultsAnswers: [''],
      sleepMedicineAnswers: [''],
      useAlcoholAnswers: [''],
      smokeAnswers: [''],
      phisicalActivitiesAnswers: [''],
      doctorFrequencyAnswers: [''],
      listIdCareLine: ['']
    });
    this.populateCareLineSelect();
    this.lookupQuestionAnswers();
  }

  lookupQuestionAnswers() {
    this.patientAnamnesisService.lookupQuestionAnswers().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listQuestions = response.listQuestions;
      this.listAnswers = response.listAnswers;

      this.updateQuestion();
      this.findQuestionAnswered();
      this.getAnswerOptions(1);
    });
  }

  findQuestionAnswered() {
    this.patientAnamnesisService.getQuestionAnswered(this.utilsWelcomeService.getPatient().idPatient, this.utilsWelcomeService.getHash()).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.model.get('listIdCareLine').setValue(response.idCareLine);
      if (response.patientAnamnesis.listQuestionAnswer) {
        let questionCheckbox: number[] = [];
        questionCheckbox.push(this.deseasesMedicineEnum);
        for (let questioncheck of questionCheckbox) {
          response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == questioncheck).forEach((questionAnswer) =>
            questionAnswer.listAnswer.forEach((answer) =>
              this.listAnswers.find(a => a.idAnswer == answer.idAnswer).isChecked = true,
            ));
        }
        this.model.get('othersDeseaseMedicine').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.deseasesMedicineEnum)[0].othersDescription);

        this.model.get('healthStatusAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.healthStatusEnum)[0].listAnswer[0].idAnswer);
        this.model.get('painAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.painEnum)[0].listAnswer[0].idAnswer);
        this.model.get('sleepDificultsAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.sleepDificultsEnum)[0].listAnswer[0].idAnswer);
        this.model.get('sleepMedicineAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.sleepMedicineEnum)[0].listAnswer[0].idAnswer);
        this.model.get('useAlcoholAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.useAlcoholEnum)[0].listAnswer[0].idAnswer);
        this.model.get('smokeAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.smokeEnum)[0].listAnswer[0].idAnswer);
        this.model.get('phisicalActivitiesAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.phisicalActivitiesEnum)[0].listAnswer[0].idAnswer);
        this.model.get('doctorFrequencyAnswers').setValue(response.patientAnamnesis.listQuestionAnswer.filter(question => question.idQuestion == this.doctorFrequencyEnum)[0].listAnswer[0].idAnswer);
      }
      this.isLoading = false;
    });
  }

  updateQuestion() {
    this.questionName = this.listQuestions.filter(question => question.idQuestion == this.setQuestionEnumActive)[0].question;
    if (this.setQuestionEnumActive < this.listQuestions.length + 1 && this.setQuestionEnumActive + 1 != 2) {
      this.nextQuestionName = this.listQuestions.filter(question => question.idQuestion == this.setQuestionEnumActive + 1)[0].question;
      this.getAnswerOptions(this.setQuestionEnumActive);
    } else {
      this.getAnswerOptions(this.setQuestionEnumActive);
      this.nextQuestionName = "Você possui alguma das doenças abaixo?";
    }
  }

  isAnswered() {
    let value = this.setQuestionEnumActive;
    if (value == this.page1Enum) {
      if (this.model.get('healthStatusAnswers').value) {
        return true
      }
      else {
        this.alertService.show('Erro', "Existem perguntas não respondidas", AlertType.error);
      }
    }
    else if (value == this.page2Enum) {

      if (this.model.get('painAnswers').value) {
        return true
      }
      else {
        this.alertService.show('Erro', "Existem perguntas não respondidas", AlertType.error);
      }
    }
    else if (value == this.page3Enum) {

      if (this.model.get('sleepDificultsAnswers').value && this.model.get('sleepMedicineAnswers').value) {
        return true
      }
      else {
        this.alertService.show('Erro', "Existem perguntas não respondidas", AlertType.error);
      }

    }
    else if (value == this.page4Enum) {

      if (this.model.get('useAlcoholAnswers').value && this.model.get('smokeAnswers').value) {
        return true
      }
      else {
        this.alertService.show('Erro', "Existem perguntas não respondidas", AlertType.error);
      }
    }
    else if (value == this.page5Enum) {

      if (this.model.get('phisicalActivitiesAnswers').value && this.model.get('doctorFrequencyAnswers').value) {
        return true
      }
      else {
        this.alertService.show('Erro', "Existem perguntas não respondidas", AlertType.error);
      }
    }

  }

  nextQuestion() {
    if (this.isLoading) return;
    if (this.isAnswered()) {
      this.setQuestionEnumActive += 2;
      this.updateQuestion();
    }
  }

  previousQuestion() {
    this.setQuestionEnumActive -= 2;
    this.updateQuestion();
  }

  test(log) {

  }

  getAnswerOptions(questionEnum: number) {
    this.question1Answer = this.listAnswers.filter(question => question.idQuestion == questionEnum);
    if (this.setQuestionEnumActive < this.listQuestions.length + 1 && questionEnum + 1 != 2) {
      this.question2Answer = this.listAnswers.filter(question => question.idQuestion == questionEnum + 1);
    } else {
      this.question2Answer = null;
    }
  }

  exit() {
    const dialogRef = this.dialog.open(ConfirmExitModalComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/company/login']);
      }

    });
  }

  populateCareLineSelect() {
    var unit = this.utilsWelcomeService.gethealthUnit();
    this.careLineService.listCareLineSelect(unit.idHealthUnit).subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.listCareLine = response.listCareLineStruct;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }


  submit() {
    if (this.isLoading) {
      return;
    }
    if (!this.isAnswered()) {
      return;
    }
    this.isLoading = true;
    let patientAnaminesis = new AnamnesisRequest();
    patientAnaminesis.idPatient = this.patient.idPatient;
    patientAnaminesis.idSyncMeasurer = this.utilsWelcomeService.getSyncMeasurer().idSyncMeasurer;

    // Preparar uma questionAnsewer pra cada Enum (question)
    let newHealthStatus = new QuestionAnswerStruct();
    let newDeseases = new QuestionAnswerStruct();
    let newDeseasesMedicine = new QuestionAnswerStruct();
    let newPain = new QuestionAnswerStruct();
    let newSleepDificults = new QuestionAnswerStruct();
    let newSleepMedicine = new QuestionAnswerStruct();
    let newUseAlcohol = new QuestionAnswerStruct();
    let newSmoke = new QuestionAnswerStruct();
    let newPhisicalActivities = new QuestionAnswerStruct();
    let newDoctorFrequency = new QuestionAnswerStruct();

    // todas Enum, todos os idQuestion
    newHealthStatus.idQuestion = this.healthStatusEnum;
    newDeseasesMedicine.idQuestion = this.deseasesMedicineEnum;
    newDeseasesMedicine.othersDescription = this.model.get('othersDeseaseMedicine').value;
    newPain.idQuestion = this.painEnum;
    newSleepDificults.idQuestion = this.sleepDificultsEnum;
    newSleepMedicine.idQuestion = this.sleepMedicineEnum;
    newUseAlcohol.idQuestion = this.useAlcoholEnum;
    newSmoke.idQuestion = this.smokeEnum;
    newPhisicalActivities.idQuestion = this.phisicalActivitiesEnum;
    newDoctorFrequency.idQuestion = this.doctorFrequencyEnum;

    let listCheckedAnswers = this.listAnswers.filter(answer => answer.isChecked);

    newDeseasesMedicine.listAnswer = [];

    listCheckedAnswers.filter(answer => answer.idQuestion == this.deseasesMedicineEnum).forEach((element) => {
      if (element.idAnswer) {
        let newAnswer: AnswerStruct = new AnswerStruct();
        newAnswer.idAnswer = element.idAnswer;
        newDeseasesMedicine.listAnswer.push(newAnswer);
      }
    });

    let answerHealthStatus: AnswerStruct = new AnswerStruct();
    answerHealthStatus.idAnswer = this.model.get('healthStatusAnswers').value;
    newHealthStatus.listAnswer = [];
    newHealthStatus.listAnswer.push(answerHealthStatus);

    let answerPain: AnswerStruct = new AnswerStruct();
    answerPain.idAnswer = this.model.get('painAnswers') ? this.model.get('painAnswers').value : null;
    newPain.listAnswer = [];
    newPain.listAnswer.push(answerPain);

    let answerSleepDificults: AnswerStruct = new AnswerStruct();
    answerSleepDificults.idAnswer = this.model.get('sleepDificultsAnswers') ? this.model.get('sleepDificultsAnswers').value : null;
    newSleepDificults.listAnswer = [];
    newSleepDificults.listAnswer.push(answerSleepDificults);

    let answerSleepMedicine: AnswerStruct = new AnswerStruct();
    answerSleepMedicine.idAnswer = this.model.get('sleepMedicineAnswers') ? this.model.get('sleepMedicineAnswers').value : null;
    newSleepMedicine.listAnswer = [];
    newSleepMedicine.listAnswer.push(answerSleepMedicine);

    let answerUseAlcohol: AnswerStruct = new AnswerStruct();
    answerUseAlcohol.idAnswer = this.model.get('useAlcoholAnswers') ? this.model.get('useAlcoholAnswers').value : null;
    newUseAlcohol.listAnswer = [];
    newUseAlcohol.listAnswer.push(answerUseAlcohol);

    let answerSmoke: AnswerStruct = new AnswerStruct();
    answerSmoke.idAnswer = this.model.get('smokeAnswers') ? this.model.get('smokeAnswers').value : null;
    newSmoke.listAnswer = [];
    newSmoke.listAnswer.push(answerSmoke);

    let answerPhisicalActivities: AnswerStruct = new AnswerStruct();
    answerPhisicalActivities.idAnswer = this.model.get('phisicalActivitiesAnswers') ? this.model.get('phisicalActivitiesAnswers').value : null;
    newPhisicalActivities.listAnswer = [];
    newPhisicalActivities.listAnswer.push(answerPhisicalActivities);

    let answersDoctorFrequency: AnswerStruct = new AnswerStruct();
    answersDoctorFrequency.idAnswer = this.model.get('doctorFrequencyAnswers') ? this.model.get('doctorFrequencyAnswers').value : null;
    newDoctorFrequency.listAnswer = [];
    newDoctorFrequency.listAnswer.push(answersDoctorFrequency);

    patientAnaminesis.listQuestionAnswer = [];
    patientAnaminesis.listQuestionAnswer.push(newHealthStatus);
    patientAnaminesis.listQuestionAnswer.push(newDeseasesMedicine);
    patientAnaminesis.listQuestionAnswer.push(newPain);
    patientAnaminesis.listQuestionAnswer.push(newSleepDificults);
    patientAnaminesis.listQuestionAnswer.push(newSleepMedicine);
    patientAnaminesis.listQuestionAnswer.push(newUseAlcohol);
    patientAnaminesis.listQuestionAnswer.push(newSmoke);
    patientAnaminesis.listQuestionAnswer.push(newPhisicalActivities);
    patientAnaminesis.listQuestionAnswer.push(newDoctorFrequency);

    this.patientAnamnesisService.saveAnaminesis(patientAnaminesis).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      };
      if (this.model.get("listIdCareLine").value) {
        this.saveCareline();
      } else {
        this.goToConfirm();
      }

    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  saveCareline() {
    let patient: PatientCareLineRequest = new PatientCareLineRequest();
    patient.hash = this.utilsWelcomeService.getHash();
    patient.idHealthUnit = this.utilsWelcomeService.gethealthUnit().idHealthUnit;
    patient.idPatient = this.utilsWelcomeService.getPatient().idPatient
    patient.listIdCareLine = this.model.get("listIdCareLine").value;
    this.patientCareLineService.saveCareLine(patient).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      };
      this.goToConfirm();
    });
  }

  goToConfirm() {
    this.router.navigate(['company/anamnese/finish']);
  }

}
