<div class="container-out"></div>
<div class="mat-dialog-content">
    <div>
        <span i18n *ngIf="noCPF">Não é possível continuar sem informar um CPF válido. <strong>Procure a recepção
                da UBS</strong>
            para cadastrar ou atualizar seus dados e tente novamente mais tarde </span>
        <span i18n *ngIf="!noCPF">Não foi possível localizar seu CPF. <strong>Procure a recepção da UBS</strong>
            para cadastrar ou atualizar seus dados e tente novamente mais tarde </span>
    </div>
    <div class="footer-confirm-modal-list">
        <button mat-flat-button class="btn-try-again-error" (click)="finishSession()">
            <span i18n>Encerrar</span>
        </button>
    </div>
</div>