import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-no-measurer-confirmation-modal',
  templateUrl: './no-measurer-confirmation-modal.component.html',
  styleUrls: ['./no-measurer-confirmation-modal.component.css']
})
export class NoMeasurerConfirmationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<NoMeasurerConfirmationModalComponent>) { }

  ngOnInit(): void {

  }

  clickClose() {
    this.matDialogRef.close();
  }

  clickConfirm() {
    this.matDialogRef.close({ send: true });
  }
}