import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GlucometerService } from 'src/app/shared/services/API/interface-measurer/glucometer.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { MeasureStruct, Glucometer } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { PagesEnum } from '../pages.enum';
import { ManualGlucometerModalComponent } from './manual-glucometer-modal/manual-glucometer-modal.component';


@Component({
  selector: 'app-glucometer',
  templateUrl: './glucometer.component.html',
  styleUrls: ['./glucometer.component.css']
})
export class GlucometerComponent implements OnInit {

  constructor(private glucometerService: GlucometerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilsWelcomeService:UtilsWelcomeService) { }

  @Output("leaveMeasurer") leaveMeasurer: EventEmitter<any> = new EventEmitter();
  @Output("changePage") changePage: EventEmitter<any> = new EventEmitter();
  

  public thermometer: number = PagesEnum.thermometer;  
  public finalizationScreen: number = PagesEnum.finalizationScreen;

  public measureWasMade: boolean = false;
  public glucometer: number;
  private syncInterval: any;
  private isRequesting: boolean = false;
  private measureStruct: MeasureStruct;

  ngOnInit(): void {
    let measure = this.utilsWelcomeService.getMeasureList();
    if (measure && measure[0]) {
      this.measureStruct = measure[0];
      if (measure[0].glucometer && measure[0].glucometer.glucose) {
        this.glucometer = measure[0].glucometer.glucose;
        this.measureWasMade = true;
      }
    } else {
      this.measureStruct = new MeasureStruct;
    }
    this.RestartGlucometer();
  }

  callChangePage(page:number){
    this.changePage.next(page);
  }

  openManualModal() {
    const dialogRef = this.dialog.open(ManualGlucometerModalComponent, {
      panelClass: 'modal-manual-glucometer'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.glucometer = result.glucometer;
        if(!this.measureStruct.glucometer)this.measureStruct.glucometer = new Glucometer;
        this.measureStruct.glucometer.glucose = this.glucometer;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }
    });
  }


  startGlucometer() {
    this.glucometerService.start("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startInterval();
    }, () => {
      this.alertService.show('Erro', "Não foi possível iniciar o Glicosímetro!", AlertType.error);
    });
  }

  RestartGlucometer() {
    this.glucometerService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startGlucometer();
    }, () => {
      this.alertService.show('Erro', "Não foi possível reiniciar o Glicosímetro!", AlertType.error);
    });
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.glucometerService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    });
  }

  readGlucometer() {
    if(this.isRequesting) return;
    this.isRequesting = true;
    this.glucometerService.read('x').subscribe((response) => {
      this.isRequesting = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (!response.running) {
        clearInterval(this.syncInterval);
        this.RestartGlucometer();
      }

      if(response.measurer){
        this.glucometer = parseFloat(response.measurer);
        if(!this.measureStruct.glucometer)this.measureStruct.glucometer = new Glucometer;
        this.measureStruct.glucometer.glucose = this.glucometer;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }

    });
  }

  startInterval() {
    this.syncInterval = setInterval(() => { this.readGlucometer() }, 1000);
  }
}
