import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingListComponent } from './components/loading-list/loading-list.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingUbsHealthPromotionModal } from './components/loading-ubs-health-promotion-modal/loading-ubs-health-promotion-modal.component';
import { ErrorPAUbsHealthPromotion } from './components/error-pa-ubs-health-promotion/error-pa-ubs-health-promotion.component';
import { JourneyStageComponent } from './components/journey-stage/journey-stage.component';
import { UbsLgpdInfoModalComponent } from './components/ubs-lgpd-info-modal/ubs-lgpd-info-modal.component';
import { UbsLgpdProtocolModalComponent } from './components/ubs-lgpd-protocol-modal/ubs-lgpd-protocol-modal.component';
import { UbsErrorModalComponent } from './components/ubs-error-modal/ubs-error-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UbsErrorOutdatedDataModalComponent } from './components/ubs-error-outdated-data-modal/ubs-error-outdated-data-modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    LoadingListComponent,
    LoadingProgressBarComponent,
    LoadingUbsHealthPromotionModal,
    ErrorPAUbsHealthPromotion,
    JourneyStageComponent,
    UbsLgpdInfoModalComponent,
    UbsLgpdProtocolModalComponent,
    UbsErrorModalComponent,
    UbsErrorOutdatedDataModalComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    CdkAccordionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
  ],
  exports: [
    LoadingListComponent, 
    LoadingProgressBarComponent,
    JourneyStageComponent,
    ErrorPAUbsHealthPromotion,
    UbsLgpdInfoModalComponent,
    UbsLgpdProtocolModalComponent,
    UbsErrorModalComponent,
    UbsErrorOutdatedDataModalComponent
  ],
})
export class SharedModule { }
