import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MeasureRequest } from '../../requests/sync-measurer/measure.request';
import { LookupQuestionAnswerResponse } from '../../responses/sync-measurer/lookup-question-answer.response';
import { PatientAnaminesisResponse } from '../../responses/sync-measurer/patient-anamnesis.response';
import { AnamnesisRequest } from '../../requests/sync-measurer/anamnesis.request';
import { PatientCareLineRequest } from '../../requests/sync-measurer/patient-care-line.request';


@Injectable({
  providedIn: 'root'
})
export class PatientCareLineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }
  public saveCareLine(request: PatientCareLineRequest): Observable<ReturnStruct> {

    let url = `PatientCareLine/`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + url, request, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}