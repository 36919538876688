import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-manual-thermometer-modal',
  templateUrl: './manual-thermometer-modal.component.html',
  styleUrls: ['./manual-thermometer-modal.component.css']
})
export class ManualThermometerModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ManualThermometerModalComponent>,
    public formBuilder: FormBuilder,
    public alertService: AlertService,
    public maskService: MaskService) { }

  public model: FormGroup;
  public masks: Masks;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      thermometer: ['', [Validators.required]],
    });
  }

  clickClose() {
    this.matDialogRef.close();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos!", AlertType.error);
      return;
    }
    let thermometer = parseFloat(this.model.get("thermometer").value.replace(",","."));
    this.matDialogRef.close({ thermometer });
  }
}