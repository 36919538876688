import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VerifyTwoNames } from 'src/app/shared/custom-validators/name.validator';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { Masks } from 'src/app/shared/services/mask.service';
import { DowntimeService } from '../../downtime.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';

@Component({
  selector: 'app-complete-name-register',
  templateUrl: './complete-name-register.component.html',
  styleUrls: ['./complete-name-register.component.css']
})
export class CompleteNameRegisterComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsUBSService: UtilsUBSService,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  private patientData: PatientData = new PatientData();
  public lgpd: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public audio = new Audio();
  public journeyStageId: number = JourneyStageEnum.Identificacao;
  public idUbsScreen: number = UbsScreenEnum.CpfRegister;
  public uBSConfigData: UBSConfigData;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();

    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1)
      this.router.navigate(['/ubs/erro']);

    this.model = this.formBuilder.group({
      completeName: ['', [
        Validators.required,
        VerifyTwoNames()
      ]],
    });
    if (this.utilsUBSService.getPatientData()) {
      this.patientData = this.utilsUBSService.getPatientData();
      this.model.get('completeName').setValue(this.patientData.name);
    }
    this.isLoading = false;
    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid)
      return;

    let completeName = this.model.get('completeName').value;
    this.patientData.name = completeName;

    this.utilsUBSService.createPatientData(this.patientData);
    
    this.downtimeService.stopTimer()
    this.audio.pause();
    this.saveLastPage();
    this.router.navigate([`ubs/birth-date-register-identifier`]);
  }

  playAudio() {
    this.audio.src = `../assets/audio/${this.locale}/complete-name-request.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack() {
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    this.router.navigate([lastPage]);

    if (this.patientData.searchByCPF == true){
      this.downtimeService.stopTimer()
      this.audio.pause();
      this.router.navigate([`ubs/cpf-register`]);
    }
    else{
      this.downtimeService.stopTimer()
      this.audio.pause();
      this.router.navigate([`ubs/cns-register`]);
    }
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}