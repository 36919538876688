<div class="white-box">
    <h1 *ngIf="!measureWasMade">Agora, vamos ver como está sua Temperatura!</h1>
    <h1 *ngIf="measureWasMade">Tudo certo, pode continuar para o próximo medidor!</h1>
    <div class="row">
        <div class="col-md-12">
            <img class="img-fc" src="assets/images/Temperatura.jpg" />
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <h3>Siga as instruções abaixo</h3>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <p><b>1</b> - Ligue o termômetro no botão ON/MEM.</p>
            <p><b>2</b> - Aguarde o bib(Som) do termômetro.</p>
            <p><b>3</b> - Insira o termômetro no ouvido.</p>
            <p><b>4</b> - Aperte o botão cinza na parte superior de trás do termômetro.</p>
            <p><b>5</b> - Caso o valor medido não apareça na tela em alguns segundos, informe o valor manualmente</p>
        </div>
        <div *ngIf="measureWasMade" class="col-md-12">
            <h3>Sua temperatura é: <b>{{thermometer}}</b>°C</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(bloodPressure)">
                <span>Voltar</span>
            </button>
        </div>
        <div class="col-md-3">
            <button (click)="leaveMeasurer.emit()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Sair</span>
            </button>
        </div>
        <div class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="openManualModal()">
                <span>Informar manualmente</span>
            </button>
        </div>
        <div *ngIf="measureWasMade" class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(glucometer)">
                <span>Próximo</span>
            </button>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(glucometer)">
                <span>Não Informar</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="index-page col-md-12">
            Página 3 de 5
        </div>
    </div>
</div>