<app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
<div class="white-body-test ubs">
    <div class="row">
        <div class="data-container">
            <div class="row">
                <div class="title">
                    <span i18n>
                        <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="Menu apps" (click)="playAudio()"/>
                        CONFIRME SE VOCÊ É:
                    </span>
                </div>
            </div>
            <div class="info-container">
                <span class="info" i18n>{{patientData.name}}</span>
                <span *ngIf="patientData.cpf != null" class="info" i18n>
                    <strong>CPF:</strong> {{patientData.cpf}}
                </span>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class=" btn-back" (click)="back()">
            <div>
                <mat-icon>arrow_back</mat-icon>
            </div>
            <span *ngIf="isLoading == false" i18n>Não, voltar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
        <button mat-button (click)="forward()" class="block btn-go btn-ubs">
            <span *ngIf="isLoading == false" i18n>Sim, continuar</span>
            <div>
                <mat-icon>arrow_forward</mat-icon>
            </div>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>