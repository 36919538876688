<div class="white-box">
    <h1 *ngIf="!measureWasMade">Vamos ver como está sua frequência cardíaca e saturação!</h1>
    <h1 *ngIf="measureWasMade">Tudo certo, pode continuar para o próximo medidor!</h1>
    <div class="row">
        <div class="col-md-12">
            <img class="img-fc" src="assets/images/frequencia-cardiaca.jpg" />
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <h3>Siga as instruções abaixo</h3>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <p><b>1</b> - Ligue o oxímetro.</p>
            <p><b>2</b> - Coloque o dedo indicador no aparelho.</p>
            <p><b>3</b> - Aguarde o valor aparecer no visor.</p>
            <p><b>4</b> - Caso o valor medido não apareça na tela em alguns segundos, informe o valor manualmente</p>
        </div>
        <div *ngIf="measureWasMade" class="col-md-12">
            <h3>Sua frequência cardíaca é: <b>{{heartRate}}</b>bpm</h3>
        </div>
        <div *ngIf="measureWasMade" class="col-md-12">
            <h3>Sua saturação é: <b>{{oximeter}}%</b></h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <button (click)="leaveMeasurer.emit()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Sair</span>
            </button>
        </div>
        <div class="col-md-4">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="openManualModal()">
                <span>Informar manualmente</span>
            </button>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-4">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(bloodPressure)">
                <span>Não Informar</span>
            </button>
        </div>
        <div *ngIf="measureWasMade" class="col-md-4">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(bloodPressure)">
                <span>Próximo</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="index-page col-md-12">
            Página 1 de 5
        </div>
    </div>
</div>