import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-height-register',
  templateUrl: './height-register.component.html',
  styleUrls: ['./height-register.component.css']
})
export class HeightRegisterComponent implements OnInit {

  private title: string = 'birth-date-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    @Inject(LOCALE_ID) public locale: string
  ) {
      this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;  
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.HeightRegister;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      height: [''],
    });

    if(this.utilsUBSService.getPatientData()){
      this.patientData = this.utilsUBSService.getPatientData();
      this.model.get('height').setValue(this.patientData.height);
    }

    this.isLoading = false;
  }

  submit() {
    if (!this.model.valid) {
      return;
    }
    this.audio.pause();
    let height = this.model.get('height').value;
    this.patientData.height = height;

    this.utilsUBSService.createPatientData(this.patientData);
    this.saveLastPage();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex+1].screenUrl}`]);
  }

  playAudio(){
    this.audio.src = `../assets/audio/${environment.locale}/height-01.mp3`;
    this.audio.load();
    this.audio.play();
  }

  goBack(){
    this.audio.pause();

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}