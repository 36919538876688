import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListCareLineResponse } from '../../responses/sync-measurer/list-care-line.response';

@Injectable({
  providedIn: 'root'
})
export class CareLineService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public listCareLineSelect(idHealthUnit: number): Observable<ListCareLineResponse> {

    let uri = `CareLine/getAll?idHealthUnit=${idHealthUnit}`

    return this.httpClient.get<ListCareLineResponse>(environment.urlApiSyncMeasurer + uri, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}