<div class="container ubs">
    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-test">
        <div class="row">
            <div class="col-12 container-title">
                <div class="title">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
                    <span i18n>Qual sua renda familiar?</span>
                </div>
            </div>        
        </div>
        
        <form class="form-slider" [formGroup]="model" (ngSubmit)="submit()">
            <mat-slider
                class="slider-custom"
                [max]="4"
                [min]="0"
                [step]="1"
                [discrete]="false"
                [showTickMarks]="true">
                <input matSliderThumb aria-label="Select an option" formControlName="householdIncome">
            </mat-slider>
        </form>
        <div class="values-info">
            <label class="value-1">R$100 - R$200</label>
            <label class="value-2">R$200 - R$500</label>
            <label class="value-3">R$500 - R$1000</label>
            <label class="value-4">R$1000 - R$2000</label>
            <label class="value-5">Acima de R$2000</label>
        </div>
    </div>
    
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon *ngIf="!isLoading" class="span-icon" >arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
        <button mat-flat-button (click)="submit()" class="block btn-go btn-ubs" [disabled]="this.model.invalid">
            <span i18n *ngIf="isLoading == false">AVANÇAR</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>