import { Component, EventEmitter, OnInit, Output, LOCALE_ID, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-error-pa-ubs-health-promotion',
  templateUrl: './error-pa-ubs-health-promotion.component.html',
  styleUrls: ['./error-pa-ubs-health-promotion.component.css']
})
export class ErrorPAUbsHealthPromotion implements OnInit {

  private title: string = 'error-pa-ubs-health-promotion';

  constructor(private titleService: Title,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  @Output("endFlowMeasurer") endFlowMeasurer: EventEmitter<any> = new EventEmitter();
  @Output("retryMeasurer") retryMeasurer: EventEmitter<any> = new EventEmitter();
  public audio = new Audio();


  ngOnInit(): void {
  }

  playAudio() {

    this.audio.src = `../../../assets/audio/${environment.locale}/error-mesurer-pa.mp3`;
    this.audio.load();
    this.audio.play();
  }

  callRetryMeasurer() {
    this.retryMeasurer.emit();
  }

  callEndFlow() {
    this.endFlowMeasurer.emit();
  }
}