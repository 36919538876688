<div class="white-box">
    <h1 *ngIf="!measureWasMade">Agora, vamos ver como está sua Glicose!</h1>
    <h1 *ngIf="measureWasMade">Tudo certo, pode continuar para a próxima tela e finalizar!</h1>
    <div class="row">
        <div class="col-md-12">
            <img class="img-fc" src="assets/images/Glicose.jpg" />
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <h3>Siga as instruções abaixo</h3>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-12">
            <p><b>1</b> - Primeiro, pegue uma fita de glicemia.</p>
            <p><b>2</b> - Insira a fita de glicemia no aparelho, com a seta da fita direcionada para o aperelho.</p>
            <p><b>3</b> - Pegue a lanceta(agulha) e fure a ponta de um dos dedos da mão.</p>
            <p><b>4</b> - Coloque a gota de sangue na ponta da fita(parte branca) que está para fora do aparelho.</p>
            <p><b>5</b> - Caso o valor medido não apareça na tela em alguns segundos, informe o valor manualmente</p>
        </div>
        <div *ngIf="measureWasMade" class="col-md-12">
            <h3>Sua Glicose é: <b>{{glucometer}}</b>mg/dL</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(thermometer)">
                <span>Voltar</span>
            </button>
        </div>
        <div class="col-md-3">
            <button (click)="leaveMeasurer.emit()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Sair</span>
            </button>
        </div>
        <div class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="openManualModal()">
                <span>Informar manualmente</span>
            </button>
        </div>
        <div *ngIf="measureWasMade" class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(finalizationScreen)">
                <span>Próximo</span>
            </button>
        </div>
        <div *ngIf="!measureWasMade" class="col-md-3">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="callChangePage(finalizationScreen)">
                <span>Não Informar</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="index-page col-md-12">
            Página 4 de 5
        </div>
    </div>
</div>