import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { UBSRequest } from '../../requests/orchestrator-patient/ubs.request';
import { WebhookStruct } from '../../structs/Integration/webhook-struct';
import { UtilsUBSService } from 'src/app/ubs-health-promotion/utils-ubs.service';
import { UBSSearchPatientRequest } from '../../requests/orchestrator-integration/ubs-search-patient.request';
import { UBSSearchPatientResponse } from '../../responses/orchestrator-integration/ubs-search-patient.response';

@Injectable({
  providedIn: 'root'
})
export class UbsWebhookService extends CommonService {

  constructor(private router: Router,
    private utilsUBSService: UtilsUBSService,
    private httpClient: HttpClient,) {
    super();
  }

  public uBSWebhook(body: UBSRequest): Observable<ReturnStruct> {
    let uri = `UBSHealthPromotion/ubs-health-promotion`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }

  public uBSSearchPatient(cpf: string, idHealthUnit: number, company: boolean, cns: string, jsonListIdHealthUnit: string, body: UBSSearchPatientRequest): Observable<UBSSearchPatientResponse> {
    let uri = `UBSHealthPromotion/uBSSearchPatient/idHealthUnit/${idHealthUnit}?`

    if (cpf) uri += `cpf=${cpf}&`;
    if (cns) uri += `cns=${cns}&`;
    if (company != null) uri += `company=${company}&`;
    if (company != null) uri += `jsonListIdHealthUnit=${jsonListIdHealthUnit}&`;

    return this.httpClient.post<UBSSearchPatientResponse>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}