import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UbsHealthPromotionSmsEmailService } from 'src/app/shared/services/API/srv-email/ubs-health-promotion-sms-email.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { environment } from 'src/environments/environment';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { PatientData, RiskFactorsData, UBSConfigData, UtilsUBSService } from 'src/app/ubs-health-promotion/utils-ubs.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostUbsHealthPromotionSmsEmailRequest } from 'src/app/shared/services/requests/srv-email/post-ubs-health-promotion-sms-email.request';
import { DowntimeService } from '../../downtime.service';
import { Router } from '@angular/router';
import { EndModalComponent } from './end-modal/end-modal.component';
import { UBSRequest } from 'src/app/shared/services/requests/orchestrator-patient/ubs.request';
import { ResultComponent } from '../result-resume/result/result.component';

@Component({
  selector: 'app-sharing-measurement-results',
  templateUrl: './sharing-measurement-results.component.html',
  styleUrls: ['./sharing-measurement-results.component.css']
})
export class SharingMeasurementResultsComponent implements OnInit, OnDestroy {

  @ViewChild('result') result: ResultComponent;
  public patientData: PatientData = new PatientData();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private maskService: MaskService,
    private UbsHealthPromotionSmsEmailService: UbsHealthPromotionSmsEmailService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private utilsUBSService: UtilsUBSService,
    private downtimeService: DowntimeService,
    private alertService: AlertService,
    private router: Router,
  ) { }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public inputValid: boolean = false;
  public healthUnit: HealthUnitStruct;
  public patientRequest: PostUbsHealthPromotionSmsEmailRequest;
  public downtime: boolean;
  public uBSRequest: UBSRequest = new UBSRequest();
  public uBSConfigData: UBSConfigData;
  public riskFactorsData: RiskFactorsData;
  public audio = new Audio();

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      email: ['', [Validators.email]],
      phone: [''],
    });
    this.isLoading = false;

    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }

    this.healthUnit = this.utilsUBSService.getHealthUnit();
    this.uBSRequest = this.utilsUBSService.getUBSPatientRequest();
    this.patientRequest = this.utilsUBSService.getSendEmailPatientRequest();
    this.patientData = this.utilsUBSService.getPatientData();
    this.riskFactorsData = this.utilsUBSService.getRiskFactorsData();

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  populateShareRequest(event: PostUbsHealthPromotionSmsEmailRequest) {
    this.patientRequest = event;
  }

  submit() {
    if (this.isLoading)
      return;

    if (!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }
    this.isLoading = true;
    let body = this.patientRequest;
    body.timeZoneValue = this.healthUnit.timeZoneValue;

    if (this.model.get('phone').value)
      body.phone = this.model.get('phone').value;
    if (this.model.get('email').value)
      body.email = this.model.get('email').value;

    body.counselingTitle = this.patientRequest.counselingTitle;
    body.counseling = this.patientRequest.counseling;
    // body.counselingDescription = this.patientRequest.counselingDescription.replace(/<b>||<\/b>/g, '');
    body.counselingDescription = this.patientRequest.counselingDescription.replace('<b>', '').replace('</b>', '');
    body.dateConsulting = new Date();
    body.degreeImc = this.patientRequest.degreeImc;
    body.imcCathegory = this.patientRequest.imcCathegory;
    body.degreePressure = this.patientRequest.degreePressure;
    body.paCathegory = this.patientRequest.paCathegory;
    body.diastolicPressure = this.patientData.diastolicPressure;
    body.systolicPressure = this.patientData.sistolicPressure;
    body.imc = parseFloat(this.patientRequest.imc.toFixed(1));
    body.referenceImc = this.patientRequest.referenceImc;
    body.referencePressure = this.patientRequest.referencePressure;

    if (this.model.get('email').value || this.model.get('phone').value) {
      this.UbsHealthPromotionSmsEmailService.sendSmsEmail(body).subscribe((response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Dados enviados com sucesso!", AlertType.success);

        this.openEndModal();
        this.downtimeService.stopTimer();
        this.utilsUBSService.removeListVisitidePages();
        this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
        this.isLoading = false;
      },
        (error) => {
          console.log(error)
          this.isLoading = false;
          this.alertService.show('Erro inesperado', error, AlertType.error);
        })
    }
    else {
      this.openEndModal();
      this.downtimeService.stopTimer();
      this.utilsUBSService.removeListVisitidePages();
      this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
      this.isLoading = false;
    }
  }

  playSound() {
    this.audio.src = `/assets/audio/${environment.locale}/send-email-or-phone.mp3`
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  verifyInput() {
    let phone = this.model.get('phone').value;
    let validPhone = /^\([1-9]{2}\) 9 [7-9]{1}[0-9]{3}\-[0-9]{4}$/.test(phone);
    if ((this.model.get('email').value != "" && this.model.valid) || (this.model.get('phone').value != "" && validPhone)) {
      this.inputValid = true;
      if ((this.model.get('phone').value != "" && !validPhone))
        this.inputValid = false
      if ((this.model.get('email').value != "" && !this.model.valid))
        this.inputValid = false
    } else
      this.inputValid = false;
  }

  openEndModal() {
    const dialogRef = this.dialog.open(EndModalComponent, {
      data: {
        request: this.uBSRequest,
      },
      panelClass: 'modal-end',
      backdropClass: 'backdrop-end'
    });
    this.downtimeService.stopTimer();
    this.utilsUBSService.removeListVisitidePages();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
  }
}