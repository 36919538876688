import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-manual-oximeter-modal',
  templateUrl: './manual-oximeter-modal.component.html',
  styleUrls: ['./manual-oximeter-modal.component.css']
})
export class ManualOximeterModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ManualOximeterModalComponent>,
    public formBuilder: FormBuilder,
    public alertService: AlertService,
    public maskService: MaskService) { }

  public model: FormGroup;
  public masks: Masks;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      heartRate: ['', [Validators.required]],
      oximeter: ['', [Validators.required]],
    });
    console.log("linha 29: ", this.model)
  }

  clickClose() {
    this.matDialogRef.close();
  }

  submit() {
    console.log("linha 37: ", this.model)
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos!", AlertType.error);
      return;
    }
    let heartRate = this.model.get("heartRate").value.replace(/[^0-9]+/g, '');
    let oximeter = this.model.get("oximeter").value.replace(/[^0-9]+/g, '');
    if (oximeter > 100) {
      this.model.get("oximeter").setValue(null);
      this.alertService.show('Erro', "A saturação não pode ser mais que 100!", AlertType.error);
      return;
    }
    this.matDialogRef.close({ heartRate, oximeter });
  }
}