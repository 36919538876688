import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';


@Component({
  selector: 'app-confirm-patient-modal',
  templateUrl: './confirm-patient-modal.component.html',
  styleUrls: ['./confirm-patient-modal.component.css']
})
export class ConfirmPatientModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ConfirmPatientModalComponent>,
    private utilsWelcomeService: UtilsWelcomeService,
  ) { }

  public isLoading:boolean;  

  ngOnInit(): void {

    this.isLoading = false;
  }
  
  close(){
    this.matDialogRef.close(); 
  }
  
  clickConfirm(){
    let storedPatient = this.utilsWelcomeService.getPatient();
    if(storedPatient && storedPatient.idPatient != this.data.patient.idPatient){
      this.utilsWelcomeService.storeMeasureList([]);
    }

    this.utilsWelcomeService.updatePatient(this.data.patient);

    this.matDialogRef.close({confirm: true}); 
  }
  
  clickCancel(){
    this.matDialogRef.close(); 
  }
}
