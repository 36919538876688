import { Component, Input, OnInit } from '@angular/core';
import { PaCathegoryEnum } from 'src/app/shared/enum/pa-cathegory.enum';
import { RiskCategoryEnum } from 'src/app/shared/enum/risk-category.enum';

@Component({
  selector: 'app-result-counseling',
  templateUrl: './result-counseling.component.html',
  styleUrls: ['./result-counseling.component.css']
})
export class ResultCounselingComponent implements OnInit {

  @Input() counselingTitle: string;
  @Input() counseling: string;
  @Input() counselingDescription: string;
  @Input() riskCategory: number;

  constructor() { }

  public riskCategoryColor: string;
  public riskCategoryEnum = RiskCategoryEnum;

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    this.changeResult();
  }

  changeResult() {
    var counselingDescriptionElemento = document.getElementById("counseling-description");
    counselingDescriptionElemento.innerHTML = this.counselingDescription;

    if (this.riskCategory == RiskCategoryEnum.noRisk) {
      this.riskCategoryColor = "noAdditionalRisk";
    }
    else if (this.riskCategory == RiskCategoryEnum.highRisk) {
      this.riskCategoryColor = "highRisk";
    }
  }
}