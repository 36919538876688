<header>
    <img src="assets/images/logo-branco.png" />
    <span class="title-header-company">{{healthUnitName}}</span>
</header>
<div class="container-body">

    <div class="box-container">


        <div class="title-header row">
            <h1 class="col-12 d-flex justify-content-center">{{questionName}}</h1>
        </div>
        <form class="form" [formGroup]="model">

            <div class="row" *ngIf="setQuestionEnumActive == page1Enum">
                <mat-radio-group class="questions d-flex justify-content-center" formControlName="healthStatusAnswers">
                    <mat-radio-button class="" *ngFor="let item of question1Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </form>

        <div class="row" *ngIf="setQuestionEnumActive == page2Enum">
            <div class="col-12 col-sm-6 col-md-4 checkboxes" *ngFor="let item of question1Answer">
                <mat-checkbox [checked]="item.isChecked" [(ngModel)]="item.isChecked" (change)="test($event)">
                    {{item.answer}}
                </mat-checkbox>
            </div>
            <form class="form" [formGroup]="model">
                <div class="col-12 col-sm-12 checkboxes d-flex justify-content-center">
                    <mat-form-field appearance="outline">
                        <mat-label>Outras doenças</mat-label>
                        <input matInput type="text" formControlName="othersDeseaseMedicine">
                    </mat-form-field>
                </div>
            </form>
        </div>

        <form class="form" [formGroup]="model">

            <div class="row" *ngIf="setQuestionEnumActive == page3Enum">
                <mat-radio-group class="questions d-flex justify-content-center"
                    formControlName="sleepDificultsAnswers">
                    <mat-radio-button class="" *ngFor="let item of question1Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row" *ngIf="setQuestionEnumActive == page4Enum">
                <mat-radio-group class="questions d-flex justify-content-center" formControlName="useAlcoholAnswers">
                    <mat-radio-button class="" *ngFor="let item of question1Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row" *ngIf="setQuestionEnumActive == page5Enum">
                <mat-radio-group class="questions d-flex justify-content-center"
                    formControlName="phisicalActivitiesAnswers">
                    <mat-radio-button class="" *ngFor="let item of question1Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="title-header d-flex justify-content-center">
                <h1>{{nextQuestionName}}</h1>
            </div>
        </form>

        <form class="form" [formGroup]="model">
            <div class="row" *ngIf="setQuestionEnumActive == page1Enum">
                <mat-form-field appearance="outline">
                    <mat-label>Doenças</mat-label>
                    <mat-select formControlName="listIdCareLine" multiple>
                        <mat-option *ngFor="let item of listCareLine" [value]="item.idCareLine">{{item.careLineName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row" *ngIf="setQuestionEnumActive == page2Enum">
                <mat-radio-group class="questions d-flex justify-content-center" formControlName="painAnswers">
                    <mat-radio-button class="" *ngFor="let item of question2Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row" *ngIf="setQuestionEnumActive == page3Enum">
                <mat-radio-group class="questions d-flex justify-content-center" formControlName="sleepMedicineAnswers">
                    <mat-radio-button class="" *ngFor="let item of question2Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row" *ngIf="setQuestionEnumActive == page4Enum">
                <mat-radio-group class="questions d-flex justify-content-center" formControlName="smokeAnswers">
                    <mat-radio-button class="" *ngFor="let item of question2Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row" *ngIf="setQuestionEnumActive == page5Enum">
                <mat-radio-group class="questions d-flex justify-content-center"
                    formControlName="doctorFrequencyAnswers">
                    <mat-radio-button class="" *ngFor="let item of question2Answer" [value]="item.idAnswer"
                        (change)="test($event)">{{item.answer}}</mat-radio-button>
                </mat-radio-group>
            </div>

        </form>
        <div class="row">
            <div class="offset-md-1 col-md-3">
                <button mat-flat-button type="button" class="btn-secundary btn-block" (click)="exit()">
                    <span>Sair</span>
                </button>
            </div>
            <div class="col-md-3">
                <button *ngIf="setQuestionEnumActive > page1Enum" mat-flat-button type="button"
                    class="btn-secundary btn-block" (click)="previousQuestion()">
                    <span>Voltar</span>
                </button>
            </div>
            <div class="col-md-3">
                <button *ngIf="setQuestionEnumActive < page5Enum" mat-flat-button color="primary" class=" btn-block"
                    (click)="nextQuestion()">
                    <span *ngIf="isLoading == false">Continuar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button *ngIf="setQuestionEnumActive >= page5Enum" mat-flat-button color="primary" class=" btn-block"
                    (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>
    </div>
</div>