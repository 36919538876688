<div mat-dialog-content class="content">
    <h3><b>Sair do cadastro</b></h3>
    <div class="row">
        <div class="col-md-12">
            <p>Deseja sair do cadastro?</p>
            <p>Os dados não salvos serão perdidos</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button mat-stroked-button type="button" color="primary" class=" btn-block" (click)="confirmExit()">
                <span>Sair</span>
            </button>
            <button type="button" mat-flat-button class="btn-secundary btn-block" (click)="goBack()">
                <span>Voltar</span>
            </button>
        </div>
    </div>
</div>