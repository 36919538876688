import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UbsWebhookService } from 'src/app/shared/services/API/orchestrator-patient/ubs-webhook.service';
import { UBSRequest } from 'src/app/shared/services/requests/orchestrator-patient/ubs.request';
import { UtilsUBSService } from 'src/app/ubs-health-promotion/utils-ubs.service';

@Component({
  selector: 'app-end-modal',
  templateUrl: './end-modal.component.html',
  styleUrls: ['./end-modal.component.css']
})
export class EndModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) public locale: string,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<EndModalComponent>,
    public alertService: AlertService,
    public ubsWebhookService: UbsWebhookService,
    private utilsUBSService: UtilsUBSService,
  ) { }

  public uBSRequest: UBSRequest = new UBSRequest();
  
  ngOnInit(): void {
    this.uBSRequest = this.utilsUBSService.getUBSPatientRequest();
    this.postPatientData();
  }

  closeModal(){
    this.matDialogRef.close();
  }

  playSound(){

  }

  postPatientData() {
    this.ubsWebhookService.uBSWebhook(this.uBSRequest).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error) => {
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
      return;
    });
  }
}