import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MeasurerResponse } from '../../responses/ubs-health-promotion/measurer.response';


@Injectable({
  providedIn: 'root'
})
export class StadiometerMeasurementService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public read(urlBaseDevice: string): Observable<MeasurerResponse> {

    // let url = `http://${urlBaseDevice}:9090/stadiometer/read`;
    let url = `http://${urlBaseDevice}:5001/ubsPromotion/Stadiometer/read`;

    return this.httpClient.get<MeasurerResponse>(url, this.addHeaderDefault())
      .pipe(
        catchError(this.handleError)
      );
  }

  public start(urlBaseDevice: string): Observable<ReturnStruct> {

    // let url = `http://${urlBaseDevice}:9090/stadiometer/start`;
    let url = `http://${urlBaseDevice}:5001/ubsPromotion/Stadiometer/start`;

    return this.httpClient.get<ReturnStruct>(url, this.addHeaderDefault())
      .pipe(
        catchError(this.handleError)
      );
  }

  public stop(urlBaseDevice: string): Observable<ReturnStruct> {

    // let url = `http://${urlBaseDevice}:9090/stadiometer/stop`;
    let url = `http://${urlBaseDevice}:5001/ubsPromotion/Stadiometer/stop`;

    return this.httpClient.get<ReturnStruct>(url, this.addHeaderDefault())
      .pipe(
        catchError(this.handleError)
      );
  }
}
