export class UBSPatientStruct {
    public idPatient: number;
    public idHealthUnit: number;
    public idGender: number;
    public idEthnicity: number;
    public imc: number;
    public systolicPressure: number;
    public diastolicPressure: number;
    public optin: boolean;
    public patientCpf: string;
    public cns: string;
    public name: string;
    public socialName: string;
    public motherName: string;
    public fatherName: string;
    public patientBirthDate: Date;
    public height: number;
    public weight: number;
    public hasDiabetes: boolean;
    public hasHighCholesterol: boolean;
    public hasHypertension: boolean;
    public haveOverweightObesity: boolean;
    public householdIncome: string;
    public tookHypertensionMedication: boolean;
    public treatedDiabetes: boolean;
    public trackWeighWithProfessional: boolean;
    public takeMedicineCholesterol: boolean;
    public paCathegory: number;
    public token: string;
    public listIdHealthUnitInGroup: number[];
}