import { Component, EventEmitter, OnInit, Output, LOCALE_ID, Inject, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DowntimeService } from '../../downtime.service';
import { UtilsUBSService } from '../../utils-ubs.service';


@Component({
  selector: 'app-error-ubs-health-promotion',
  templateUrl: './error-ubs-health-promotion.component.html',
  styleUrls: ['./error-ubs-health-promotion.component.css']
})
export class ErrorUbsHealthPromotionComponent implements OnInit, OnDestroy {

  private title: string = 'error-ubs-health-promotion';

  constructor(private titleService: Title,
    private downtimeService: DowntimeService,
    private utilService: UtilsUBSService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public audio = new Audio();
  public downtime: boolean;


  ngOnInit(): void {
    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.utilService.removeListVisitidePages();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  playAudio() {

    this.audio.src = `../../../assets/audio/${environment.locale}/generic-error.mp3`;
    this.audio.load();
    this.audio.play();
  }
}