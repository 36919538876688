import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-household-income',
  templateUrl: './household-income.component.html',
  styleUrls: ['./household-income.component.css']
})
export class HouseholdIncomeComponent implements OnInit, OnDestroy {

  private title: string = 'birth-date-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) 
      this.router.navigate(['/ubs/erro']);
    
    this.journeyStageId = JourneyStageEnum.Identificacao;
    // this.idUbsScreen = UbsScreenEnum.HouseholdIncome;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1) 
      this.router.navigate(['/ubs/erro']);
    
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      householdIncome: ['', [Validators.required]],
    });

    if (this.utilsUBSService.getPatientData()){
      this.patientData = this.utilsUBSService.getPatientData();
      if (this.patientData.householdIncome != null){
        if(this.patientData.householdIncome.includes('R$100 - R$200')){
          this.model.get('householdIncome').setValue('0');
        }
        else if(this.patientData.householdIncome.includes('R$200 - R$500')){
          this.model.get('householdIncome').setValue('1');
        }
        else if(this.patientData.householdIncome.includes('R$500 - R$1000')){
          this.model.get('householdIncome').setValue('2');
        }
        else if(this.patientData.householdIncome.includes('R$1000 - R$2000')){
          this.model.get('householdIncome').setValue('3');
        }
        else if(this.patientData.householdIncome.includes('Acima de R$2000')){
          this.model.get('householdIncome').setValue('4');
      }
    }  
    }
    

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid) 
      return;
    
    this.audio.pause();
    let choiceModel = parseInt(this.model.get('householdIncome').value);

    if(choiceModel == 0) this.patientData.householdIncome = "R$100 - R$200";
    if(choiceModel == 1) this.patientData.householdIncome = "R$200 - R$500";
    if(choiceModel == 2) this.patientData.householdIncome = "R$500 - R$1000";
    if(choiceModel == 3) this.patientData.householdIncome = "R$1000 - R$2000";
    if(choiceModel == 4) this.patientData.householdIncome = "Acima de R$2000";
    
    this.utilsUBSService.createPatientData(this.patientData);
    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`])
  }

  playAudio(){
    this.audio.src = `../assets/audio/${environment.locale}/household-income.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    if (this.patientData.searchByCPF || this.patientData.searchByCNS || this.patientData.existsInBaseData)
      this.router.navigate([lastPage]);
    else
      this.router.navigate([`ubs/mother-complete-name-register`]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}