<div mat-dialog-content class="content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <h3>Insira o valor medido no aparelho</h3>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Insira o valor do Glicosímetro</mat-label>
                    <input matInput type="text" formControlName="glucometer" [mask]="masks.threeDigits">
                    <span matSuffix>mg/dL</span>
                    <mat-error *ngIf="model.get('glucometer').invalid">Informe a glicemia!</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span>Confirmar</span>
                </button>
                <a mat-flat-button type=" button" class="btn-secudary btn-block" (click)="clickClose()">
                    <span>Cancelar</span>
                </a>
            </div>
        </div>
    </form>
</div>