import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ImcCathegoryEnum } from 'src/app/shared/enum/imc-cathegory.enum';
import { PaCathegoryEnum } from 'src/app/shared/enum/pa-cathegory.enum';

@Component({
  selector: 'app-result-measures',
  templateUrl: './result-measures.component.html',
  styleUrls: ['./result-measures.component.css']
})
export class ResultMeasuresComponent implements OnInit, OnChanges {

  @Input() resultValue: string;
  @Input() referenceValue: string;
  @Input() riskFactorCount: number = 0;
  @Input() paCathegory: number;
  @Input() imcCathegory: number;
  @Input() colorCode: string;
  @Input() isPaResult: boolean = false;
  @Input() isImcResult: boolean = false;
  @Output() emitCathegory = new EventEmitter<string>();

  constructor() { }

  public title: string;
  public cathegory: string;
  public src: string;

  public PaCathegoryEnum = PaCathegoryEnum;

  public underweight: number = ImcCathegoryEnum.underweight;
  public normalWeight: number = ImcCathegoryEnum.normalWeight;
  public overweight: number = ImcCathegoryEnum.overweight;
  public obesity: number = ImcCathegoryEnum.obesity;

  ngOnInit(): void {
    this.resultValue = this.resultValue ? this.resultValue.replace('.', ',') : "";
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resultValue = changes.resultValue.currentValue ? changes.resultValue.currentValue.replace('.', ',') : "";
  }

  ngAfterContentChecked() {
    this.populateValues();
  }

  populateValues() {
    if (this.isImcResult) {
      this.title = 'Seu IMC:';
      if (this.imcCathegory == this.underweight) {
        this.src = "assets/images/clipboard-purple.png";
        this.cathegory = 'Abaixo do peso';
      }
      else if (this.imcCathegory == this.normalWeight) {
        this.src = "assets/images/clipboard-blue.png";
        this.cathegory = 'Peso normal';
      }
      else if (this.imcCathegory == this.overweight) {
        this.src = "assets/images/clipboard-yellow.png"
        this.cathegory = 'Sobrepeso';
      }
      else if (this.imcCathegory == this.obesity) {
        this.src = "assets/images/clipboard-red.png";
        this.cathegory = 'Obesidade';
      }
    }
    else if (this.isPaResult) {
      this.title = 'Sua Pressão Arterial:';
      if (this.paCathegory == this.PaCathegoryEnum.noAdditionalRisk) {
        this.src = "assets/images/heart-purple.png";
        this.cathegory = 'Pressão Normal';
      } else if (this.paCathegory == this.PaCathegoryEnum.borderline) {
        this.src = "assets/images/heart-red.png";
        this.cathegory = 'Pressão sem alteração (limítrofe*)';
      }
      else {
        this.cathegory = 'Hipertensão*';
      }
    }

    this.emitCathegory.emit(this.cathegory);
  }
}
