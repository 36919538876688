import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-data-confirm',
  templateUrl: './data-confirm.component.html',
  styleUrls: ['./data-confirm.component.css']
})
export class DataConfirmComponent implements OnInit, OnDestroy {

  constructor(
    private maskService: MaskService,
    private router: Router,
    private utilService: UtilsUBSService,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) { }

  public model: FormBuilder;
  public masks: Masks;
  public isLoading = true;
  public audio = new Audio();


  public patientData: PatientData;

  public journeyStageId: number = JourneyStageEnum.Identificacao;
  public idUbsScreen: number = UbsScreenEnum.DataConfirm;
  public uBSConfigData: UBSConfigData;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilService.getUBSData();

    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }

    this.isLoading = true;
    this.masks = this.maskService.getMasks();
    this.patientData = this.utilService.getPatientData();

    if (this.patientData.cpf != null)
      this.patientData.cpf = this.formatCPF(this.patientData.cpf);
    else if (this.patientData.cns != null)
      this.patientData.cns = this.formatCNS(this.patientData.cns);

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
      
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  forward() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);
  }

  back() {
    this.audio.pause();
    this.downtimeService.stopTimer();

    let visitedPages = this.utilService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/data-confirm.mp3`;
    this.audio.load();
    this.audio.play();
  }

  formatCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]/g, "");

    // return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    return cpf = `${cpf.substring(0, 3)}.***.***-${cpf.substring(9, 11)}`;
  }

  formatCNS(cns: string) {
    cns = cns.replace(/[^\d]/g, "");

    return cns.replace(/(\d{3})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4");
  }

  saveLastPage(){
    let visitedPages = this.utilService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilService.updateListVisitidePages(visitedPages);
  }
}