<div class="counseling-box"
    [ngClass]="{'measures-green': this.riskCategory == this.riskCategoryEnum.noRisk, 'measures-red': this.riskCategory == this.riskCategoryEnum.highRisk}">

    <div class="title-and-subtitle">
        <span class="title"
            [ngClass]="{'green-title': this.riskCategory == this.riskCategoryEnum.noRisk,'red-title': this.riskCategory == this.riskCategoryEnum.highRisk}">
            {{counselingTitle}}
        </span>
        <span class="subtitle">
            {{counseling}}
        </span>
    </div>
    <div class="icon-and-message"
        [ngClass]="{'green': riskCategoryColor == 'noAdditionalRisk', 'red': riskCategoryColor == 'highRisk' }">

        <!-- <div class="icon"
            [ngClass]="{'green': riskCategoryColor == 'noAdditionalRisk', 'red': riskCategoryColor == 'highRisk' }">
            
            <img src="/assets/images/counseling-icon-blue.png">
        </div> -->

        <span id="counseling-description" class="message"></span>

    </div>
</div>