import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetUBSResponse } from '../../responses/ubs-health-promotion/get-ubs-response';
import { ListUbsFemaleNamesResponse } from '../../responses/ubs-health-promotion/list-ubs-female-names.response';

@Injectable({
  providedIn: 'root'
})
export class UBSLookupService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getAllUBSFemaleNames(): Observable<ListUbsFemaleNamesResponse> {
    let uri = `UBSLookup/getAllFemaleNames`
    return this.httpClient.get<ListUbsFemaleNamesResponse>(environment.urlApiUbsHealthPromotion + uri, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}