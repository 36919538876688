import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-ubs-health-promotion-modal',
  templateUrl: './loading-ubs-health-promotion-modal.component.html',
  styleUrls: ['./loading-ubs-health-promotion-modal.component.css']
})
export class LoadingUbsHealthPromotionModal implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<LoadingUbsHealthPromotionModal>) { }

  ngOnInit(): void {
  }
}