import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';

@Component({
  selector: 'app-manual-blood-pressure-modal',
  templateUrl: './manual-blood-pressure-modal.component.html',
  styleUrls: ['./manual-blood-pressure-modal.component.css']
})
export class ManualBloodPressureModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ManualBloodPressureModalComponent>,
    public formBuilder: FormBuilder,
    public alertService: AlertService,
    public maskService: MaskService) { }

  public model: FormGroup;
  public masks: Masks;
  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      bloodPressure: ['', [Validators.required]],
    });
  }

  clickClose() {
    this.matDialogRef.close();
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha todos os campos!", AlertType.error);
      return;
    }
    let bloodPressure:string = this.model.get("bloodPressure").value;

    let sistolic = parseInt(bloodPressure.split('/')[0].replace(/[^0-9]+/g, ''));
    let diastolic = parseInt(bloodPressure.split('/')[1].replace(/[^0-9]+/g, ''));
    if (sistolic < diastolic) {
      this.alertService.show('Erro', 'O valor antes da "/" não pode ser menor que o valor após a "/".', AlertType.error);
      return;
    }
    this.matDialogRef.close({ sistolic, diastolic });
  }
}