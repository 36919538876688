import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ThermometerService } from 'src/app/shared/services/API/interface-measurer/thermometer.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { MeasureStruct, Thermometer } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { PagesEnum } from '../pages.enum';
import { ManualThermometerModalComponent } from './manual-thermometer-modal/manual-thermometer-modal.component';


@Component({
  selector: 'app-thermometer',
  templateUrl: './thermometer.component.html',
  styleUrls: ['./thermometer.component.css']
})
export class ThermometerComponent implements OnInit {

  constructor(private thermometerService: ThermometerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilsWelcomeService:UtilsWelcomeService) { }

  @Output("leaveMeasurer") leaveMeasurer: EventEmitter<any> = new EventEmitter();
  @Output("changePage") changePage: EventEmitter<any> = new EventEmitter();
  
  public measureWasMade: boolean = false;
  public thermometer: number;
  private syncInterval: any;
  private isRequesting: boolean = false;
  private measureStruct: MeasureStruct;

  public bloodPressure: number = PagesEnum.bloodPressure;
  public glucometer: number = PagesEnum.glucometer;
  

  ngOnInit(): void {
    let measure = this.utilsWelcomeService.getMeasureList();
    if (measure && measure[0]) {
      this.measureStruct = measure[0];
      if (measure[0].thermometer && measure[0].thermometer.temperature) {
        this.thermometer = measure[0].thermometer.temperature;
        this.measureWasMade = true;
      }
    } else {
      this.measureStruct = new MeasureStruct;
    }
    this.RestartThermometer();
  }

  callChangePage(page:number){
    this.changePage.next(page);
  }

  openManualModal() {
    const dialogRef = this.dialog.open(ManualThermometerModalComponent, {
      panelClass: 'modal-manual-thermomenter'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.thermometer = result.thermometer;
        if(!this.measureStruct.thermometer)this.measureStruct.thermometer = new Thermometer;
        this.measureStruct.thermometer.temperature = this.thermometer;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }
    });
  }


  startThermometer() {
    this.thermometerService.start("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startInterval();
    }, () => {
      this.alertService.show('Erro', "Não foi possível iniciar o Termômetro!", AlertType.error);
    });
  }

  RestartThermometer() {
    this.thermometerService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startThermometer();
    }, () => {
      this.alertService.show('Erro', "Não foi possível reiniciar o Termômetro!", AlertType.error);
    });
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.thermometerService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    });
  }

  readThermometer() {
    if(this.isRequesting) return;
    this.isRequesting = true;
    this.thermometerService.read('x').subscribe((response) => {
      this.isRequesting = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (!response.running) {
        clearInterval(this.syncInterval);
        this.RestartThermometer();
      }

      if(response.measurer){
        this.thermometer = parseFloat(response.measurer);
        if(!this.measureStruct.thermometer)this.measureStruct.thermometer = new Thermometer;
        this.measureStruct.thermometer.temperature = this.thermometer;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }

    });
  }

  startInterval() {
    this.syncInterval = setInterval(() => { this.readThermometer() }, 1000);
  }
}
