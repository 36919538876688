<header>
    <img src="assets/images/logo-branco.png" /> 
    <a class="back-header" routerLink="/"> <mat-icon aria-hidden="false" aria-label="">arrow_back</mat-icon>Voltar</a>
</header>
<div class="body-meassure">
    <h5><span>{{patient.patientName}}</span> realize suas medições com os aparelhos!</h5>

    <div class="row">
        <div class="col-4 text-center">
           <div class="meassure" [ngClass]="{'meassure-active': temperature != ''}">
            <h4 class="measure-title">Temperatura</h4>
            <span *ngIf="temperature != ''">{{temperature}} <b>ºC</b>
                <mat-icon aria-hidden="false" aria-label="">{{syncStatus}}</mat-icon>
            </span>  
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="temperature == ''"></mat-spinner>
           </div>
        </div>
        <div class="col-4 text-center">
            <div class="meassure" [ngClass]="{'meassure-active': glucose != ''}">
             <h4 class="measure-title">Glicemia</h4>
             <span *ngIf="glucose != ''">{{glucose}} <b>mg/dl</b>
                <mat-icon aria-hidden="false" aria-label="">{{syncStatus}}</mat-icon>
            </span>  
             <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="glucose == ''"></mat-spinner>
            </div>
         </div>
         <div class="col-4 text-center">
            <div class="meassure" [ngClass]="{'meassure-active': bloodPressureDiastole != ''}">
                <h4 class="measure-title">Pressão</h4>
                <span *ngIf="bloodPressureDiastole != ''">{{bloodPressure}} <b>mmHg</b>
                    <mat-icon aria-hidden="false" aria-label="">{{syncStatus}}</mat-icon>
                </span>  
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="bloodPressureDiastole == ''"></mat-spinner>
            </div>
         </div>
    </div>
    <div class="row">
        <div class="col-6 text-center">
            <div class="meassure meassure-bottom" [ngClass]="{'meassure-active': saturation != ''}">
                <h4 class="measure-title">Saturação/Oxigenação</h4>
                <span *ngIf="saturation != ''"> {{saturation}} <b>%</b> 
                    <mat-icon aria-hidden="false" aria-label="">{{syncStatus}}</mat-icon>
                </span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="saturation == ''"></mat-spinner>
            </div>
        </div>
        <div class="col-6 text-center">
            <div class="meassure meassure-bottom" [ngClass]="{'meassure-active': heartRate != ''}">
                <h4 class="measure-title">Frequência cardíaca</h4>
                <span *ngIf="heartRate != ''"> {{heartRate}} <b>bpm</b>
                    <mat-icon aria-hidden="false" aria-label="">{{syncStatus}}</mat-icon>
                </span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="heartRate == ''"></mat-spinner>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-6">
            <a mat-flat-button color="primary" class=" btn-block" [routerLink]="['/welcome/measure-history', {idPatient: idPatient, hash: hash}]">
                Histórico
            </a>
        </div>
        <div class="col-6">
            <span class="internet-connecting" *ngIf="isInternetAccess == false">Conenctando na internet...</span>
        </div>
    </div>
</div>