import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { GetPatientByCpfResponse } from '../../responses/medical-record/get-patient-by-cpf.response';
import { GetPatientNBMRequest } from '../../requests/medical-record/get-patient-nbm.request';
import { UBSCreatePatientRequest } from '../../requests/orchestrator-integration/ubs-create-patient.request';
import { UBSSearchResponse } from '../../responses/orchestrator-integration/ubs-search.response';

@Injectable({
  providedIn: 'root'
})
export class GetPatientByCpfService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getPatientByCPF(cpf: string, idHealthUnit: number, company: boolean, cns: string, jsonListIdHealthUnit: string): Observable<GetPatientByCpfResponse> {
    let uri = `GetPatientByCpfHash/idHealthUnit/${idHealthUnit}?`

    if (cpf) uri += `cpf=${cpf}&`;
    if (cns) uri += `cns=${cns}&`;
    if (company != null) uri += `company=${company}&`;
    if (company != null) uri += `jsonListIdHealthUnit=${jsonListIdHealthUnit}&`;

    return this.httpClient.get<GetPatientByCpfResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getPatientByFullTerm(idHealthUnit: number, body: GetPatientNBMRequest): Observable<GetPatientByCpfResponse> {
    let uri = `GetPatientByCpfHash/idHealthUnit/${idHealthUnit}`

    return this.httpClient.post<GetPatientByCpfResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }

  public CreatePatient(idHealthUnit: number, body: UBSCreatePatientRequest): Observable<UBSSearchResponse> {
    let uri = `GetPatientByCpfHash/CreatePatient/idHealthUnit/${idHealthUnit}`

    return this.httpClient.post<UBSSearchResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}
