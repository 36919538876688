<div mat-dialog-content class="content">
    <h3><b>Realmente deseja sair?</b></h3>
    <div class="row">
        <div class="col-md-12 text-warn">
            <b>Todas as medições feitas até o momento serão perdidas e essa ação é irrevessível.</b>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <button mat-flat-button type="submit" class="btn-secudary btn-block" (click)="clickConfirm()">
                <span>Sim</span>
            </button>
        </div>
        <div class="col-md-6">
            <button mat-flat-button type="submit" color="primary" class=" btn-block" (click)="clickClose()">
                <span>Não</span>
            </button>
        </div>
    </div>
</div>