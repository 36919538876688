<div class="top-spacing ubs">
    <div class="title-error-component">
        <span i18n> <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="Menu apps" (click)="playAudio()"/>
            ERRO AO MEDIR A PRESSÃO ARTERIAL
        </span>
    </div>
    <div class="conteiner-red-box">
        <div class="error-img">
            <img src="assets/images/error-round.png" />
        </div>
        <div class="error-message" i18n>
            Não foi possível obter os resultados da sua Pressão Arterial. Por favor, solicite o suporte da equipe da UBS.
        </div>
    </div>
    <div class="button-containers">
        <button mat-flat-button class="block btn-back" (click)="callRetryMeasurer()">
            <div>
                <mat-icon class="icon-back material-symbols-rounded">arrow_back</mat-icon>
            </div>
            <span i18n>VOLTAR</span>
        </button>
        <button mat-flat-button class="block btn-go" (click)="callEndFlow()">
            <span i18n>ENCERRAR</span>
        </button>
    </div>
</div>