import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingUbsHealthPromotionModal } from 'src/app/shared/components/loading-ubs-health-promotion-modal/loading-ubs-health-promotion-modal.component';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { Title } from '@angular/platform-browser';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { PaMeasurementService } from 'src/app/shared/services/API/ubs-health-promotion/ubs-pa-measurer.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { listMockPa } from 'src/app/ubs-health-promotion/mock-data';

@Component({
  selector: 'app-measurement-guidelines-pa',
  templateUrl: './measurement-guidelines-pa.component.html',
  styleUrls: ['./measurement-guidelines-pa.component.css']
})
export class MeasurementGuidelinesPaComponent implements OnInit, OnDestroy {

  private title: string = 'measurement-guidelines-pa';

  constructor(
    private titleService: Title,
    private paMeasurementService: PaMeasurementService,
    private utilsUBSService: UtilsUBSService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }
  private thisScreen: UbsScreenEnum = UbsScreenEnum.MeasurementGuidelinesPa;
  public isError = false;
  public isLoading = false;
  public audio = new Audio();
  private uBSConfigData: UBSConfigData;
  public connectionCount: number = 0;
  public startInterval: any;
  public paMeasurement: any;
  public readInterval: any;
  public patient: PatientData;
  public urlBaseDevice: string;
  public seconds = 0;
  public idUbsScreen = UbsScreenEnum.MeasurementGuidelinesPa;
  public journeyStage: number = JourneyStageEnum.MedirPressao;
  public pageIndex: number;
  public mockTimeout;
  private readTime: number = 0;
  private inRequest: boolean = false;
  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }

    this.urlBaseDevice = this.uBSConfigData.ipDevice;
    //this.urlBaseDevice = "192.168.0.46";
    this.activateDataStream();
  }

  ngOnDestroy() {
    this.audio.pause();
    clearInterval(this.startInterval);
    clearInterval(this.readInterval);
    clearTimeout(this.mockTimeout);
  }

  activateDataStream() {
    if (this.uBSConfigData.isClientTest) {
      let $this = this;
      this.mockTimeout = setTimeout(() => {
        let randomPA = listMockPa[Math.floor(Math.random() * listMockPa.length)]
        randomPA = listMockPa[1]
        console.log(randomPA);
        $this.paMeasurement = randomPA.split("/");
        $this.patient = $this.utilsUBSService.getPatientData();
        if (!$this.patient) {
          $this.patient = new PatientData();
        }
        $this.patient.sistolicPressure = $this.paMeasurement[0];
        $this.patient.diastolicPressure = $this.paMeasurement[1];
        $this.utilsUBSService.createPatientData($this.patient);
        $this.saveLastPage();
        $this.router.navigate([`ubs${$this.uBSConfigData.listUBSStruct[$this.pageIndex + 1].screenUrl}`]);
        clearTimeout($this.mockTimeout);
      }, 5000);

      return;
    }

    this.connectionCount += 1;

    if (this.connectionCount > 2) {
      this.router.navigate(['/ubs/erro']);
      return;
    }
    this.paMeasurementService.start(this.urlBaseDevice).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.retryConnection();
        return;
      }
      this.isLoading = false;
      this.startRead();

    },
      (error) => {
        this.isLoading = false;
        this.retryConnection();
        // this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  readDataStream() {
    if (this.inRequest) return;
    this.inRequest = true;
    this.paMeasurementService.read(this.urlBaseDevice).subscribe((response) => {
      this.inRequest = false;
      if (response.isError || !response.running) {
        this.isLoading = false;
        clearInterval(this.readInterval);
        this.retryConnection();
        return;
      }

      if (response.message) {
        this.paMeasurement = response.message.split("/");
        this.patient = this.utilsUBSService.getPatientData();
        if (!this.patient) {
          this.patient = new PatientData();
        }
        this.patient.sistolicPressure = this.paMeasurement[0];
        this.patient.diastolicPressure = this.paMeasurement[1];
        this.utilsUBSService.createPatientData(this.patient);
        clearInterval(this.readInterval);
        this.deactivateDataStream();
        this.isLoading = false;
        this.saveLastPage();
        this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);
      }

    },
      (error) => {
        this.isLoading = false;
        // this.alertService.show('Erro esperado', error, AlertType.error);
      });

  }

  deactivateDataStream() {
    this.paMeasurementService.stop(this.urlBaseDevice).subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.router.navigate(['/ubs/erro']);
        return;
      }
      this.isLoading = false;

    },
      (error) => {
        this.isLoading = false;
        // this.alertService.show('Erro inesperado', error, AlertType.error);
        this.router.navigate(['/ubs/erro']);
      });
  }

  startRead() {
    let $this = this;
    this.readInterval = setInterval(() => {
      $this.readTime += 1;
      $this.readDataStream();
      if ($this.readTime >= 6000) {
        $this.isError = true;
        clearInterval($this.readInterval);
      }
    }, 100);
  }

  OpenLoadingModal() {
    const dialogRef = this.dialog.open(LoadingUbsHealthPromotionModal, {
      width: '854px',
      height: '452px',
      panelClass: 'loading-ubs-health-promotion-modal',
      disableClose: true,
    });
  }

  endFlowMeasurer() {
    this.audio.pause();
    let lastScreen = this.uBSConfigData.listUBSStruct[0];
    if (!lastScreen) {
      this.router.navigate(['/ubs/erro']);
    }
    this.router.navigate(['/ubs' + lastScreen.screenUrl]);
  }

  retryConnection() {
    this.activateDataStream();
  }

  retryRead() {
    this.readDataStream();
  }

  retryMeasurer() {
    this.isError = false;
    this.activateDataStream();
  }

  goBack() {
    this.audio.pause();
    let screen = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.thisScreen.valueOf());
    if (screen == -1) {
      this.router.navigate(['/ubs/erro']);
    }
    let lastScreen = this.uBSConfigData.listUBSStruct[screen - 1];
    if (!lastScreen) {
      this.router.navigate(['/ubs/erro']);
    }

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  playAudio() {
    this.audio.src = `../../../assets/audio/${environment.locale}/guidlines-pa.mp3`;
    this.audio.load();
    this.audio.play();
  }

  saveLastPage() {
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if (!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}