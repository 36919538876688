import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-infos-modal',
  templateUrl: './infos-modal.component.html',
  styleUrls: ['./infos-modal.component.css']
})
export class InfosModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<InfosModalComponent>,
    private alertService: AlertService,
  ) { }

  public isLoading: boolean;
  public audio = new Audio();

  ngOnInit(): void {
    this.isLoading = false;
  }

  closeModal() {
    this.audio.pause();
    this.matDialogRef.close();
  }

  goBack() {
    this.audio.pause();
    this.matDialogRef.close();
  }
  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/gender-modal.mp3`;
    this.audio.load();
    this.audio.play();
  }
}