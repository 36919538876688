<div class="container ubs birth-date-verifier">
    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-test">
        <div class="row">
            <div class="col-12 container-title">
                <div class="title">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
                    <span i18n>CONFIRME O ANO DO SEU NASCIMENTO</span>
                </div>
            </div>        
        </div>
        
        <form class="form radio-ubs" [formGroup]="model" (ngSubmit)="submit()">
            <mat-radio-group aria-label="Select an option" formControlName="birthDate">
                <mat-radio-button *ngFor="let item of listDates" [value]="item"><span i18n class="radio-label">{{item}}</span></mat-radio-button>
            </mat-radio-group>
        </form>
    </div>
    
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon *ngIf="!isLoading" class="span-icon" >arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
        <button mat-flat-button (click)="submit()" class="block btn-go btn-ubs" [disabled]="this.model.invalid">
            <span i18n *ngIf="isLoading == false">AVANÇAR</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>