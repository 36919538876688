<header>
    <img src="assets/images/logo-branco.png" /> 
    <a class="back-header" [routerLink]="['/patient-measurement', {idPatient: idPatient, hash: hash}]"> <mat-icon aria-hidden="false" aria-label="">arrow_back</mat-icon>Voltar</a>
</header>
<div class="body-meassure">
    <div class="" *ngIf="listNotSyncMeasureStruct && listNotSyncMeasureStruct.length > 0 || listSyncMeasureStruct && listSyncMeasureStruct.length > 0">
        <div class="title-header">
            <h1>Medidas não sincronizadas</h1>
        </div>
        <ng-container *ngIf="listNotSyncMeasureStruct && listNotSyncMeasureStruct.length > 0">
            <cdk-accordion-item *ngFor="let item of listNotSyncMeasureStruct; let index = index;"
                #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button" tabindex="0"
                [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                    class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                    {{item.measureTime | date:'dd/MM/yy, H:mm' }} - {{item.isServerSync? 'Sincronizado com sucesso': 'Sincronizando'}}
                    <span [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-description">
                        Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                    </span>
                </div>
                <div class="profile-register-accordion-item-body" role="region"
                    [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index">
    
                    <div class="row">                    
                        <div class="col-4 text-center">
                            <label>Temperatura</label>
                            <p>{{item.thermometer.temperature}} C°<mat-spinner [diameter]="15" class="mat-spinner-btn" *ngIf="item.isServerSync == false"></mat-spinner></p>
                        </div>
                        <div class="col-4 text-center">
                            <label>Freqência Cardíaca</label>
                            <p>
                                {{item.oximeter.heartRate}} bpm
                                <mat-spinner [diameter]="15" class="mat-spinner-btn" *ngIf="item.isServerSync == false"></mat-spinner>
                            </p>
                        </div>
                        <div class="col-4 text-center">
                            <label>Glicose</label>
                            <p>
                                {{item.glucometer.glucose}} mg/dL
                                <mat-spinner [diameter]="15" class="mat-spinner-btn" *ngIf="item.isServerSync == false"></mat-spinner>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center">
                            <label>Pressão Arterial</label>
                            <p>
                                {{item.bloodPressure.bloodPressureSystole}}/{{item.bloodPressure.bloodPressureDiastole}} mmHg
                                <mat-spinner [diameter]="15" class="mat-spinner-btn" *ngIf="item.isServerSync == false"></mat-spinner>
                            </p>
                        </div>
                        <div class="col-6 text-center">
                            <label>Saturação de O2</label>
                            <p>
                                {{item.oximeter.saturation}} %
                                <mat-spinner [diameter]="15" class="mat-spinner-btn" *ngIf="item.isServerSync == false"></mat-spinner>
                            </p>
                        </div>
                    </div>
                </div>
            </cdk-accordion-item>
        </ng-container>
        <div *ngIf="!listNotSyncMeasureStruct || listNotSyncMeasureStruct.length == 0">
            <h3>Não há novas atualizações para serem sincronizadas.</h3>
        </div>
        <div class="title-header">
            <h1>Medidas sincronizadas</h1>
        </div>
        <ng-container *ngIf="listSyncMeasureStruct  && listSyncMeasureStruct.length > 0">
            <cdk-accordion-item *ngFor="let item of listSyncMeasureStruct; let index = index;"
                #accordionItemModule="cdkAccordionItem" class="profile-register-accordion-item" role="button" tabindex="0"
                [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItemModule.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div [ngClass]="{'profile-register-accordion-item-header-expanded': accordionItemModule.expanded}"
                    class="profile-register-accordion-item-header" (click)="accordionItemModule.toggle()">
                    {{item.measureTime | date:'dd/MM/yy, H:mm' }} - {{item.isServerSync? 'Sincronizado com sucesso': 'Sincronizando'}}
                    <span [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                        class="profile-register-accordion-item-description">
                        Clique para {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                    </span>
                </div>
                <div class="profile-register-accordion-item-body" role="region"
                    [style.display]="accordionItemModule.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index">
                    
                    <div class="row">                    
                        <div class="col-4 text-center">
                            <label>Temperatura</label>
                            <p>{{item.thermometer.temperature}} C°</p>
                        </div>
                        <div class="col-4 text-center">
                            <label>Freqência Cardíaca</label>
                            <p>{{item.oximeter.heartRate}} bpm</p>
                        </div>
                        <div class="col-4 text-center">
                            <label>Glicose</label>
                            <p>{{item.glucometer.glucose}} mg/dL</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center">
                            <label>Pressão Arterial</label>
                            <p>{{item.bloodPressure.bloodPressureSystole}}/{{item.bloodPressure.bloodPressureDiastole}} mmHg</p>
                        </div>
                        <div class="col-6 text-center">
                            <label>Saturação de O2</label>
                            <p>{{item.oximeter.saturation}} %</p>
                        </div>
                    </div>
                </div>
            </cdk-accordion-item>
        </ng-container>
        <div *ngIf="!listSyncMeasureStruct || listSyncMeasureStruct.length == 0">
            <h3>Não há medidas sincronizadas ainda.</h3>
        </div>
    </div>
    <div *ngIf="(!listSyncMeasureStruct || listSyncMeasureStruct.length == 0) && (!listNotSyncMeasureStruct || listNotSyncMeasureStruct.length == 0)">
        <h2>Você não possui um histórico de medição, ao realizar sua medição ele aparecerá aqui.</h2> 
    </div>
</div>