import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-exit-modal',
  templateUrl: './confirm-exit-modal.component.html',
  styleUrls: ['./confirm-exit-modal.component.css']
})
export class ConfirmExitModalComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ConfirmExitModalComponent>) { }

  ngOnInit(): void {
  }

  confirmExit(){
    this.matDialogRef.close({ register: true });
  }

  goBack(){
    this.matDialogRef.close();
  }

}
