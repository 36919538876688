import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BloodPressureService } from 'src/app/shared/services/API/interface-measurer/blood-pressure.service';
import { GlucometerService } from 'src/app/shared/services/API/interface-measurer/glucometer.service';
import { OximeterService } from 'src/app/shared/services/API/interface-measurer/oximeter.service';
import { ThermometerService } from 'src/app/shared/services/API/interface-measurer/thermometer.service';
import { MeasureService } from 'src/app/shared/services/API/sync-measurer/measure.service';
import { SetActivePatientService } from 'src/app/shared/services/API/sync-measurer/set-active-patient.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { MeasureRequest} from 'src/app/shared/services/requests/sync-measurer/measure.request';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { LastCallStruct } from 'src/app/shared/services/structs/interface-measurer/last-call.struct';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { BloodPressure, Glucometer, MeasureStruct, Oximeter, Thermometer } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { WebsocketMeasurerService } from "src/app/shared/services/websocket-measurer.service";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  
  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private setActivePatientService: SetActivePatientService,
    private utilsWelcomeService: UtilsWelcomeService,
    private maskService: MaskService,
    private bloodPressureService: BloodPressureService,
    private oximeterService: OximeterService,
    private thermometerService: ThermometerService,
    private glucometerService: GlucometerService,
    private measureService: MeasureService,
  ) { }

  public hash: string;
  public idPatient: number;
  public patient: PatientStruct;
  public masks: Masks;
  public isInternetAccess:boolean;
  private intervalInteretTest;
  private intervalActivePatient;
  private intervalMeasure;
  private updateTimeout;
  public datas: LastCallStruct;
  public measureStruct: MeasureStruct;
  public syncStatus: string;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('hash'))
      this.hash = this.activatedRoute.snapshot.paramMap.get('hash');

    if (this.activatedRoute.snapshot.paramMap.get('idPatient'))
      this.idPatient = parseInt(this.activatedRoute.snapshot.paramMap.get('idPatient'));
      
    this.masks = this.maskService.getMasks();
    this.patient = this.utilsWelcomeService.getPatient();
    this.bloodPressureDiastole = "";
    this.bloodPressureSystole = "";
    this.glucose = "";
    this.temperature = "";
    this.saturation = "";
    this.heartRate = "";
    this.intervalActivePatient = null;
    this.setActivePatient()
    this.testInternet();
    this.processMeasurements();
    this.bloodPressureStart();
    this.oximeterStart();
    this.glucometerStart();
    this.thermometerStart();
  }

  ngOnDestroy(): void{
    clearInterval(this.intervalInteretTest);
    clearInterval(this.intervalMeasure);
    clearTimeout(this.updateTimeout);
    if(this.intervalActivePatient != null){
      clearInterval(this.intervalActivePatient);
    }
      
    this.bloodPressureStop();
    this.oximeterStop();
    this.glucometerStop();
    this.thermometerStop();
  }

  public bloodPressureDiastole:string;
  public bloodPressureSystole:string;
  public bloodPressure:string;
  public glucose:string;
  public temperature:string;
  public saturation:string;
  public heartRate:string;
  private measureMaxInterval:number = 300000; //Representa 5 minutos;
  public listMeasureStruct: MeasureStruct[];
  public auxLastCallMeasures: Date;

  setActivePatient(){
    let requestFunc = ()=>{
      this.setActivePatientService.setActivePatient(this.idPatient, this.hash).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        clearInterval(this.intervalActivePatient);
      },
      (error)=>{
        console.log(error)    
        // this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    };

    this.intervalActivePatient = setInterval(requestFunc, 5000)
  }

  public testInternet():void{
    let testFunc = ()=>{
      console.log("testing internet");
      this.setActivePatientService.serviceActive().subscribe((response)=>{
        this.isInternetAccess = true;
        if(response.status != 200)
          this.isInternetAccess = false;
      },
      (error)=>{
        this.isInternetAccess = true;
        if(error.status != 200)
          this.isInternetAccess = false;
      });
    };

    this.intervalInteretTest = setInterval(testFunc, 5000);
  }

  processMeasurements(){
    let processMeasure = () => {
      clearTimeout(this.updateTimeout);
      this.datas = new LastCallStruct();
      this.listMeasureStruct = this.utilsWelcomeService.getMeasureList();
      if(!this.listMeasureStruct || this.listMeasureStruct.length == 0) {
        this.listMeasureStruct = [];
        this.measureStruct = new MeasureStruct();
        this.measureStruct.thermometer = new Thermometer();
        this.measureStruct.oximeter = new Oximeter();
        this.measureStruct.glucometer = new Glucometer();
        this.measureStruct.bloodPressure = new BloodPressure();
      }
      else{
        this.auxLastCallMeasures = new Date(this.listMeasureStruct[this.listMeasureStruct.length-1].measureTime);
        if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() > this.measureMaxInterval){
          this.measureStruct = new MeasureStruct();
          this.measureStruct.thermometer = new Thermometer();
          this.measureStruct.oximeter = new Oximeter();
          this.measureStruct.glucometer = new Glucometer();
          this.measureStruct.bloodPressure = new BloodPressure();
        }
      }
      
      //Thermometer measurer
      this.thermometerRead();
      
      //Oximeter Measurer
      this.oximeterRead();
      
      //Glucometer Measurer
      this.glucometerRead();
      
      //BloodPressure Measurer
      this.bloodPressureRead();
      
      this.updateTimeout = setTimeout(() => this.updateListMeasures(), 1500)    
    }

    this.intervalMeasure = setInterval(processMeasure, 5000);
  }

  thermometerRead(){
    let lastCallThermometer = new Date(this.datas.lastCallThermometer);
    this.thermometerService.read("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (response.running && response.measurer)
      {
        if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() < this.measureMaxInterval){
          this.listMeasureStruct[this.listMeasureStruct.length-1].thermometer.temperature = parseFloat(response.measurer);
          this.listMeasureStruct[this.listMeasureStruct.length-1].isServerSync = false;
          this.syncStatus="schedule";
        }
        else{
          this.measureStruct.thermometer.temperature = parseInt(response.measurer);
          this.measureStruct.isServerSync = false;
          this.syncStatus="schedule";
        }
        this.datas.lastCallBloodPressure = new Date();
        this.temperature = response.measurer;
      }
      else if(!response.running || new Date().getTime() - new Date(lastCallThermometer).getTime() > this.measureMaxInterval){
        //this.thermometerStop();
        this.thermometerStart();
        this.datas.lastCallThermometer = new Date();
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  thermometerStart(){
    this.thermometerService.start("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  thermometerStop(){
    this.thermometerService.stop("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  oximeterRead(){
    let lastCallOximeter = new Date(this.datas.lastCallOximeter);
    this.oximeterService.read("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (response.running && response.measurer)
      {
        if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() < this.measureMaxInterval){
          this.listMeasureStruct[this.listMeasureStruct.length-1].oximeter.heartRate = parseInt(response.measurer.split('/')[1]);
          this.listMeasureStruct[this.listMeasureStruct.length-1].oximeter.saturation = parseInt(response.measurer.split('/')[0]);
          this.listMeasureStruct[this.listMeasureStruct.length-1].isServerSync = false;
          this.syncStatus="schedule";
        }
        else{
          this.measureStruct.oximeter.heartRate = parseInt(response.measurer.split('/')[1]);
          this.measureStruct.oximeter.saturation = parseInt(response.measurer.split('/')[0]);
          this.measureStruct.isServerSync = false;
          this.syncStatus="schedule";
        }
        
        this.datas.lastCallOximeter = new Date();
        this.heartRate = response.measurer.split('/')[1];
        this.saturation = response.measurer.split('/')[0];
      }
      else if(!response.running || new Date().getTime() - new Date(lastCallOximeter).getTime() > this.measureMaxInterval){
        //this.oximeterStop();
        this.oximeterStart();
        this.datas.lastCallOximeter = new Date();
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  oximeterStart(){
    this.oximeterService.start("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  oximeterStop(){
    this.oximeterService.stop("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }


  glucometerRead(){
    let lastCallGlucometer = new Date(this.datas.lastCallGlucometer);
    this.glucometerService.read("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (response.running && response.measurer)
      {
        if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() < this.measureMaxInterval){
          this.listMeasureStruct[this.listMeasureStruct.length-1].glucometer.glucose = parseInt(response.measurer);
          this.listMeasureStruct[this.listMeasureStruct.length-1].isServerSync = false;
          this.syncStatus="schedule";
        }
        else{
          this.measureStruct.glucometer.glucose = parseInt(response.measurer);
          this.measureStruct.isServerSync = false;
          this.syncStatus="schedule";
        }
      
        this.datas.lastCallBloodPressure = new Date();
        this.glucose = response.measurer;
      }
      else if(!response.running || new Date().getTime() - new Date(lastCallGlucometer).getTime() > this.measureMaxInterval){
        //this.glucometerStop();
        this.glucometerStart();
        this.datas.lastCallGlucometer = new Date();
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  glucometerStart(){
    this.glucometerService.start("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  glucometerStop(){
    this.glucometerService.stop("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }


  bloodPressureRead(){
    let lastCallBloodPressure = new Date(this.datas.lastCallBloodPressure);
    this.bloodPressureService.read("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (response.running && response.measurer)
      {
        if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() < this.measureMaxInterval){
          this.listMeasureStruct[this.listMeasureStruct.length-1].bloodPressure.bloodPressureSystole = parseInt(response.measurer.split('/')[0]);
          this.listMeasureStruct[this.listMeasureStruct.length-1].bloodPressure.bloodPressureDiastole = parseInt(response.measurer.split('/')[1]);
          this.listMeasureStruct[this.listMeasureStruct.length-1].isServerSync = false;
          this.syncStatus="schedule";
        }
        else{
          this.measureStruct.bloodPressure.bloodPressureSystole = parseInt(response.measurer.split('/')[0]);
          this.measureStruct.bloodPressure.bloodPressureDiastole = parseInt(response.measurer.split('/')[1]);
          this.measureStruct.isServerSync = false;
          this.syncStatus="schedule";
        }
        
        this.datas.lastCallBloodPressure = new Date();
        this.bloodPressureDiastole = response.measurer.split('/')[1];
        this.bloodPressureSystole = response.measurer.split('/')[0];
        this.bloodPressure = `${this.bloodPressureSystole}/${this.bloodPressureDiastole}`
      }
      else if(!response.running || new Date().getTime() - new Date(lastCallBloodPressure).getTime() > this.measureMaxInterval){
        //this.bloodPressureStop();
        this.bloodPressureStart();
        this.datas.lastCallBloodPressure = new Date();
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  bloodPressureStart(){
    this.bloodPressureService.start("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  bloodPressureStop(){
    this.bloodPressureService.stop("x").subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  sendMeasurements(){
    let listMeasuresToSend = this.listMeasureStruct.filter(x => x.isServerSync == false);

    let measureRequest: MeasureRequest = new MeasureRequest();
    measureRequest.listMeasureStruct = listMeasuresToSend;
    measureRequest.syncMeasurerHash = this.hash;
    measureRequest.idPatient = this.utilsWelcomeService.getPatient().idPatient;
    this.syncStatus="check";
    this.measureService.sendMeasurements(measureRequest).subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.syncStatus="done_all";
      this.listMeasureStruct.forEach((measurement)=>{
        if(!measurement.isServerSync){
          measurement.isServerSync = true;
        }
      })

      this.utilsWelcomeService.storeMeasureList(this.listMeasureStruct);
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  updateListMeasures(){

    if(this.measureStruct && ((this.measureStruct.bloodPressure.bloodPressureDiastole && this.measureStruct.bloodPressure.bloodPressureSystole)
    || (this.measureStruct.glucometer.glucose)
    || (this.measureStruct.thermometer.temperature) 
    || (this.measureStruct.oximeter.heartRate && this.measureStruct.oximeter.saturation))){
      this.measureStruct.measureTime = new Date();
    }

    if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() < this.measureMaxInterval){
      this.listMeasureStruct[this.listMeasureStruct.length-1].measureTime = new Date();
    }

    if(new Date().getTime() - new Date(this.auxLastCallMeasures).getTime() > this.measureMaxInterval || (!this.listMeasureStruct || this.listMeasureStruct.length  == 0)){

      if(this.measureStruct && ((this.measureStruct.bloodPressure.bloodPressureDiastole && this.measureStruct.bloodPressure.bloodPressureSystole)
      || (this.measureStruct.glucometer.glucose)
      || (this.measureStruct.thermometer.temperature) 
      || (this.measureStruct.oximeter.heartRate && this.measureStruct.oximeter.saturation))){
        this.measureStruct.isServerSync = false;
        this.listMeasureStruct.push(this.measureStruct);
      }
    }
    
    this.utilsWelcomeService.storeMeasureList(this.listMeasureStruct);
    
    //Enviar medidas para api se isInternetAccess = true;
    if(this.isInternetAccess){
      this.sendMeasurements();
    }
  }
}
 