import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-is-not-me-modal',
  templateUrl: './is-not-me-modal.component.html',
  styleUrls: ['./is-not-me-modal.component.css']
})
export class IsNotMeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<IsNotMeModalComponent>) { }

  ngOnInit(): void {

  }

  clickClose() {
    this.matDialogRef.close();
  }
}