import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { ListPatientComponent } from './pages/list-patient/list-patient.component';
import { ConfirmPatientModalComponent } from './pages/list-patient/confirm-patient-modal/confirm-patient-modal.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MeasureHistoryComponent } from './pages/measure-history/measure-history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [WelcomeComponent, ListPatientComponent, ConfirmPatientModalComponent, MeasureHistoryComponent],
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
  ],
  providers:[
    AuthGuard
  ],
})
export class WelcomeModule { }
