<div class="container-body">
    <div class="content">
        <div class="button-container">
            <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="Menu apps"
                (click)="playAudio()" />
            <div class="close-button">
                <mat-icon mat-icon aria-hidden="false" aria-label="sound" (click)="close()">close</mat-icon>
            </div>
        </div>
        <span>
            <p i18n>Para continuar, você deverá informar seus dados cadastrais 
                (CPF ou CNS, data de nascimento, sexo) e dados de saúde. 
                Esses dados serão usados para avaliar riscos cardiovasculares e 
                permitir que a equipe da UBS te ajude a cuidar da saúde. </p>
        </span>
        <span>
            <p i18n>Os dados ficarão armazenados sob responsabilidade da 
                Prefeitura de São Paulo, de acordo com as diretrizes da Lei 
                Geral de Proteção de Dados (Lei Federal 13.709/2018).</p>
        </span>
        
        <span>
            <span (click)="openLgpdInfo()">
                <p class="lgpd" i18n>Para mais informações, clique aqui.</p>
            </span>
            <p i18n>Ao clicar em continuar, você consente em informar estes dados.</p>
        </span>
    </div>
    <div>
        <button mat-flat-button class="button-continue btn-ubs" (click)="continue()">
            <span i18n>CONTINUAR</span>
            <div class="btn-icon">
                <mat-icon>arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>