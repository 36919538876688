import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MeasureRequest } from '../../requests/sync-measurer/measure.request';
import { LookupQuestionAnswerResponse } from '../../responses/sync-measurer/lookup-question-answer.response';
import { PatientAnaminesisResponse } from '../../responses/sync-measurer/patient-anamnesis.response';
import { AnamnesisRequest } from '../../requests/sync-measurer/anamnesis.request';


@Injectable({
  providedIn: 'root'
})
export class PatientAnamnesisService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public lookupQuestionAnswers(): Observable<LookupQuestionAnswerResponse> {

    let url = `Anamnesis/lookupQuestionAnswers`

    return this.httpClient.get<LookupQuestionAnswerResponse>(environment.urlApiSyncMeasurer + url, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
  public getQuestionAnswered(idPatient: number, hash: string): Observable<PatientAnaminesisResponse> {

    let url = `Anamnesis/patientAnamnesis/idPatient/${idPatient}?hash=${hash}`

    return this.httpClient.get<PatientAnaminesisResponse>(environment.urlApiSyncMeasurer + url, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
  public saveAnaminesis(request: AnamnesisRequest): Observable<ReturnStruct> {

    let url = `Anamnesis/`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + url, request, this.addHeaderHash())
      .pipe(
        catchError(this.handleError)
      )
  }
}