<div class="pa-conteiner ubs">
    <app-journey-stage *ngIf="!isError" [journeyStageId]="journeyStage"></app-journey-stage>
    <div *ngIf="!isError" class="guidelines">
        <div class="guidelines-titles">
            <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
            <span i18n>CHEGOU A HORA DE VOCÊ MEDIR A PRESSÃO</span>
        </div>
        <div class="guidelines-content">
            <div class="row">
                <div class="col-8">
                    <div class="content-block">
                        <div class="red-dot">1</div>
                        <div class="guidelines-text">
                            <span i18n>
                                Sente-se e ajuste a <b>altura do banco</b> para
                                que o aparelho fique na <b>altura do seu coração</b>.
                            </span>
                        </div>
                    </div>
                    <div class="content-block">
                        <div class="red-dot">2</div>
                        <div class="guidelines-text">
                            <span i18n>
                                Coloque seu braço no aparelho até que o <b>cotovelo fique bem apoiado</b>. A <b>palma da mão deve ficar virada para cima</b>.
                            </span>
                        </div>
                    </div>
                    <div class="content-block">
                        <div class="red-dot">3</div>
                        <div class="guidelines-text">
                            <span i18n>
                                Aperte o <b>botão verde para começar</b>. Fique em silêncio e imóvel. Aguarde a medição
                                tranquilamente.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="img-pa">
                    <img src="assets/images/measurement-pa.png" />
                </div>
            </div>
            <div class="button-container">
                <button mat-flat-button class="block btn-back" (click)="goBack()">
                    <div>
                        <mat-icon class="icon-back material-symbols-rounded">arrow_back</mat-icon>
                    </div>
                    <span i18n>VOLTAR</span>
                </button>
            </div>
        </div>
    </div>
    <app-error-pa-ubs-health-promotion *ngIf="isError" (endFlowMeasurer)="endFlowMeasurer()"
        (retryMeasurer)="retryMeasurer()">
    </app-error-pa-ubs-health-promotion>
</div>