export enum QuestionEnum{
    healthStatus = 1,
    deseasesMedicine = 3,
    pain = 4,
    sleepDificults = 5,
    sleepMedicine = 6,
    useAlcohol = 7,
    smoke = 8,
    phisicalActivities = 9,
    doctorFrequency = 10,
  }
  