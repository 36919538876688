import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { PatientSyncMeasurerResponse } from '../../responses/sync-measurer/patient-sync-measurer.response';
import { PatientSyncMeasurerStruct } from '../../structs/sync-measurer/patient-sync-measurer.struct';


@Injectable({
  providedIn: 'root'
})
export class SyncMeasurerPatientService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public patientSyncMeasurer(idSyncMeasurer: number, idPatient: number): Observable<PatientSyncMeasurerResponse> {

    let url = `SyncMeasurerPatient/idPatient/${idPatient}/idSyncMeasurer/${idSyncMeasurer}`

    return this.httpClient.get<PatientSyncMeasurerResponse>(environment.urlApiSyncMeasurer + url, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }

  public CreatePatientSyncMeasurer(patientSyncMeasurerRequest: PatientSyncMeasurerStruct): Observable<PatientSyncMeasurerResponse> {

    let url = `SyncMeasurerPatient/`

    return this.httpClient.post<PatientSyncMeasurerResponse>(environment.urlApiSyncMeasurer + url, patientSyncMeasurerRequest , this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }
}