<div class="main-body ubs">
    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-gender">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                        (click)="playAudio()" />
                    <span i18n>COMO VOCÊ DEFINE SUA IDENTIDADE DE GÊNERO?</span>
                    <img class="gender-info" src="assets/icons/gender-info.png" aria-hidden="false" aria-label="Informação"
                        (click)="openModalInfo()" />
                    <!-- <mat-icon class="icon-info" mat-icon aria-hidden="false" aria-label="Menu apps"
                        (click)="openModalInfo()">info</mat-icon> -->
                </div>
            </div>
        </div>
        <div>
            <div class="form-radio radio-ubs">
                <form class="form-gender-select" [formGroup]="model" (ngSubmit)="submit()">
                    <mat-radio-group aria-label="Select an option" formControlName="gender">
                        <mat-radio-button [value]="maleCisEnum">
                            <span i18n class="radio-label">Homem cisgênero</span></mat-radio-button>
                        <mat-radio-button [value]="maleTransEnum">
                            <span i18n class="radio-label">Homem transgênero</span></mat-radio-button>
                        <mat-radio-button [value]="nonBinaryEnum">
                            <span i18n class="radio-label">Gênero não binário</span></mat-radio-button>
                        <mat-radio-button [value]="famaleCisEnum">
                            <span i18n class="radio-label">Mulher cisgênero</span></mat-radio-button>
                        <mat-radio-button [value]="femaleTransEnum">
                            <span i18n class="radio-label">Mulher transgênero</span></mat-radio-button>
                        <mat-radio-button [value]="travestiEnum">
                            <span i18n class="radio-label">Travesti</span></mat-radio-button>
                        <mat-radio-button class="no-gender-declaration" [value]="notInformedEnum">
                            <span i18n class="radio-label">Prefiro não declarar gênero</span></mat-radio-button>
                    </mat-radio-group>
                </form>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon class="span-icon">arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
        <button mat-flat-button (click)="submit()" class="block btn-go btn-ubs" [disabled]="this.model.invalid">
            <span i18n *ngIf="isLoading == false">AVANÇAR</span>
            <div>
                <mat-icon class="span-icon">arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>
