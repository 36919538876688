import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MeasureService } from 'src/app/shared/services/API/sync-measurer/measure.service';
import { SetActivePatientService } from 'src/app/shared/services/API/sync-measurer/set-active-patient.service';
import { MeasureRequest } from 'src/app/shared/services/requests/sync-measurer/measure.request';
import { MeasureStruct } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { UtilsWelcomeService } from '../../../shared/services/session/utils-welcome.service';

@Component({
  selector: 'app-measure-history',
  templateUrl: './measure-history.component.html',
  styleUrls: ['./measure-history.component.css']
})
export class MeasureHistoryComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilsWelcomeService: UtilsWelcomeService,
    private measureService: MeasureService,
    private alertService: AlertService,
    private setActivePatientService: SetActivePatientService,
  ) { }
  
  public hash: string;
  public idPatient: number;
  public listSyncMeasureStruct: MeasureStruct[] = [];
  public listNotSyncMeasureStruct: MeasureStruct[] = [];
  private intervalInteretTest;
  private syncInterval;
  public isInternetAccess: boolean;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('hash'))
      this.hash = this.activatedRoute.snapshot.paramMap.get('hash');

    if (this.activatedRoute.snapshot.paramMap.get('idPatient'))
      this.idPatient = parseInt(this.activatedRoute.snapshot.paramMap.get('idPatient'));

    if(this.utilsWelcomeService.getMeasureList()){
      this.listSyncMeasureStruct = this.utilsWelcomeService.getMeasureList().filter(x => x.isServerSync);
      this.listNotSyncMeasureStruct = this.utilsWelcomeService.getMeasureList().filter(x => !x.isServerSync);
    } 

    if(this.listNotSyncMeasureStruct){
      this.listNotSyncMeasureStruct.sort((a,b) =>{
        return new Date(b.measureTime).getTime() - new Date(a.measureTime).getTime();
      });
    }
    if(this.listSyncMeasureStruct){
      this.listSyncMeasureStruct.sort((a,b)=>{
        return new Date(b.measureTime).getTime() - new Date(a.measureTime).getTime();
      });
    } 

    this.testInternet();
    this.updateListMeasures();
  }

  ngOnDestroy(): void{
    clearInterval(this.syncInterval);
    clearInterval(this.intervalInteretTest);
  }

  updateListMeasures(){
    let syncMeasurement = () =>{
    
      //Enviar medidas para api se isInternetAccess = true;
      if(this.isInternetAccess && this.listNotSyncMeasureStruct.length >0){
        this.sendMeasurements();
      }
    }
    
    this.syncInterval = setInterval(syncMeasurement, 3000)
  }

  sendMeasurements(): void{
    let listMeasureStruct = [];
    let measureRequest: MeasureRequest = new MeasureRequest();
    measureRequest.listMeasureStruct = this.listNotSyncMeasureStruct;
    measureRequest.syncMeasurerHash = this.hash;
    measureRequest.idPatient = this.utilsWelcomeService.getPatient().idPatient;

    this.measureService.sendMeasurements(measureRequest).subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if(this.utilsWelcomeService.getMeasureList()){
        listMeasureStruct = this.utilsWelcomeService.getMeasureList();
      } 

      listMeasureStruct.forEach((measurement)=>{
        if(!measurement.isServerSync){
          measurement.isServerSync = true;
        }
      })

      this.utilsWelcomeService.storeMeasureList(listMeasureStruct);


      if(this.utilsWelcomeService.getMeasureList()){
        this.listSyncMeasureStruct = this.utilsWelcomeService.getMeasureList().filter(x => x.isServerSync);
        this.listNotSyncMeasureStruct = this.utilsWelcomeService.getMeasureList().filter(x => !x.isServerSync);

        if(this.listNotSyncMeasureStruct){
          this.listNotSyncMeasureStruct.sort((a,b) =>{
            return new Date(b.measureTime).getTime() - new Date(a.measureTime).getTime();
          });
        }
        if(this.listSyncMeasureStruct){
          this.listSyncMeasureStruct.sort((a,b)=>{
            return new Date(b.measureTime).getTime() - new Date(a.measureTime).getTime();
          });
        } 
      } 
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  public testInternet():void{
    let testFunc = ()=>{
      console.log("testing internet");
      this.setActivePatientService.serviceActive().subscribe((response)=>{
        this.isInternetAccess = true;
        if(response.status != 200)
          this.isInternetAccess = false;
      },
      (error)=>{
        this.isInternetAccess = true;
        if(error.status != 200)
          this.isInternetAccess = false;
      });
    };

    this.intervalInteretTest = setInterval(testFunc, 5000);
  }
}
