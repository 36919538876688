import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RiskFactorsComponent } from './pages/risk-factors/risk-factors.component';
import { JourneyPageComponent } from './pages/journey/journey-page.component';
import { CpfRegisterComponent } from './pages/cpf-register/cpf-register.component';
import { MeasurementGuidelinesPaComponent } from './pages/measurement-guidelines-pa/measurement-guidelines-pa.component';
import { PreviewComponent } from './pages/preview.component';
import { WelcomePageComponent } from './pages/welcome/welcome-page.component';
import { BirthDateVerifierComponent } from './pages/birth-date-verifier/birth-date-verifier.component';
import { HeightRegisterComponent } from './pages/height-register/height-register.component';
import { GenderSelectComponent } from './pages/gender-select/gender-select.component';
import { DataConfirmComponent } from './pages/data-confirm/data-confirm.component';
import { ErrorUbsHealthPromotionComponent } from './pages/error-ubs-health-promotion/error-ubs-health-promotion.component';
import { MeasurementGuidelinesScaleComponent } from './pages/measurement-guidelines-scale/measurement-guidelines-scale.component';
import { ResultResumeComponent } from './pages/result-resume/result-resume.component';
import { SuccessMeasurementsComponent } from './pages/success-measurements/success-measurements.component';
import { CnsRegisterComponent } from './pages/cns-register/cns-register.component';
import { BirthDateRegisterIdentifierComponent } from './pages/birth-date-register-identifier/birth-date-register-identifier.component';
import { CompleteNameRegisterComponent } from './pages/complete-name-register/complete-name-register.component';
import { MotherCompleteNameRegisterComponent } from './pages/mother-complete-name-register/mother-complete-name-register.component';
import { MotherNameVerifierComponent } from './pages/mother-name-verifier/mother-name-verifier.component';
import { HouseholdIncomeComponent } from './pages/household-income/household-income.component';
import { LgpdComponent } from './pages/lgpd/lgpd.component';
import { EthnicityRegisterComponent } from './pages/ethnicity-register/ethnicity-register.component';
import { SharingMeasurementResultsComponent } from './pages/sharing-measurement-results/sharing-measurement-results.component';

const routes: Routes = [
  { path: 'ubs/journey', component: JourneyPageComponent },
  { path: 'ubs/success-measurements', component: SuccessMeasurementsComponent },
  { path: 'ubs/welcome', component: WelcomePageComponent },
  { path: 'ubs/lgpd', component: LgpdComponent },
  { path: 'ubs/cpf-register', component: CpfRegisterComponent },
  { path: 'ubs/cns-register', component: CnsRegisterComponent },
  { path: 'ubs/conplete-name-register', component: CompleteNameRegisterComponent },
  { path: 'ubs/birth-date-register-identifier', component: BirthDateRegisterIdentifierComponent },
  { path: 'ubs/mother-complete-name-register', component: MotherCompleteNameRegisterComponent },
  { path: 'ubs/household-income', component: HouseholdIncomeComponent },
  { path: 'ubs/data-confirm', component: DataConfirmComponent },
  { path: 'ubs/birth-date-verifier', component: BirthDateVerifierComponent },
  { path: 'ubs/mother-name-verifier', component: MotherNameVerifierComponent },
  { path: 'ubs/gender-select', component: GenderSelectComponent },
  { path: 'ubs/ethnicity-register', component: EthnicityRegisterComponent },
  { path: 'ubs/height-register', component: HeightRegisterComponent },
  { path: 'ubs/measurement-guidelines-pa', component: MeasurementGuidelinesPaComponent },
  { path: 'ubs/erro', component: ErrorUbsHealthPromotionComponent },
  { path: 'ubs/preview/:healthUnitToken', component: PreviewComponent },
  { path: 'ubs/journey-page', component: JourneyPageComponent},
  { path: 'ubs/sharing-measurement-results', component: SharingMeasurementResultsComponent},
  { path: 'ubs/measurement-guidelines-scale', component: MeasurementGuidelinesScaleComponent },
  { path: 'ubs/result-resume', component: ResultResumeComponent },
  { path: 'ubs/risk-factors', component: RiskFactorsComponent },
  { path: '**', redirectTo: 'ubs/welcome', pathMatch: 'full' },
  { path: 'ubs/welcome', component: WelcomePageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UBSHealthPromotionRoutingModule { }
