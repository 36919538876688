<div class="result-div"
    [ngClass]="{'result-green': (paCathegory == PaCathegoryEnum.noAdditionalRisk || paCathegory == PaCathegoryEnum.borderline), 'result-red': (paCathegory == PaCathegoryEnum.hypertension || riskFactorCount > 0) }">
    <app-result-measures [riskFactorCount]="riskFactorCount" [resultValue]="imcStringResult"
        [referenceValue]="imcReferenceValue" [imcCathegory]="imcCathegory" [colorCode]="imcColor" [isPaResult]="false"
        [isImcResult]="true" (emitCathegory)="populateImcCathegory($event)">
    </app-result-measures>
    <app-result-measures [riskFactorCount]="riskFactorCount" [resultValue]="paResult"
        [referenceValue]="paReferenceValue" [paCathegory]="paCathegory" [colorCode]="paColor" [isPaResult]="true"
        [isImcResult]="false" (emitCathegory)="populatePaCathegory($event)">
    </app-result-measures>
    <app-result-counseling [counselingTitle]="counselingTitle" [counseling]="counseling"
        [counselingDescription]="counselingDescription" [riskCategory]="riskFactorCategory">
    </app-result-counseling>
</div>