<div class="main-body ubs">

    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-ubs">

        <div class="row body-form">
            <div class="col-12 container-title">
                <div class="title">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
                    <span i18n>QUAL É O SEU NOME COMPLETO?</span>
                </div>
            </div>
            <div class="form-container">
                <form class="form" [formGroup]="model" (ngSubmit)="submit()">
                    <mat-form-field>
                        <input matInput type="text" formControlName="completeName"
                            placeholder="Ex: Rodrigo da Silva">
                        <mat-error i18n>Nome inválido</mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon *ngIf="!isLoading">arrow_back</mat-icon>
            </div>
            <span *ngIf="!isLoading" i18n>VOLTAR</span>
            <div class="loading">
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </div>
        </button>
        <button mat-flat-button (click)="submit()" [disabled]="!this.model.valid" class="block btn-go btn-ubs">
            <span *ngIf="!isLoading" i18n>AVANÇAR</span>
            <div>
                <mat-icon *ngIf="!isLoading">arrow_forward</mat-icon>
            </div>
            <div class="loading">
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </div>
        </button>
    </div>
</div>