<div class="container">
    <div class="container-body">
        <div class="title-container">
            <div class="page-title">
                <div class="audio-icon-img">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false"
                        aria-label="Menu apps" (click)="playSound('first')" />
                </div>
                <span i18n>SUA JORNADA LEVA MENOS DE 5 MINUTOS</span>
            </div>
            <div class="audio-information-box">
                <span class="audio-information-text" i18n>
                    Sempre que precisar, clique no ícone de som para ouvir
                    as perguntas.
                </span>
            </div>
        </div>
        <div class="row journey">
            <div class="journey-one">
                <p i18n>Responda <b>perguntas</b> sobre você. Não se
                    preocupe, é <b>rapidinho!</b></p>
            </div>
            <div class="journey-two">
                <p i18n>Depois você irá <b>medir sua pressão arterial</b>. Logo depois suba na 
                    balança e saberá seu peso.</p>
            </div>
            <div class="journey-three">
                <p i18n> Verifique seu <b>peso</b>.</p>
                <p i18n>Caso não saiba a sua <b>altura</b>, você deverá medir também.</p>
            </div>
            <div class="journey-four">
                <p i18n>
                    Ao final, você terá acesso ao <b>resultado</b>, com informações
                    importantes sobre sua saúde.
                </p>
            </div>
        </div>
            <button mat-flat-button class="block btn-go btn-ubs" (click)="clickGo()" i18n>
                <span>AVANÇAR</span>
                <div class="btn-icon">
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </button>
    </div>
</div>