<header>
    <img src="assets/images/logo-branco.png" />
    <span class="title-header-company">{{healthUnitName}}</span>
</header>

<div class="container-body d-flex align-items-center">  
    <div class="box-container row">
            <h1 class="col-12 d-flex justify-content-center">Obrigado por se cadastrar!</h1>
            <h2 class="col-12 d-flex justify-content-center">Não se esqueça de realizar as medições diariamente!</h2>
            <h3 class="col-12 d-flex justify-content-center">Vamos ajudar você a cuidar da saúde</h3>              
        <div class="col-12 offset-md-4 col-md-4">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="goToMeasurer()">
                <span>Realizar medições</span>
            </button>
        </div>
    </div>
</div>