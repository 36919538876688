import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UbsLgpdProtocolModalComponent } from 'src/app/shared/components/ubs-lgpd-protocol-modal/ubs-lgpd-protocol-modal.component';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';
import { UBSCreatePatientRequest } from 'src/app/shared/services/requests/orchestrator-integration/ubs-create-patient.request';
import { WebhookService } from 'src/app/shared/services/API/integration/webhook.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { WebhookTypeEnum } from 'src/app/shared/enum/webhook-type.enum';
import { WebhookStruct } from 'src/app/shared/services/structs/Integration/webhook-struct';
import { UBSWebhookService } from 'src/app/shared/services/API/orchestrator-integration/ubs-webhook.service';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-ethnicity-register',
  templateUrl: './ethnicity-register.component.html',
  styleUrls: ['./ethnicity-register.component.css']
})
export class EthnicityRegisterComponent implements OnInit, OnDestroy {

  private title: string = 'ethnicity-register';
  private patientData: PatientData = new PatientData();

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private webhookService: WebhookService,
    private router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    public uBSWebhookService: UBSWebhookService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public idHealthUnit: number;
  public webhook: WebhookStruct[];
  public ubsCreate: UBSCreatePatientRequest = new UBSCreatePatientRequest();
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.EthnicityRegister;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1)
      this.router.navigate(['/ubs/erro']);

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      ethnicity: ['', [Validators.required]],
    });

    if (this.utilsUBSService.getPatientData()) {
      this.patientData = this.utilsUBSService.getPatientData();

      if(this.patientData.ethnicity != null){
        if(this.patientData.ethnicity == 1){
          this.model.get('ethnicity').setValue('1');
        }
        if(this.patientData.ethnicity == 2){
          this.model.get('ethnicity').setValue('2');
        }
        if(this.patientData.ethnicity == 3){
          this.model.get('ethnicity').setValue('3');
        }
        if(this.patientData.ethnicity == 4){
          this.model.get('ethnicity').setValue('4');
        }
        
      }
    }

    this.isLoading = false;

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid)
      return;

    this.audio.pause();
    let ethnicity = this.model.get('ethnicity').value;
    this.patientData.ethnicity = ethnicity;
    this.utilsUBSService.createPatientData(this.patientData);

    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`])
  }

  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/ethnicity-register.mp3`;
    this.audio.load();
    this.audio.play();
  }

  goBack() {
    this.downtimeService.stopTimer();
    this.audio.pause();

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    this.router.navigate([lastPage]);
    // (this.patientData.existsInBaseData)
    //   ? this.router.navigate([lastPage])
    //   : this.router.navigate(['/ubs/erro']);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}