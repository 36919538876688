<header>
    <img src="assets/images/logo-branco.png" />
    <span class="title-header-company">{{healthUnitName}}</span>
</header>
<div class="container-body">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">           
        <div class="title-header">
            <div class="row">
                <div class="col-6 col-sm-6 col-md-6">
                    <h1>Dados Pessoais / Profissionais</h1>
                </div>
            </div>    
        </div>       
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="patientName">
                    <mat-error *ngIf="model.get('patientName').invalid">Informe o nome do paciente</mat-error>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-4 col-md-3">
                <mat-form-field appearance="outline"  class="outline-spinner">
                    <mat-label>CPF</mat-label>
                    <input matInput type="text" formControlName="cpf" [mask]="masks.cpf" (keyup)="onKeySearchCPF($event)">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingCpf == true"></mat-spinner>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput type="text" (keyup)="onKeySearchAge($event)" formControlName="birthDate" [mask]="masks.date">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <label *ngIf="patientAge != null">Idade:</label>
                <p>{{patientAge}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Gênero</mat-label>
                    <mat-select formControlName="idGender">
                        <mat-option *ngFor="let item of genderList" value="{{item.idGender}}">{{item.genderName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Telefone 1</mat-label>
                    <input matInput type="text" formControlName="phone1" [mask]="masks.phone">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline"  class="outline-spinner">
                    <mat-label>Filial/Unidade</mat-label>
                    <input matInput type="text" formControlName="unitBranch">
                    <mat-spinner [diameter]="30" *ngIf="isLoadingCpf == true" class="mat-spinner-btn"></mat-spinner>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline"  class="outline-spinner">
                    <mat-label>Cargo</mat-label>
                    <input matInput type="text" formControlName="roleEmployee">
                    <mat-spinner [diameter]="30" *ngIf="isLoadingCpf == true" class="mat-spinner-btn"></mat-spinner>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline"  class="outline-spinner">
                    <mat-label>Setor</mat-label>
                    <input matInput type="text" formControlName="sectorEmployee">
                    <mat-spinner [diameter]="30" *ngIf="isLoadingCpf == true"class="mat-spinner-btn"></mat-spinner>
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-3">
                <mat-form-field appearance="outline"  class="outline-spinner">
                    <mat-label>Matricula </mat-label>
                    <input matInput type="text" formControlName="registryEmployee">
                    <mat-spinner [diameter]="30" *ngIf="isLoadingCpf == true" class="mat-spinner-btn"></mat-spinner>
                </mat-form-field>
            </div>
        </div>
        <div class="title-header">
            <h1>Endereço</h1>
        </div>
        <div class="row">
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline" class="outline-spinner">
                    <mat-label>CEP</mat-label>
                    <input matInput type="text" formControlName="zipCode" (keyup)="onKeySearchCEP($event)">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingCep == true"></mat-spinner>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Logradouro</mat-label>
                    <input matInput type="text" formControlName="street">
                </mat-form-field>
            </div>
            <div  class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Número</mat-label>
                    <input matInput type="number" formControlName="houseNumber">
                </mat-form-field>
            </div>
            <div class="col-6 col-sm-3 col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Complemento</mat-label>
                    <input matInput type="text" formControlName="apartmentNumber">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Bairro</mat-label>
                    <input matInput type="text" formControlName="neighborhood">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Cidade</mat-label>
                    <input matInput type="text" formControlName="city">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>Estado</mat-label>
                    <input matInput type="text" formControlName="state">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <mat-form-field appearance="outline">
                    <mat-label>País</mat-label>
                    <input matInput type="text" formControlName="country">
                </mat-form-field>
            </div>
        </div> 
    
        <div class="row">
            <div class="offset-md-6 col-md-3">
                <button mat-flat-button type="button" class="btn-secundary btn-block" (click)="exit()">
                    <span>Sair</span>
                </button>
            </div>
            <div class="col-md-3">
                <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="submit()">
                    <span *ngIf="isLoading == false">Salvar e continuar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </div>     
    </form>
</div> 