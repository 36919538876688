<div mat-dialog-content class="content">

    <h3><b>Confirme seus dados antes de continuar!</b></h3>
    <div class="row">
        <div class="col-md-12">
            <p><label><b>Nome: </b></label><label> {{data.patient.patientName}}</label></p>
            <p><label><b>Data de Nascimento: </b></label><label> {{data.patient.birthDate | date: "dd/MM/yyyy" }}</label></p>
            <p><label><b>Nome da Mãe: </b></label><label> {{data.patient.motherName}}</label></p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button *ngIf="data.hasData" mat-flat-button type="button" (click)="clickConfirm()" color="primary" class=" btn-block">
                <span>Confirmar</span>
            </button>
            <button type="button" mat-flat-button class="btn-secundary btn-block" (click)="reviewMyData()">
                <span>Revisar Cadastro</span>
            </button>
            <button type="button" mat-flat-button class="btn-secundary btn-block" (click)="clickIsNotMe()">
                <span>Não sou eu</span>
            </button>
            <button type="button" mat-flat-button class="btn-secundary btn-block" (click)="close()">
                <span>Tentar Novamente</span>
            </button>
        </div>
    </div>
</div>