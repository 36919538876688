import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { PatientPatchRequest } from '../../requests/medical-record/patient-patch.request';
import { PatientDeviceRequest } from '../../requests/medical-record/patient-device.request';
import { ListPatientResponse } from '../../responses/medical-record/list-patient.response';
import { PatchPatientResponse } from '../../responses/medical-record/patch-patient.response';
import { PostPatientResponse } from '../../responses/medical-record/post-patient.response';
import { PatientDeviceResponse } from '../../responses/medical-record/patient-device.response';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends CommonService{

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public savePatient(body: PatientDeviceRequest): Observable<PatientDeviceResponse>{

    let uri = `Patient/PatientDevicePost`

    return this.httpClient.post<PatientDeviceResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }

  public updatePatientAndEmployee(body: PatientDeviceRequest): Observable<PatientDeviceResponse>{

    let uri = `Patient/PatientDeviceUpdate`

    return this.httpClient.put<PatientDeviceResponse>(environment.urlApiMedicalRecord + uri, body, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getPatient(ids: number[]): Observable<ListPatientResponse>{

    let uri = `Patient/jsonListIdPatient/${JSON.stringify(ids)}`

    return this.httpClient.get<ListPatientResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }
}
