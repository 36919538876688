<div class="mat-dialog-content-info">
    <div class="content">
        <div class="button-container">
            <img class="audio-icon-title" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="Menu apps"
                (click)="playAudio()" />
            <div class="close-button">
                <mat-icon mat-icon aria-hidden="false" aria-label="sound" (click)="closeModal()">close</mat-icon>
            </div>
        </div>
        <div class="details">
            <span>

                <h3 class="title">
                    <b i18n>TERMO DE CONSENTIMENTO LIVRE, INFORMADO E POLÍTICAS DE USO</b>
                </h3>

                <p class="lgpd" i18n>Este é um Termo de Uso e de Privacidade que o usuário do SUS deve conhecer para que
                    possa utilizar o Aplicativo do Cantinho Cuidando de Todos, disponibilizado pela Secretaria Municipal
                    de Saúde - SMS, que define as regras a serem observadas pelo usuário para sua utilização. A versão
                    válida do Termo é aquela publicada atualmente no Aplicativo, que poderá sofrer atualizações ao longo
                    do tempo nas próximas versões. Portanto, qualquer atualização realizada neste termo será apresentada
                    durante a autenticação para que o usuário tome conhecimento e realize o aceite novamente.</p>

                <p class="lgpd" i18n>Os serviços fornecidos por meio do aplicativo estão em conformidade com a
                    legislação brasileira, em especial, mas sem se limitar a Portaria GM/MS 3632/2020, que institui a
                    estratégia de Saúde Digital para o Brasil até 2028 e, a Lei Geral de Proteção de Dados (Lei nº
                    13.709).</p>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS nunca insere dados no Aplicativo, de modo que
                    todas as informações são incluídas no sistema pelo usuário.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>IMPORTANTE:</b></h3>
                <ul>
                    <li class="lgpd" i18n>Para utilizar o Aplicativo é necessária a aceitação expressa do usuário a este
                        termo;</li>

                    <li class="lgpd" i18n>A aceitação é confirmada quando o usuário clica no botão "continuar" e declara
                        ter ciência e concordar com todo o seu conteúdo de forma livre e informada;</li>

                    <li class="lgpd" i18n>Caso o usuário não concorde com qualquer disposição, não será possível
                        utilizar o Aplicativo. </li>
                </ul>
            </span>
            <span>
                <h3 class="title">
                    <b i18n>SERVIÇOS</b>
                </h3>

                <p class="lgpd" i18n>O Aplicativo é destinado ao uso do Usuário oferecendo (mas não se limitando), neste
                    momento como exemplo, os seguintes serviços:</p>
                <ul>
                    <li class="lgpd" i18n>Rastreamento de fatores de risco - O Rastreamento consiste em um conjunto de
                        procedimentos importantes para a saúde pública para detectar e intervir precocemente nos fatores
                        de risco (FR), em indivíduos assintomáticos ou com primeiros sinais e sintomas das doenças
                        crônicas não transmissíveis (DCNT), possibilitando o diagnóstico e tratamento precoces,
                        minimizando e evitando complicações.
                    </li>
                </ul>
            </span>
            <span>
                <h3 class="title">
                    <b i18n>ACESSO AO APLICATIVO</b>
                </h3>

                <p class="lgpd" i18n>Este Termo de Uso documenta a intenção do Usuário em utilizar as funcionalidades do
                    Aplicativo e a concordância expressa deste com este Termo, online ou por escrito, representa a
                    manifestação livre, expressa e informada da vontade do Usuário em contar com as facilidades
                    proporcionadas pelo Aplicativo, por meio do armazenamento e compartilhamento de seus dados pessoais
                    e médicos.</p>

                <p class="lgpd" i18n>O Usuário reconhece e aceita que a rede IP (Internet Protocol) constitui uma rede
                    pública de comunicações eletrônicas suscetível de utilização por vários usuários e, como tal, está
                    sujeita a sobrecargas e instabilidades, razão pela qual a Secretaria Municipal de Saúde - SMS não
                    garante o acesso ininterrupto e estável ao Aplicativo.</p>
            </span>
            <span>
                <h3 class="title">
                    <b i18n>REGRAS PARA UTILIZAÇÃO DO APLICATIVO
                    </b>
                </h3>

                <p class="lgpd" i18n>Ao aderir e utilizar o Aplicativo, o Usuário declara que todas as informações por
                    ele inseridas no Aplicativo são verdadeiras, assumindo total responsabilidade sobre tais
                    informações. Caso o Usuário disponibilize informação incompleta ou falsa, a Secretaria Municipal de
                    Saúde - SMS tem o direito de suspender ou cancelar o acesso ao Aplicativo e este poderá responder
                    cível e/ou criminalmente pelas ações inseridas.</p>

                <p class="lgpd" i18n>O Usuário reconhece que o Aplicativo contém informação confidencial e apresenta
                    conteúdo que está protegido por direitos de propriedade intelectual. O acesso ao aplicativo não
                    representa autorização de uso de quaisquer elementos protegidos por propriedade intelectual da
                    Secretaria Municipal de Saúde - SMS.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>POLÍTICA DE PRIVACIDADE</b></h3>
                <h3 class="title"><b i18n>1. CONSENTIMENTO</b></h3>

                <p class="lgpd" i18n>Com a Política de Privacidade, o Usuário manifesta seu consentimento livre e
                    informado em um formulário especifico (Termo de Consentimento Livre e Informado - TCLI) para que
                    sejam utilizados no sistema seus dados cadastrais (nome completo, número do Cadastro de Pessoa
                    Física [CPF], sexo, raça/cor, e número de sua Carteira Nacional de Saúde - Cartão SUS), bem como os
                    dados de saúde informados (exemplo: tipo sanguineo, alergias, pressão arterial, temperatura,
                    glicemia, peso, medicamentos, cuidados, entre outros).</p>

                <p class="lgpd" i18n>Consente, também, que a Secretaria Municipal de Saúde - SMS está plenamente
                    autorizada a receber, a qualquer tempo, as informações do Usuário para compor o presente Aplicativo
                    e o prontuário do paciente, as quais podem ser recebidas de profissionais de saúde, Unidades de
                    Saúde, órgãos e organizações integrantes do SUS, dentre outros. Ainda autoriza o recebimento de
                    notificações, avisos e alertas que poderão ser enviados com intuito informativo para o uso do
                    aplicativo.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>2. UTILIZAÇÃO E COMPARTILHAMENTO DE DADOS</b></h3>

                <p class="lgpd" i18n>As informações descritas poderão ser utilizadas: (1) para consulta e uso dos
                    profissionais de saúde que atuam em Unidades de Saúde ou Instituições de saúde a elas relacionadas,
                    vinculadas aos processos assistenciais relativos aos usuários; (2) de maneira anonimizada para
                    compilação, consulta, estatística, pesquisa, divulgação de campanhas públicas, intercâmbio de
                    informações com terceiros e com a própria Secretaria Municipal de Saúde - SMS e extração de
                    relatórios, com acesso restrito aos colaboradores autorizados da Secretaria Municipal de Saúde -
                    SMS. Poderão ser usados, ainda, de maneira anonimizada e consolidada (sem identificação pessoal), em
                    esforços de aprimoramento da Saúde Pública e pesquisa epidemiológica, como, por exemplo, para apoiar
                    o combate a doenças crônicas não transmissíveis, entre outras enfermidades.</p>

                <p class="lgpd" i18n>A utilização dos dados atenderá as questões normativas da Lei Geral de Proteção de
                    Dados (Lei nº 13.709) e preceitos éticos de utilização de prontuário médico e informações sensíveis
                    da saúde. Todo e qualquer acesso será devidamente identificado e registrado no sistema. Relatórios
                    estatísticos poderão ser compartilhados pela Secretaria Municipal de Saúde - SMS com terceiros,
                    desde que mantenham o anonimato dos Usuários. As informações exibidas no Aplicativo serão
                    confidenciais e sigilosas por prazo indeterminado, devendo ser utilizadas somente para as
                    finalidades previstas nestes Termos.</p>

                <p class="lgpd" i18n>O Usuário declara-se ciente que as suas informações não podem ser divulgadas a
                    terceiros, exceto mediante solicitação judicial.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>Finalidades do Tratamento dos Dados</b></h3>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS é a única CONTROLADORA dos dados não
                    existindo CO-CONTROLADORES para fins da Lei Geral de Proteção de Dados, sendo que as empresas
                    terceirizadas têm apenas o status de OPERADORAS dos dados dos usuários. O tratamento dos dados
                    pessoais listados neste documento tem as seguintes finalidades:</p>
                <ul>
                    <li class="lgpd" i18n>Auxiliar o usuário no acesso às suas informações de saúde no âmbito da
                        Secretaria Municipal de Saúde.</li>
                    <li class="lgpd" i18n>Auxiliar o profissional no acesso às informações de saúde do usuário quando de
                        um atendimento a este, no âmbito da Secretaria Municipal de Saúde e monitorar o usuário com
                        relação ao andamento de sua saúde ou mesmo controle de eventual doenças (crônicas ou não);</li>
                    <li class="lgpd" i18n>Possibilitar que a Secretaria Municipal de Saúde - SMS identifique e entre em
                        contato com o Titular para fins de relacionamento podendo informar que pode possuir risco para
                        doenças crônicas não transmissíveis ou outras doenças, infecciosas ou não, como forma de apoio a
                        saúde da população;</li>
                    <li class="lgpd" i18n>Possibilitar que a Secretaria Municipal de Saúde - SMS contate com usuários
                        que estiveram em contato com o titular do dado, em face de descoberta que ele identificou
                        possuir risco para
                        doenças crônicas não transmissíveis ou outras doenças, infecciosas ou não, como forma de apoio a
                        saúde da população</li>
                </ul>
            </span>
            <span>
                <h3 class="title"><b i18n>3. EXCLUSÃO E RETIFICAÇÃO DE DADOS</b></h3>

                <p class="lgpd" i18n>Caso o Usuário necessite corrigir algum dado ou opte pela exclusão de suas
                    informações do Aplicativo, deverá enviar uma solicitação à Secretaria Municipal de Saúde - SMS por
                    meio do e-mail: contato@esaudesp.prefeitura.sp.gov.br encaminhando com esta solicitação uma cópia de
                    um documento oficial com foto (ex: RG, CNH, etc.) dentro da validade e telefone para contato.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>LIMITAÇÕES DE RESPONSABILIDADE</b></h3>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS não é responsável pela curadoria dos dados
                    nele inseridos pelo Usuário, nos termos da legislação aplicável, sendo toda a informação inserida no
                    Aplicativo considerada como "conteúdo de terceiros". Os responsáveis pela correção, autenticidade e
                    curadoria dos dados inseridos no Aplicativo são de inteira responsabilidade dos operadores.</p>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS não é responsável pelos prejuízos ou danos
                    diretos ou indiretos decorrentes da má utilização do Aplicativo quando estes não lhe sejam
                    diretamente imputáveis a título de dolo ou culpa devidamente comprovados.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>SEGURANÇA DOS DADOS</b></h3>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS responsabiliza-se pela manutenção de medidas
                    de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não
                    autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
                    qualquer forma de tratamento inadequado ou ilícito.</p>

                <p class="lgpd" i18n>Em conformidade ao art. 48 da Lei nº 13.709, a Secretaria Municipal de Saúde - SMS
                    comunicará ao usuário e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente
                    de segurança que possa acarretar risco ou dano relevante ao Titular.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>4. SUSPENSÕES E CANCELAMENTO DO ACESSO</b></h3>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS pode a qualquer momento, descontinuar o
                    funcionamento do Aplicativo, ou restringir temporariamente o acesso ao Sistema - no seu todo ou
                    apenas em parte, para realização de ações de manutenção, reparação ou introdução de funcionalidades,
                    ou quando o Usuário não observar as condições de utilização descritas nos presentes Termos. Também
                    poderá suspender o uso de determinado usuário que, comprovadamente, tenha utilizado o aplicativo de
                    forma abusiva ou mesmo fraudulenta;</p>

                <p class="lgpd" i18n>A Secretaria Municipal de Saúde - SMS pode, a qualquer momento, suspender ou
                    cancelar o registro de um Usuário do Aplicativo, por prevenção de fraudes ou mesmo suspeita de
                    invasão;</p>

                <p class="lgpd" i18n>A suspensão ou a cessação de acesso ao Aplicativo pela Secretaria Municipal de
                    Saúde - SMS não conferem ao Usuário ou a terceiros o direito a qualquer indenização, não podendo a
                    Secretaria Municipal de Saúde - SMS ser responsabilizada por qualquer consequência da suspensão ou
                    cancelamento do acesso ao Aplicativo.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>DISPOSIÇÕES GERAIS</b></h3>

                <p class="lgpd" i18n>A adesão aos Termos e ao Aplicativo não estabelece vínculo de qualquer natureza
                    diversa do quanto aqui estabelecido entre o Usuário e a Secretaria Municipal de Saúde - SMS, como
                    por exemplo, de prestação de serviços médico-ambulatoriais ou autorização de procedimentos.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>LEGISLAÇÃO E FORO</b></h3>

                <p class="lgpd" i18n>A presente relação jurídica é regida exclusivamente pelas leis brasileiras,
                    inclusive eventuais ações decorrentes de violação dos seus termos e condições.</p>

                <p class="lgpd" i18n>Fica eleito o foro do domicílio do Usuário como o único competente para dirimir
                    quaisquer controvérsias decorrentes deste Termo, independentemente de qualquer outro, por mais
                    privilegiado que seja ou venha a ser.</p>
            </span>
            <span>
                <h3 class="title"><b i18n>Referências:</b></h3>

                <p class="lgpd" i18n>Brasil. <b>Lei nº 13.709, de 14 de agosto de 2018.</b> Lei Geral de Proteção de
                    Dados Pessoais (LGPD). Diário Oficial da União, Brasília, DF.</p>

                <p class="lgpd" i18n>Brasil. Ministério da Saúde. (2020). <b>Portaria GM/MS nº 3.632, de 21 de dezembro
                        de 2020.</b> Diário Oficial da União. Disponível em:
                        https://in.gov.br/web/dou/-/portaria-gm/ms-n-3.632-de-21-de-dezembro-de-2020-295516279#:~:text=PORTARIA%20GM%2FMS%20N%C2%BA%203.632%2C%20DE%2021%20DE%20DEZEMBRO,par%C3%A1grafo%20%C3%BAnico%20do%20art.%2087%20da%20Constitui%C3%A7%C3%A3o%2C%20resolve%3A.
                </p>
                <p class="lgpd" i18n>Controladoria Geral do Município. (2021). <b>Diretrizes e Boas Práticas de Proteção
                        de Dados e Privacidade da Controladoria Geral do Município.</b> Disponível em: DiretrizesparaoprogramadeprivacidadeeprotecaodedadosdaPrefeituraMunicipaldeSaoPaulo_publicacao15_02_2021.pdf.
                </p>
                <p class="lgpd" i18n>Prefeitura do Município de São Paulo. (2020). <b>Decreto nº 59.767 de 15 de setembro de 2020.</b> São Paulo, SP. Disponível em: https://legislacao.prefeitura.sp.gov.br/leis/decreto-59767-de-15-de-setembro-de-2020#:~:text=DECRETO%20N%C2%BA%2059.767%2C%20DE%2015%20DE%20SETEMBRO%20DE,no%20%C3%A2mbito%20da%20Administra%C3%A7%C3%A3o%20Municipal%20direta%20e%20indireta.
                </p>
            </span>
        </div>
    </div>
</div>
<div class="footer-confirm-modal-list">
    <button mat-flat-button class="button-continue btn-ubs" (click)="continue()">
        <span i18n>CONTINUAR</span>
        <div class="btn-icon">
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </button>
</div>