import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyCPF(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        let isValid: boolean = true;

        let error = { invalidCPF: {value: control.value} };

        let cpfString = value.replace(/[^0-9]+/g, '');

        if (cpfString.length != 11){
            return error;
        }

        var Soma;
        var Resto;

        Soma = 0;
        if (cpfString == "00000000000" ||
            cpfString == "11111111111" ||
            cpfString == "22222222222" ||
            cpfString == "33333333333" ||
            cpfString == "44444444444" ||
            cpfString == "55555555555" ||
            cpfString == "66666666666" ||
            cpfString == "77777777777" ||
            cpfString == "88888888888" ||
            cpfString == "99999999999") {
            isValid = false;
        }

        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (11 - i)
        };

        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0
        };

        if (Resto != parseInt(cpfString.substring(9, 10))) {
            isValid = false;
        };

        Soma = 0;

        for (let i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (12 - i)
        };
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) {
            Resto = 0;
        }

        if (Resto != parseInt(cpfString.substring(10, 11))) {
            isValid = false;
        }

        return !isValid ?  error : null
    };

}

