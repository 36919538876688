import { Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { VerifyCPF } from 'src/app/shared/custom-validators/cpf.validator';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { WebhookCpfService } from 'src/app/shared/services/API/orchestrator-integration/webhook-cpf.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { WebhookStruct } from 'src/app/shared/services/structs/Integration/webhook-struct';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';
import { CpfErrorModalComponent } from './cpf-error-modal/cpf-error-modal.component';
import { UBSSearchPatientRequest } from 'src/app/shared/services/requests/orchestrator-integration/ubs-search-patient.request';
import { UBSSearchResponse } from 'src/app/shared/services/responses/orchestrator-integration/ubs-search.response';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';
import { UBSDataSectionRequest } from 'src/app/shared/services/requests/ubs-health-promotion/ubs-data-section.request';
import { UBSDataSectionService } from 'src/app/shared/services/API/ubs-health-promotion/ubs-data-section.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patiet-by-cpf.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { UbsWebhookService } from 'src/app/shared/services/API/orchestrator-patient/ubs-webhook.service';
import { UBSWebhookPatientStruct } from 'src/app/shared/services/structs/orchestrator-patient/ubs-webhook-patient.struct';

@Component({
  selector: 'app-cpf-register',
  templateUrl: './cpf-register.component.html',
  styleUrls: ['./cpf-register.component.css']
})
export class CpfRegisterComponent implements OnInit, OnDestroy {

  constructor(
    private ubsDataSectionService: UBSDataSectionService,
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private webhookService: WebhookCpfService,
    private utilService: UtilsUBSService,
    private downtimeService: DowntimeService,
    private ubsWebhookService: UbsWebhookService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    private getPatient: GetPatientByCpfService
  ) { }

  private patientData: PatientData = new PatientData();
  public lgpd: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public audio = new Audio();
  public journeyStageId: number = JourneyStageEnum.Identificacao;
  public idUbsScreen: number = UbsScreenEnum.CpfRegister;
  public uBSConfigData: UBSConfigData;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilService.getUBSData();

    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      cpf: ['', [
        Validators.required,
        VerifyCPF(),
      ]],
    });
    this.isLoading = false;

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid || this.isLoading) {
      return;
    }

    this.audio.pause();
    this.isLoading = true;

    let cpf = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    this.patientData.cpf = cpf;

    let request = new UBSSearchPatientRequest();
    request.cpf = cpf;
    request.birthDate = null;
    request.url = '';
    request.token = '';
    request.guid = '';
    
    this.callSearchPatient(request);
  }

  openErrorModal(noCPF: boolean) {
    const dialogRef = this.dialog.open(CpfErrorModalComponent, {
      disableClose: true,
      data: {
        noCPF: noCPF,
      },
      panelClass: 'modal-cpf-error'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.downtimeService.stopTimer();
      this.utilService.removeListVisitidePages();
      this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
    });
  }

  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/requisicao_cpf.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();

    let visitedPages = this.utilService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  goCNS() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate([`ubs/cns-register`]);
  }

  callSearchPatient(request: UBSSearchPatientRequest){  
    this.isLoading = true;
    this.patientData.existsInBaseData = true;
    let healthUnit = this.utilService.getHealthUnit();
    request.isFullTermPatientSearch = false;

    let webhookStruct: WebhookStruct = this.utilService.getWebhook();    
    if (webhookStruct){
      request.url = webhookStruct.url;
      request.token = webhookStruct.token;
      request.guid = webhookStruct.guid;
    }

    let jsonListIdHealthUnit = null;
    if(healthUnit.listIdHealthUnitInGroup)
      jsonListIdHealthUnit = JSON.stringify(healthUnit.listIdHealthUnitInGroup);
    
    this.ubsWebhookService.uBSSearchPatient(request.cpf, healthUnit.idHealthUnit, false, null, jsonListIdHealthUnit, request).subscribe({
      next: (response) => {
        if ((response.isError && response.errorCode == 1 && webhookStruct && !response.patientMedicalRecordStruct) || 
            (!response.isError && !response.patientMedicalRecordStruct && !response.uBSWebhookPatientStruct)) {
              this.patientData.existsInBaseData = false;
              this.patientData.searchByCPF = true;
              this.utilService.createPatientData(this.patientData);
              this.goCompleteName();
              this.isLoading = false;
              return;
        }
        else if (response.isError) {
          this.isLoading = false;
          this.downtimeService.stopTimer();
          this.router.navigate(['/ubs/erro']);
          return;
        }
        else{
          if (response.patientMedicalRecordStruct){
            this.mapToPatientMedicalRecordData(response.patientMedicalRecordStruct);
            this.getAditionalPatientData(response.patientMedicalRecordStruct.idPatient);
          }
          else {
            this.mapToPatientData(response.uBSWebhookPatientStruct);
            this.getAditionalPatientData(response.uBSWebhookPatientStruct.idPatient);
          } 

          this.utilService.createPatientData(this.patientData);
          this.downtimeService.stopTimer();
          this.saveLastPage();
          this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);          
          this.isLoading = false;
          return;
        }        
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.downtimeService.stopTimer();
        this.router.navigate(['/ubs/erro']);
      }
    });
  }

  getAditionalPatientData(idPatient: number) {
    this.ubsDataSectionService.getUBSDataSection(idPatient).subscribe((response) => {
      if (response.ubsDataSection) {
        this.patientData.idGender = response.ubsDataSection.idUbsGender;
        this.patientData.householdIncome = response.ubsDataSection.householdIncome;
        this.patientData.ethnicity = response.ubsDataSection.idEthnicityGroup;
      }
      this.utilService.createPatientData(this.patientData);
    },
      (error) => {
        console.log(error);
      })
  }


  goCompleteName() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.router.navigate([`ubs/conplete-name-register`]);
  }

  mapToPatientData(data: UBSWebhookPatientStruct) {
    this.patientData.idPatient = data.idPatient;
    this.patientData.cpf = data.cpf;
    this.patientData.cns = data.cns;
    this.patientData.name = data.name;
    this.patientData.birthDate = data.birthDate;
    this.patientData.motherName = data.motherName;
  }

  mapToPatientMedicalRecordData(data: PatientStruct) {
    this.patientData.idPatient = data.idPatient;
    this.patientData.cpf = data.cpf;
    this.patientData.cns = data.cns;
    this.patientData.name = data.patientName;
    this.patientData.birthDate = data.birthDate;
    this.patientData.motherName = data.motherName;
  }

  saveLastPage(){
    let visitedPages = this.utilService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilService.updateListVisitidePages(visitedPages);
  }
}