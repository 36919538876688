import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-ubs-error-outdated-data-modal',
  templateUrl: './ubs-error-outdated-data-modal.component.html',
  styleUrls: ['./ubs-error-outdated-data-modal.component.css']
})
export class UbsErrorOutdatedDataModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) public locale: string,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UbsErrorOutdatedDataModalComponent>,
    private alertService: AlertService,
    public router: Router
  ) { }
  
  public audio = new Audio();
  public tryAgain: boolean = true;

  ngOnInit(): void {
    this.tryAgain = this.data.tryAgain;
  }

  finishSession(){
    this.audio.pause();
    this.matDialogRef.close('finish');
  }

  closeModal() {
    this.audio.pause();
    this.matDialogRef.close();
  }

  playAudio(){
    if(this.tryAgain)
      this.audio.src = `/assets/audio/${this.locale}/error-outdated-data2.mp3`
    else 
      this.audio.src = `/assets/audio/${this.locale}/error-outdated-data.mp3`
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }
}
