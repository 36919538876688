<div>
    <div class="conteiner-red-box">
        <div class="error-img">
            <img src="assets/images/error-round.png" />
        </div>
        <div class="title-error-component">
            <span i18n> <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="Menu apps" (click)="playAudio()"/>
                Ocorreu um erro!
            </span>
        </div>
        <div class="error-message" i18n>
            Por favor, solicite o suporte da recepção da UBS.
        </div>
    </div>
</div>