import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { ListPatientComponent } from './pages/list-patient/list-patient.component';
import { MeasureHistoryComponent } from './pages/measure-history/measure-history.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

const routes: Routes = [
  { path: '', component: ListPatientComponent, pathMatch: 'full'},
  { path: 'tolifecloud-ui-device', component: ListPatientComponent},
  { path: 'patient-measurement', component: WelcomeComponent},
  { path: 'welcome/list-patient', component: ListPatientComponent},
  { path: 'welcome/measure-history', component: MeasureHistoryComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WelcomeRoutingModule { }
