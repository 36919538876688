import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { RiskFactorsData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-risk-factors',
  templateUrl: './risk-factors.component.html',
  styleUrls: ['./risk-factors.component.css']
})
export class RiskFactorsComponent implements OnInit, OnDestroy {

  private title: string = 'risk-factors';

  constructor(private utilsUBSService: UtilsUBSService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    private downtimeService: DowntimeService,
    private titleService: Title,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public isLoading: boolean = false;
  public riskFactorsData: RiskFactorsData = new RiskFactorsData();
  public journeyStageId: number;
  public uBSConfigData: UBSConfigData;
  public idUbsScreen: number;
  public pageIndex: number;
  //Pressão alta
  public haveHighPressure: boolean = false;
  public clickHighPressure: boolean = false;
  public havePressureRemedy: boolean = false;
  public clickPressureRemedy: boolean = false;
  //Diabetes
  public haveDiabetes: boolean = false;
  public clickDiabetes: boolean = false;
  public haveTreatedDiabetes: boolean = false;
  public clickTreatedDiabetes: boolean = false;
  //Colesterol Alto
  public haveHighCholesterol: boolean = false;
  public clickHighCholesterol: boolean = false;
  public haveTakeMedicineCholesterol: boolean = false;
  public clickTakeMedicineCholesterol: boolean = false;
  //Sobrepeso/Obesidade
  public haveOverweightObesity: boolean = false;
  public clickOverweightObesity: boolean = false;
  public haveTrackWeighWithProfessional: boolean = false;
  public clickTrackWeighWithProfessional: boolean = false;

  public audio = new Audio();
  public downtime: boolean;

  ngOnInit(): void {
    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.RiskFactors;

    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (this.uBSConfigData && this.uBSConfigData != null && this.uBSConfigData.listUBSStruct.length > 0)
      this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  changeHighPressure() {
    if (!this.clickHighPressure) {
      this.riskFactorsData.haveHighPressure = true;
      this.clickHighPressure = true;
    }
    else {
      this.riskFactorsData.haveHighPressure = false;
      this.clickHighPressure = false;
      this.riskFactorsData.havePressureRemedy = false;
      this.clickPressureRemedy = false;
    }
  }

  changePressureRemedy() {
    if (!this.clickPressureRemedy) {
      this.riskFactorsData.havePressureRemedy = true;
      this.clickPressureRemedy = true;
    }
    else {
      this.riskFactorsData.havePressureRemedy = false;
      this.clickPressureRemedy = false;
    }
  }

  changeDiabetes() {
    if (!this.clickDiabetes) {
      this.riskFactorsData.haveDiabetes = true;
      this.clickDiabetes = true;
    }
    else {
      this.riskFactorsData.haveDiabetes = false;
      this.clickDiabetes = false;
      this.riskFactorsData.haveTreatedDiabetes = false;
      this.clickTreatedDiabetes = false;
    }
  }

  changeTreatedDiabetes() {
    if (!this.clickTreatedDiabetes) {
      this.riskFactorsData.haveTreatedDiabetes = true;
      this.clickTreatedDiabetes = true;
    }
    else {
      this.riskFactorsData.haveTreatedDiabetes = false;
      this.clickTreatedDiabetes = false;
    }
  }

  changeHighCholesterol() {
    if (!this.clickHighCholesterol) {
      this.riskFactorsData.haveHighCholesterol = true;
      this.clickHighCholesterol = true;
    }
    else {
      this.riskFactorsData.haveHighCholesterol = false;
      this.clickHighCholesterol = false;
      this.riskFactorsData.haveTakeMedicineCholesterol = false;
      this.clickTakeMedicineCholesterol = false;
    }
  }

  changeTakeMedicineCholesterol() {
    if (!this.clickTakeMedicineCholesterol) {
      this.riskFactorsData.haveTakeMedicineCholesterol = true;
      this.clickTakeMedicineCholesterol = true;
    }
    else {
      this.riskFactorsData.haveTakeMedicineCholesterol = false;
      this.clickTakeMedicineCholesterol = false;
    }
  }

  changeOverweightObesity() {
    if (!this.clickOverweightObesity) {
      this.riskFactorsData.haveOverweightObesity = true;
      this.clickOverweightObesity = true;
    }
    else {
      this.riskFactorsData.haveOverweightObesity = false;
      this.clickOverweightObesity = false;
      this.riskFactorsData.haveTrackWeighWithProfessional = false;
      this.clickTrackWeighWithProfessional = false;
    }
  }

  changeTrackWeighWithProfessional() {
    if (!this.clickTrackWeighWithProfessional) {
      this.riskFactorsData.haveTrackWeighWithProfessional = true;
      this.clickTrackWeighWithProfessional = true;
    }
    else {
      this.riskFactorsData.haveTrackWeighWithProfessional = false;
      this.clickTrackWeighWithProfessional = false;
    }
  }

  playSound() {
    this.audio.src = `/assets/audio/${environment.locale}/risk-factor.mp3`

    this.audio.load();
    this.audio.play();
  }

  clickBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  clickGo() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.utilsUBSService.createRiskFactorsData(this.riskFactorsData);
    this.saveLastPage();
    this.router.navigate(["ubs" + this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}