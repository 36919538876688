import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { SyncMeasurerHashResponse } from '../../responses/sync-measurer/sync-measurer-hash.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';


@Injectable({
  providedIn: 'root'
})
export class SetActivePatientService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public setActivePatient(idPatient: number, hash: string): Observable<ReturnStruct> {

    let url = `SetActivePatient/idPatient/${idPatient}/hash/${hash}`

    return this.httpClient.get<ReturnStruct>(environment.urlApiSyncMeasurer + url)
    .pipe(
      catchError(this.handleError)
    )
  }

  public serviceActive(): Observable<any>{
    return this.httpClient.get<string>(environment.urlApiSyncMeasurer, { observe: 'response' })
    .pipe(
     // retry(100)
      //catchError(this.handleError)
    )
  }
}
