import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function VerifyCNS(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        
        let error = { invalidCNS: {value: control.value} }
        
        let cnsString = value.replace(/[^0-9]+/g, '')
        
        var validateSize = (cnsString.length == 15)
        var validateStart = ['1', '2', '7', '8', '9'].includes(cnsString[0])
        
        if(validateSize && validateStart){
            //CNS Iniciados em 7, 8 ou 9
            if(['7', '8', '9'].includes(cnsString[0])) {
                var sum = cnsString.split('').reduce((total, value, index) => total + (value * (15 - index)), 0);
                return (sum % 11 == 0)? null : error
                
            } else if(['1', '2'].includes(cnsString[0])){
                //CNS Iniciados em 1, 2
                var pis = cnsString.substring(0,11);
                var sum = pis.split('').reduce((total, value, index) => total + (value * (15 - index)), 0)
                
                var rest = sum % 11
                var dv = rest == 0 ? 0 : 11 - rest
                
                var result = dv == 10 ? `${pis}001${(11 - ((sum + 2) % 11))}` : `${pis}000${dv}`
                return (result == cnsString)? null : error
            } else{
                return error
            }      
        }else{
            return error
        }      
    }
}



/*
Função para validar número do CNS - Cartão Nacional de Saúde ou Cartão do SUS

:param cns: Número de CNS que será validado
:type cns: String (Caso não seja, será transformado)
:rtype: Boolean
*/

