import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ImcCathegoryEnum } from 'src/app/shared/enum/imc-cathegory.enum';
import { PaCathegoryEnum } from 'src/app/shared/enum/pa-cathegory.enum';
import { RiskCategoryEnum } from 'src/app/shared/enum/risk-category.enum';
import { AlertType } from 'src/app/shared/services/alert.service';
import { PostUbsHealthPromotionSmsEmailRequest } from 'src/app/shared/services/requests/srv-email/post-ubs-health-promotion-sms-email.request';
import { PatientData, RiskFactorsData, UtilsUBSService } from 'src/app/ubs-health-promotion/utils-ubs.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  @Input() patientData: PatientData;
  @Input() riskFactorsData: RiskFactorsData;
  @Output() emitPatientData = new EventEmitter<PostUbsHealthPromotionSmsEmailRequest>();
  @Output() emitAudioSrc = new EventEmitter<string>();

  constructor(private router: Router,
    private utilsUbsService: UtilsUBSService) { }

  public imcResult: number;
  public imcStringResult: string;
  public imcColor: string;
  public imcCathegory: number;
  public imcCathegoryDescription: string;
  public imcReferenceValue: string;

  public audioSrc: string;
  public paCathegory: number;
  public paCathegoryDescription: string;
  public paColor: string;
  public paResult: string;
  public paReferenceValue: string;

  public paNormal: string = '#65B4BA';
  public paYellow: string = '#F6CD48';
  public paDanger: string = '#DA6960';

  public counselingDescription: string;
  public counselingTitle: string;
  public counseling: string;

  public PaCathegoryEnum = PaCathegoryEnum;
  public patientRequest: PostUbsHealthPromotionSmsEmailRequest;

  public riskFactorCount: number = 0;
  public riskFactorCategory: number;

  async ngOnInit() {
    this.patientData = new PatientData();
    this.patientData = this.utilsUbsService.getPatientData();

    await this.calculateIMC();
    await this.getPaCathegory();
  }

  async calculateIMC() {
    let weight = this.patientData.weight;
    let height = (this.patientData.height / 100);

    let heightSquare = height * height;

    this.imcResult = (weight / heightSquare);
    this.imcStringResult = `${(Math.round(this.imcResult * 10) / 10).toFixed(1)} kg/m²`;

    if (this.imcResult <= 18.4) {
      this.imcColor = "#65B4BA";
      this.imcReferenceValue = 'IMC até 18,4kg/m²';
      this.imcCathegory = ImcCathegoryEnum.underweight;
    }
    else if (this.imcResult >= 18.5 && this.imcResult < 25) {
      this.imcColor = "#65B4BA";
      this.imcReferenceValue = 'IMC entre 18,5 e 24,9kg/m²';
      this.imcCathegory = ImcCathegoryEnum.normalWeight;
    }
    else if (this.imcResult >= 25.0 && this.imcResult < 30) {
      this.imcColor = "#F6CD48";
      this.imcReferenceValue = 'IMC entre 25,0 e 29,9kg/m²';
      this.imcCathegory = ImcCathegoryEnum.overweight;
    }
    else {
      this.imcColor = "#DA6960";
      this.imcReferenceValue = 'IMC acima de 30,0kg/m²';
      this.imcCathegory = ImcCathegoryEnum.obesity;
    }
  }

  async getPaCathegory() {
    let sistolicPressure = this.patientData.sistolicPressure;
    let diastolicPressure = this.patientData.diastolicPressure;
    this.paResult = `${this.patientData.sistolicPressure}/${this.patientData.diastolicPressure} mmHg`;

    if (sistolicPressure >= 140 || diastolicPressure >= 90) {
      this.paCathegory = PaCathegoryEnum.hypertension;
      this.paReferenceValue = "PA entre 140/90mmHg até 159/99mmHg";
      if (sistolicPressure >= 160 || diastolicPressure >= 100) {
        this.paReferenceValue = "PA entre 160/100mmHg até 179/109mmHg";
      }
      if (sistolicPressure >= 180 || diastolicPressure >= 110) {
        this.paReferenceValue = "PA entre 180/110mmHg";
      }

    } else if (sistolicPressure >= 130 || diastolicPressure >= 85) {
      this.paCathegory = PaCathegoryEnum.borderline;
      this.paReferenceValue = "PA entre 130/85mmHg até 139/89mmHg";
    } else {
      this.paCathegory = PaCathegoryEnum.noAdditionalRisk;
      this.paReferenceValue = "PA até 129/84mmHg";
    }

    switch (this.paCathegory) {
      case PaCathegoryEnum.noAdditionalRisk:
        this.paColor = this.paNormal;
        this.audioSrc = "no-risk";
        this.counselingTitle = "Continue assim!";
        this.counseling = "Seus cuidados com a sua saúde estão bons!";
        this.counselingDescription = "Acompanhe a medida da pressão arterial pelo menos 1 vez ao ano e <b>mantenha suas consultas em dia.</b>";
        break;
      case PaCathegoryEnum.borderline:
        this.paColor = this.paYellow;
        this.audioSrc = "no-risk";
        this.counselingTitle = "Continue assim!";
        this.counseling = "Seus cuidados com a sua saúde estão bons!";
        this.counselingDescription = "Acompanhe a medida da pressão arterial pelo menos 1 vez ao ano e <b>mantenha suas consultas em dia.</b>";
        break;
      case PaCathegoryEnum.hypertension:
        this.paColor = this.paDanger;
        this.audioSrc = "has-risk";
        this.counselingTitle = "Atenção!";
        this.counseling = "Não deixe sua saúde para depois!";
        this.counselingDescription = "<b>Priorize o seu atendimento na UBS.</b> Mantenha os cuidados e siga as orientações da equipe de saúde.";
        break;
    }

    if (this.riskFactorsData) {
      if (this.riskFactorsData.haveDiabetes) this.riskFactorCount++;
      if (this.riskFactorsData.haveHighCholesterol) this.riskFactorCount++;
      if (this.riskFactorsData.haveHighPressure) this.riskFactorCount++;
      if (this.riskFactorsData.havePressureRemedy) this.riskFactorCount++;
      if (this.riskFactorCount > 0 && this.paCathegory == PaCathegoryEnum.borderline) this.audioSrc = "has-risk";
    }

    if (this.riskFactorCount > 0 || this.paCathegory == PaCathegoryEnum.hypertension) {
      this.counselingTitle = "Atenção!";
      this.counseling = "Não deixe sua saúde para depois!";
      this.counselingDescription = "<b>Priorize o seu atendimento na UBS.</b> Mantenha os cuidados e siga as orientações da equipe de saúde.";
      this.riskFactorCategory = RiskCategoryEnum.highRisk;
    }
    else {
      this.riskFactorCategory = RiskCategoryEnum.noRisk;
    }

    this.emitAudioSrc.emit(this.audioSrc);
  }

  async verifyRiskFactor(paSis?: number, paDias?: number): Promise<number> {

    let paCathegory = 0;

    let faCount = 0;
    if (this.riskFactorsData) {
      if (this.riskFactorsData.haveDiabetes) faCount++;
      if (this.riskFactorsData.haveHighCholesterol) faCount++;
      if (this.riskFactorsData.haveHighPressure) faCount++;
      if (this.riskFactorsData.havePressureRemedy) faCount++;
    }

    if (paSis) {
      if (faCount == 0) {
        if (paSis < 140)
          paCathegory = PaCathegoryEnum.noAdditionalRisk;
        else
          paCathegory = PaCathegoryEnum.hypertension;
      }
      else if (faCount > 0) {
        if (paSis < 130)
          paCathegory = PaCathegoryEnum.noAdditionalRisk;
        else
          paCathegory = PaCathegoryEnum.hypertension;
      }
    }
    else {
      if (faCount == 0) {
        if (paDias < 90)
          paCathegory = PaCathegoryEnum.noAdditionalRisk;
        else
          paCathegory = PaCathegoryEnum.hypertension;
      }
      else if (faCount > 0) {
        if (paDias < 85)
          paCathegory = PaCathegoryEnum.noAdditionalRisk;
        else
          paCathegory = PaCathegoryEnum.hypertension;
      }
    }

    return paCathegory;
  }


  async mapToStruct() {
    let request: PostUbsHealthPromotionSmsEmailRequest = new PostUbsHealthPromotionSmsEmailRequest();
    request.counselingTitle = this.counselingTitle;
    request.counseling = this.counseling;
    request.counselingDescription = this.counselingDescription;
    request.dateConsulting = new Date();
    request.degreeImc = this.imcCathegoryDescription;
    request.imcCathegory = this.imcCathegory;
    request.degreePressure = this.paCathegoryDescription;
    request.paCathegory = this.paCathegory;
    request.diastolicPressure = this.patientData.diastolicPressure;
    request.systolicPressure = this.patientData.sistolicPressure
    request.email = null;
    request.imc = this.imcResult;
    request.phone = null;
    request.referenceImc = this.imcReferenceValue;
    request.referencePressure = this.paReferenceValue;

    this.utilsUbsService.createSendEmailPatientRequest(request);

    this.emitPatientData.emit(request);
  }

  populateImcCathegory(event) {
    this.imcCathegoryDescription = event;
  }
  populatePaCathegory(event) {
    this.paCathegoryDescription = event;
  }
}
