<div class="container">
    <div class="container-body">
        <div class="row container-row">
            <div class="col-12">
                <div class="row container-title-audio">
                    <div class="col-1 audio-icon">
                        <img src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                            (click)="playSound('first')">
                    </div>
                    <div class="col-11">
                        <h1 i18n>Você está no Cantinho Cuidando de Todos!</h1>
                        <p i18n [ngStyle]="{'font-size':'36px', 'padding':'0px 0px 0px 0px'}">Aqui você pode cuidar da
                            <strong>sua saúde</strong>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <img class="img-welcome" src="assets/images/ilustracao-boas-vindas.png">
            <div class="info-card">
                <div class="row">
                    <div class="col-12">
                        <div class="card-title">
                            <span i18n>Como será sua experiência:</span>
                        </div>
                    </div>
                </div>
                <div class="row info-block">
                    <div class="info-img col-1">
                        <img src="assets/icons/clock.png">
                    </div>
                    <div class="col-11">
                        <p i18n>O processo é <b>rápido</b> e não tomará muito do seu tempo na UBS;</p>
                    </div>
                </div>
                <div class="row info-block">
                    <div class="info-img col-1">
                        <img src="assets/icons/shield-check.png">
                    </div>
                    <div class="col-11">
                        <p i18n>Seus <b>dados</b> estão <b>seguros</b>, só os usaremos para <b>calcular</b> seus resultados;</p>
                    </div>
                </div>
                <div class="row info-block">
                    <div class="info-img col-1">
                        <img src="assets/icons/chart-bar.png">
                    </div>
                    <div class="col-11">
                        <p i18n>Ao final, você receberá no seu e-mail <b>todos os resultados</b>.</p>
                    </div>
                </div>
                <div class="button-start-container">
                    <button mat-flat-button class="button-start btn-ubs" (click)="clickGo()">
                        <span i18n>INICIAR</span>
                        <div class="btn-icon">
                            <mat-icon aria-hidden="false">arrow_forward</mat-icon>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="img-container">
            <img src="assets/images/barra-de-logo.png">
        </div>
    </div>
</div>
<!-- <button mat-flat-button class="col-6 button-start" (click)="clickGo()" i18n>
    Clique para começar
</button> -->