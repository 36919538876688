<div class="container ubs">
    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-gender">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                    (click)="playAudio()" />
                    <span i18n>IDENTIFICAÇÃO ÉTNICO-RACIAL</span>
                </div>
            </div>
        </div>
        <div class="radio-ubs">
            <div class="form-radio">
                <form [formGroup]="model" (ngSubmit)="submit()">
                    <mat-radio-group aria-label="Select an option" formControlName="ethnicity">
                        <mat-radio-button value="1">
                            <span i18n class="radio-label">Amarela</span></mat-radio-button>
                        <mat-radio-button value="2">
                            <span i18n class="radio-label">Branca</span></mat-radio-button>
                        <mat-radio-button value="3">
                            <span i18n class="radio-label">Indígena</span></mat-radio-button>
                        <mat-radio-button value="4">
                            <span i18n class="radio-label">Parda</span></mat-radio-button>
                        <mat-radio-button value="5">
                            <span i18n class="radio-label">Preta</span></mat-radio-button>
                    </mat-radio-group>
                </form>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon class="span-icon">arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
        <button mat-flat-button (click)="submit()" class="block btn-go btn-ubs" [disabled]="this.model.invalid">
            <span i18n *ngIf="isLoading == false">AVANÇAR</span>
            <div>
                <mat-icon class="span-icon">arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>
