export enum UbsScreenEnum
{
    Welcome = 1,
    MeasurementGuidelinesPa = 2,
    BirthDateRegister = 3,
    GenderSelect = 4,
    HeightRegister = 5,    
    CpfRegister = 6,
    DataConfirm = 7,
    MeasurementGuidelinesScale = 8,
    SuccessMeasurements = 9,
    ResultResume = 10,
    RiskFactors = 11,
    Journey = 12,
    // HouseholdIncome = 14,
    Lgpd = 13,
    EthnicityRegister = 15
}