import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListPatientResponse } from '../../responses/medical-record/list-patient.response';


@Injectable({
  providedIn: 'root'
})
export class CallSyncMeasurerPatientService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public listPatientSyncMeasurer(syncMeasurerHash: string): Observable<ListPatientResponse> {

    let url = `CallSyncMeasurerPatient/syncMeasurerHash/${syncMeasurerHash}`
    
    return this.httpClient.get<ListPatientResponse>(environment.urlApiOrchestratorPatient + url)
    .pipe(
      //catchError(this.handleError)
    )
  }
}
