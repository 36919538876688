import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ResultResponse } from '../../responses/interface-measurer/list-patient.response';


@Injectable({
  providedIn: 'root'
})
export class GlucometerService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public read(brand: string): Observable<ResultResponse> {

    let url = `Glucometer/${brand}`;

    return this.httpClient.get<ResultResponse>(environment.urlApiSensors + url)
    .pipe(
      catchError(this.handleError)
    )
  }

  public start(brand: string): Observable<ReturnStruct> {

    let url = `Glucometer/${brand}`;

    return this.httpClient.post<ReturnStruct>(environment.urlApiSensors + url, null)
    .pipe(
      catchError(this.handleError)
    )
  }

  public stop(brand: string): Observable<ReturnStruct> {

    let url = `Glucometer/${brand}`;

    return this.httpClient.delete<ReturnStruct>(environment.urlApiSensors + url)
    .pipe(
      catchError(this.handleError)
    )
  }
}
