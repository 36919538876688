export const environment = {
    production: false,
    staging:true,
    hashEmailSmsAuthorization: "2d62ddbb4cef30c726b8e627a752041b",
    hashAuthorization: "0d3990c46dbdd338b5037946d41cf3ec9d35f95285127acaf1c742bf9e93a215",
    urlApiBase: "https://clusterstaging.tolife.app/",
    urlApiUser: "https://api.clusterstaging.tolife.app/tolifecloud-api-user/",
    urlApiAuth: "https://apiclusterstaging.tolife.app/tolifecloud-api-auth/",
    urlApiSrvEmail: "https://api.clusterstaging.tolife.app/tolifecloud-api-srvemail/",
    urlApiFlow: "https://api.clusterstaging.tolife.app/tolifecloud-api-flow/",
    urlApiTotem: "https://api.clusterstaging.tolife.app/tolifecloud-api-totem/",
    urlApiPanel: "https://api.clusterstaging.tolife.app/tolifecloud-api-panel/",
    urlWebsocket: "wss://clusterstaging.tolife.app/",
    urlApiOrchestratorIntegration: "https://api.clusterstaging.tolife.app/integration/",
    urlApiOrchestratorTotem: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-totem/",
    urlApiOrchestratorQueue: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-queue/",
    urlApiOrchestratorPanel: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-panel/",
    urlApiQueue: "https://api.clusterstaging.tolife.app/tolifecloud-api-queue/",
    urlApiFrontDesk: "https://api.clusterstaging.tolife.app/tolifecloud-api-frontdesk/",
    urlApiPharmacy: "https://api.clusterstaging.tolife.app/tolifecloud-api-pharmacy/",
    urlApiMedicalRecord:"https://api.clusterstaging.tolife.app/tolifecloud-api-medical-record/",
    urlApiSADT: "https://api.clusterstaging.tolife.app/tolifecloud-api-sadt/",
    urlApiTotemPrinter: "http://localhost:49577/",
    urlApiOrchestratorPatient: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-patient/",
    urlApiOrchestratorSADT: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-sadt/",
    urlApiRiskClassification: "https://api.clusterstaging.tolife.app/tolifecloud-api-risk-classification/",
    urlApiSensors: "http://localhost:5001/",
    urlApiIntegration: "https://api.clusterstaging.tolife.app/tolifecloud-api-integration/",
    urlApiAudit: "https://api.clusterstaging.tolife.app/tolifecloud-api-audit/",
    urlApiOrchestratorAudit: "https://api.clusterstaging.tolife.app/tolifecloud-api-orchestrator-audit/",
    urlApiBed: "https://api.clusterstaging.tolife.app/tolifecloud-api-bed/",
    urlWorkerSyncMeasurer:"http://localhost:7000/",
    urlApiSyncMeasurer: "https://api.clusterstaging.tolife.app/tolifecloud-api-sync-measurer/",
    urlApiUbsHealthPromotion: "https://api.clusterstaging.tolife.app/tolifecloud-api-ubs-health-promotion/",
    urlBaseUIDevice: "https://device.clusterstaging.tolife.app/",
    urlBaseDevice: "http://localhost:9090/",
    locale: 'pt-BR',
  };