import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common-service';
import { catchError, retry } from 'rxjs/operators';
import { ReturnStruct } from '../structs/return.struct';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient,) {
    super();
  }

  public signIn(login: string, password: string, ip: string): Observable<any> {

    let userAuthRequest ={
      login: login,
      password: password,
      ip: ip
    };

    return this.httpClient.post<any>(environment.urlApiAuth + "GenerateToken", userAuthRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  updateToken(userLoginResponse: any) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  updateDefaultModule(newDefaultModule: any) {
    let data = JSON.parse(sessionStorage.getItem("token")); 
    if (newDefaultModule != undefined && newDefaultModule != null) {
      data.idDefaultModule = newDefaultModule.idModule;
      data.uriDefaultModule = newDefaultModule.uri;
      data.defaultModuleName = newDefaultModule.nameModule;
    }
    else {
      data.idDefaultModule = null;
      data.uriDefaultModule = null;
      data.defaultModuleName = null;
    }
    sessionStorage.setItem("token", JSON.stringify(data));
  }

  logout() {
    sessionStorage.removeItem("token");
    this.router.navigate(['/'])
  }

  getToken() {
    // let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"))
    // return userLoginResponse.token;
  }

  
  getTokenMenu() {
    // let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    // return userLoginResponse;
  }
  
  isAuthenticated(): boolean {
    return sessionStorage.getItem("token") == null ? false : true;
  }
  
  haveRolePermission(idRole: number) : boolean{
    return false;
    // let hasRole = false;
    // let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    
    // userLoginResponse.listRole.forEach(element => {
    //   if(element.idRole == idRole){
    //     hasRole = true;
    //   }
    // });
    
    // return hasRole;
  }
}
