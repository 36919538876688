import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  title = 'tolifecloud-ui-device'; //olhar como passar esse titulo

  constructor(
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  private thisScreen: UbsScreenEnum = UbsScreenEnum.Welcome;
  public isError = false;
  public downtime: boolean;
  public audio = new Audio();
  private ubsData: UBSConfigData;


  ngOnInit(): void {
    this.utilsUBSService.removeListVisitidePages();
    this.ubsData = this.utilsUBSService.getUBSData();
    if (!this.ubsData || !this.ubsData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.utilsUBSService.removePatientData();
    this.utilsUBSService.removeRiskFactorsData();
    this.utilsUBSService.removeSendEmailPatientRequest();
    
    this.downtime = this.utilsUBSService.getDowntime();
    if (this.downtime){
      this.alertService.show('Atenção! ', ' O fluxo foi encerrado por inatividade!', AlertType.warning);
      this.utilsUBSService.removeDowntime();
    }
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.utilsUBSService.removeUBSPatientRequest();
  }

  playSound(choice: string) {
    if (choice == "first") {
      this.audio.src = `/assets/audio/${environment.locale}/welcome/Welcome-screen.mp3`
      this.audio.load();
      this.audio.play();
    }
  }

  clickGo() {
    this.audio.pause();

    let screen = this.ubsData.listUBSStruct.findIndex(x => x.idUBSScreen == this.thisScreen.valueOf());
    if (screen == -1)
      this.router.navigate(['ubs/erro']);

    let nextScreen = this.ubsData.listUBSStruct[screen + 1];
    if (!nextScreen)
      this.router.navigate(['ubs/erro']);

    this.router.navigate(['ubs' + nextScreen.screenUrl]);
  }
}