export class MeasureStruct{
    public syncMeasurerHash: string;
    
    public thermometer: Thermometer;
    
    public oximeter: Oximeter;
    
    public bloodPressure: BloodPressure;
    
    public glucometer: Glucometer;
    
    public isServerSync: boolean;

    public measureTime: Date;
}

export class Thermometer{
    public temperature: number;
}

export class Oximeter{
    public saturation: number;
    public heartRate: number;
}

export class BloodPressure{
    public bloodPressureSystole: number;
    public bloodPressureDiastole: number;
}

export class Glucometer{
    public glucose: number;
}