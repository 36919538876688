import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BloodPressureService } from 'src/app/shared/services/API/interface-measurer/blood-pressure.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { BloodPressure, MeasureStruct } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { PagesEnum } from '../pages.enum';
import { ManualBloodPressureModalComponent } from './manual-blood-pressure-modal/manual-blood-pressure-modal.component';


@Component({
  selector: 'app-blood-pressure',
  templateUrl: './blood-pressure.component.html',
  styleUrls: ['./blood-pressure.component.css']
})
export class BloodPressureComponent implements OnInit {

  constructor(private bloodPressureService: BloodPressureService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilsWelcomeService: UtilsWelcomeService) { }

  @Output("leaveMeasurer") leaveMeasurer: EventEmitter<any> = new EventEmitter();
  @Output("changePage") changePage: EventEmitter<any> = new EventEmitter();

  public measureWasMade: boolean = false;
  public sistolic: number;
  public diastolic: number;
  private measureStruct: MeasureStruct;
  private syncInterval: any;
  private isRequesting: boolean = false;

  public oximeter: number = PagesEnum.oximeter;
  public thermometer: number = PagesEnum.thermometer;

  ngOnInit(): void {
    let measure = this.utilsWelcomeService.getMeasureList();
    if (measure && measure[0]) {
      this.measureStruct = measure[0];
      if (measure[0].bloodPressure && measure[0].bloodPressure.bloodPressureDiastole && measure[0].bloodPressure.bloodPressureSystole) {
        this.diastolic = measure[0].bloodPressure.bloodPressureDiastole;
        this.sistolic = measure[0].bloodPressure.bloodPressureSystole;
        this.measureWasMade = true;
      }
    } else {
      this.measureStruct = new MeasureStruct;
    }
    this.RestartOximeter();
  }

  openManualModal() {
    const dialogRef = this.dialog.open(ManualBloodPressureModalComponent);

    dialogRef.afterClosed().subscribe(({ sistolic, diastolic }) => {
      if (sistolic && diastolic) {
        this.sistolic = sistolic;
        this.diastolic = diastolic;
        if (!this.measureStruct.bloodPressure) this.measureStruct.bloodPressure = new BloodPressure;
        this.measureStruct.bloodPressure.bloodPressureSystole = this.sistolic;
        this.measureStruct.bloodPressure.bloodPressureDiastole = this.diastolic;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }
    });
  }

  callChangePage(page: number) {
    this.changePage.next(page);
  }

  startOximeter() {
    this.bloodPressureService.start("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startInterval();
    }, () => {
      this.alertService.show('Erro', "Não foi possível iniciar o aparelho de pressão!", AlertType.error);
    });
  }

  RestartOximeter() {
    this.bloodPressureService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startOximeter();
    }, () => {
      this.alertService.show('Erro', "Não foi possível reiniciar o aparelho de pressão!", AlertType.error);
    });
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.bloodPressureService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    });
  }

  readOximeter() {
    if (this.isRequesting) return;
    this.isRequesting = true;
    this.bloodPressureService.read('x').subscribe((response) => {
      this.isRequesting = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (!response.running) {
        clearInterval(this.syncInterval);
        this.RestartOximeter();
      }

      if (response.measurer) {
        let measure = response.measurer.split("/");
        this.sistolic = parseInt(measure[0]);
        this.diastolic = parseInt(measure[1]);
        this.measureWasMade = true;
        if (!this.measureStruct.bloodPressure) this.measureStruct.bloodPressure = new BloodPressure;
        this.measureStruct.bloodPressure.bloodPressureSystole = this.sistolic;
        this.measureStruct.bloodPressure.bloodPressureDiastole = this.diastolic;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
      }
    });
  }

  startInterval() {
    this.syncInterval = setInterval(() => { this.readOximeter() }, 1000);
  }
}
