import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { MeasureService } from 'src/app/shared/services/API/sync-measurer/measure.service';
import { SetActivePatientService } from 'src/app/shared/services/API/sync-measurer/set-active-patient.service';
import { MeasureRequest } from 'src/app/shared/services/requests/sync-measurer/measure.request';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { PagesEnum } from '../pages.enum';
import { NoMeasurerConfirmationModalComponent } from './no-measurer-confirmation/no-measurer-confirmation-modal.component';


@Component({
  selector: 'app-finalization-screen',
  templateUrl: './finalization-screen.component.html',
  styleUrls: ['./finalization-screen.component.css']
})
export class FinalizationScreenComponent implements OnInit {

  constructor(private alertService: AlertService,
    private dialog: MatDialog,
    private utilsWelcomeService: UtilsWelcomeService,
    private measureService: MeasureService,
    private router: Router,
    private setActivePatientService: SetActivePatientService,
    private clockService: ClockService) { }

  public wasSended: boolean = false;
  public hadError: boolean = false;

  @Output("changePage") changePage: EventEmitter<any> = new EventEmitter();

  public oximeter: number = PagesEnum.oximeter;
  public datetimeStartRegister:Date;



  ngOnInit(): void {
    this.getDateTimeStart();
    this.setActivePatient();
  }

  callChangePage() {
    this.changePage.next(this.oximeter);
  }

  leaveMeasurer() {
    this.router.navigate(['/company/login'])
  }

  setActivePatient() {
    this.setActivePatientService.setActivePatient(this.utilsWelcomeService.getPatient()?.idPatient, this.utilsWelcomeService.getHash()).subscribe((response) => {
      if (response.isError) {
        if (this.hadError) {
          this.wasSended = true;
        }
        this.hadError = true;
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      var listMeasureStruct = this.utilsWelcomeService.getMeasureList();
      if (listMeasureStruct && listMeasureStruct.length) {
        this.sendMeasurements();
      } else {
        this.openModal();
      }
    },
      (error) => {
        if (this.hadError) {
          this.wasSended = true;
        }
        this.hadError = true;
        console.log(error)
        // this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.datetimeStartRegister = response.date;
    });
  }

  sendMeasurements(): void {
    let measureRequest: MeasureRequest = new MeasureRequest();
    measureRequest.listMeasureStruct = this.utilsWelcomeService.getMeasureList();
    measureRequest.syncMeasurerHash = this.utilsWelcomeService.getHash();
    measureRequest.idPatient = this.utilsWelcomeService.getPatient()?.idPatient;
    measureRequest.listMeasureStruct[0].measureTime = this.datetimeStartRegister ? this.datetimeStartRegister: new Date();
    this.measureService.sendMeasurements(measureRequest).subscribe((response) => {
      this.wasSended = true;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.hadError = true;
        return;
      }
      this.utilsWelcomeService.storeMeasureList([]);
      setTimeout(() => { this.router.navigate(['/company/login']) }, 3000);
    },
      (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.hadError = true;
      });
  }

  openModal() {
    const dialogRef = this.dialog.open(NoMeasurerConfirmationModalComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.send) {
        this.sendMeasurements();
      } else {
        this.callChangePage();
      }
    });
  }

}
