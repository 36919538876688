import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { CpfRegisterComponent } from './pages/cpf-register/cpf-register.component';
import { WelcomePageComponent } from './pages/welcome/welcome-page.component';
import { CpfErrorModalComponent } from './pages/cpf-register/cpf-error-modal/cpf-error-modal.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ErrorUbsHealthPromotionComponent } from './pages/error-ubs-health-promotion/error-ubs-health-promotion.component';
import { DataConfirmComponent } from './pages/data-confirm/data-confirm.component';
import { MeasurementGuidelinesScaleComponent } from './pages/measurement-guidelines-scale/measurement-guidelines-scale.component';
import { ErrorScaleUbsHealthPromotion } from './pages/measurement-guidelines-scale/error-scale-ubs-health-promotion/error-scale-ubs-health-promotion.component';
import { ResultResumeComponent } from './pages/result-resume/result-resume.component';
import { ResultMeasuresComponent } from './pages/result-resume/result-measures/result-measures.component';
import { ResultCounselingComponent } from './pages/result-resume/result-counseling/result-counseling.component';
import { ResultComponent } from './pages/result-resume/result/result.component';
import { RiskFactorsComponent } from './pages/risk-factors/risk-factors.component';
import { MeasurementGuidelinesPaComponent } from './pages/measurement-guidelines-pa/measurement-guidelines-pa.component';
import { PreviewComponent } from './pages/preview.component';
import { SuccessMeasurementsComponent } from './pages/success-measurements/success-measurements.component';
import { JourneyPageComponent } from './pages/journey/journey-page.component';
import { BirthDateVerifierComponent } from './pages/birth-date-verifier/birth-date-verifier.component';
import { GenderSelectComponent } from './pages/gender-select/gender-select.component';
import { HeightRegisterComponent } from './pages/height-register/height-register.component';
import { UBSHealthPromotionRoutingModule } from './ubs-health-promotion-routing.module';
import { CnsRegisterComponent } from './pages/cns-register/cns-register.component';
import { CompleteNameRegisterComponent } from './pages/complete-name-register/complete-name-register.component';
import { BirthDateRegisterIdentifierComponent } from './pages/birth-date-register-identifier/birth-date-register-identifier.component';
import { MotherCompleteNameRegisterComponent } from './pages/mother-complete-name-register/mother-complete-name-register.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MotherNameVerifierComponent } from './pages/mother-name-verifier/mother-name-verifier.component';
import { HouseholdIncomeComponent } from './pages/household-income/household-income.component';
import {MatSliderModule} from '@angular/material/slider';
import { InfosModalComponent } from './pages/gender-select/infos-modal/infos-modal.component';
import { LgpdComponent } from './pages/lgpd/lgpd.component';
import { EthnicityRegisterComponent } from './pages/ethnicity-register/ethnicity-register.component';
import { SharingMeasurementResultsComponent } from './pages/sharing-measurement-results/sharing-measurement-results.component';
import { EndModalComponent } from './pages/sharing-measurement-results/end-modal/end-modal.component';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    WelcomePageComponent,
    LgpdComponent,
    MeasurementGuidelinesPaComponent,
    PreviewComponent,
    RiskFactorsComponent,
    SuccessMeasurementsComponent,
    JourneyPageComponent,
    RiskFactorsComponent,
    SuccessMeasurementsComponent,
    MeasurementGuidelinesPaComponent,
    PreviewComponent,
    CpfRegisterComponent,
    CnsRegisterComponent,
    CompleteNameRegisterComponent,
    BirthDateRegisterIdentifierComponent,
    MotherCompleteNameRegisterComponent,
    BirthDateVerifierComponent,
    MotherNameVerifierComponent,
    GenderSelectComponent,
    HeightRegisterComponent,
    MeasurementGuidelinesPaComponent,
    CpfErrorModalComponent,
    PreviewComponent,
    DataConfirmComponent,
    ErrorUbsHealthPromotionComponent,
    MeasurementGuidelinesScaleComponent,
    ErrorScaleUbsHealthPromotion,
    ResultResumeComponent,
    ResultMeasuresComponent,
    ResultCounselingComponent,
    ResultComponent,
    RiskFactorsComponent,
    EndModalComponent,
    HouseholdIncomeComponent,
    EndModalComponent,
    InfosModalComponent,
    EthnicityRegisterComponent,
    SharingMeasurementResultsComponent,
  ],
  imports: [
    MatStepperModule,
    MatRadioModule,
    CommonModule,
    SharedModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    UBSHealthPromotionRoutingModule,
    MatDialogModule,
    MatSliderModule
  ],
  providers: [
    MatCheckbox,
    MatCheckboxChange,
    { provide: MatDialogRef, useValue: {} },
  ],
})
export class UBSHealthPromotionModule { }
