import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VerifyTwoNames } from 'src/app/shared/custom-validators/name.validator';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebhookCpfService } from 'src/app/shared/services/API/orchestrator-integration/webhook-cpf.service';
import { Masks } from 'src/app/shared/services/mask.service';
import { UBSSearchPatientRequest } from 'src/app/shared/services/requests/orchestrator-integration/ubs-search-patient.request';
import { UBSSearchResponse } from 'src/app/shared/services/responses/orchestrator-integration/ubs-search.response';
import { WebhookStruct } from 'src/app/shared/services/structs/Integration/webhook-struct';
import { DowntimeService } from '../../downtime.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patiet-by-cpf.service';
import { GetPatientNBMRequest } from 'src/app/shared/services/requests/medical-record/get-patient-nbm.request';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { UBSDataSectionService } from 'src/app/shared/services/API/ubs-health-promotion/ubs-data-section.service';
import { UbsWebhookService } from 'src/app/shared/services/API/orchestrator-patient/ubs-webhook.service';
import { UBSWebhookPatientStruct } from 'src/app/shared/services/structs/orchestrator-patient/ubs-webhook-patient.struct';

@Component({
  selector: 'app-mother-complete-name-register',
  templateUrl: './mother-complete-name-register.component.html',
  styleUrls: ['./mother-complete-name-register.component.css']
})
export class MotherCompleteNameRegisterComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private utilsUBSService: UtilsUBSService,
    private webhookCpfService: WebhookCpfService,
    private ubsWebhookService: UbsWebhookService,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    private getPatient: GetPatientByCpfService,
    private ubsDataSectionService: UBSDataSectionService,
  ) { }

  private patientData: PatientData = new PatientData();
  public lgpd: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public audio = new Audio();
  public journeyStageId: number = JourneyStageEnum.Identificacao;
  public idUbsScreen: number = UbsScreenEnum.CpfRegister;
  public uBSConfigData: UBSConfigData;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();

    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1)
      this.router.navigate(['/ubs/erro']);

    this.model = this.formBuilder.group({
      motherCompleteName: ['', [
        Validators.required,
        VerifyTwoNames()
      ]],
    });
    if (this.utilsUBSService.getPatientData()) {
      this.patientData = this.utilsUBSService.getPatientData();
      this.model.get('motherCompleteName').setValue(this.patientData.motherName);
    }
    this.isLoading = false;
    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid) {
      return;
    }

    this.audio.pause();
    this.isLoading = true;

    let motherCompleteName = this.model.get('motherCompleteName').value;
    this.patientData.motherName = motherCompleteName;

    this.utilsUBSService.createPatientData(this.patientData);

    let request = new UBSSearchPatientRequest();
    request.name = this.patientData.name;
    request.birthDate = this.patientData.birthDate;
    request.motherName = this.patientData.motherName;
    request.url = '';
    request.token = '';
    request.guid = '';

    this.callSearchPatient(request);
  }

  playAudio() {
    this.audio.src = `../assets/audio/${this.locale}/mother-complete-name-request.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    this.router.navigate([lastPage]);
    //this.router.navigate([`ubs/birth-date-register-identifier`]);
  }

  callSearchPatient(request: UBSSearchPatientRequest){  
    this.isLoading = true;
    this.patientData.existsInBaseData = true;
    let healthUnit = this.utilsUBSService.getHealthUnit();
    request.isFullTermPatientSearch = true;

    let webhookStruct: WebhookStruct = this.utilsUBSService.getWebhook();
    if (webhookStruct){
      request.url = webhookStruct.url;
      request.token = webhookStruct.token;
      request.guid = webhookStruct.guid;
    }

    let jsonListIdHealthUnit = null;
    if(healthUnit.listIdHealthUnitInGroup)
      jsonListIdHealthUnit = JSON.stringify(healthUnit.listIdHealthUnitInGroup);

    this.ubsWebhookService.uBSSearchPatient(null, healthUnit.idHealthUnit, false, null, jsonListIdHealthUnit, request).subscribe({
      next: (response) => {
        if ((response.isError && response.errorCode == 1 && webhookStruct && !response.patientMedicalRecordStruct) || 
            (!response.isError && !response.patientMedicalRecordStruct && !response.uBSWebhookPatientStruct)) {
              this.patientData.existsInBaseData = false;
        }
        else if (response.isError) {
          this.isLoading = false;
          this.downtimeService.stopTimer();
          this.router.navigate(['/ubs/erro']);
          return;
        }

        if (response.patientMedicalRecordStruct && this.patientData.existsInBaseData){
          this.mapToPatientMedicalRecordData(response.patientMedicalRecordStruct);
          this.getAditionalPatientData(response.patientMedicalRecordStruct.idPatient);
        }
        else if (response.uBSWebhookPatientStruct && this.patientData.existsInBaseData) {
          this.mapToPatientData(response.uBSWebhookPatientStruct);
          this.getAditionalPatientData(response.uBSWebhookPatientStruct.idPatient);
        } 

        this.utilsUBSService.createPatientData(this.patientData);
        this.downtimeService.stopTimer();
        this.saveLastPage();
        this.isLoading = false; 
        
        if (this.patientData.existsInBaseData)
          this.router.navigate([`ubs/data-confirm`]);
        else
          this.router.navigate([`ubs/ethnicity-register`]);
               
        return;
               
      },
      error: (error) => {
        console.log(error)
        this.isLoading = false;
        this.downtimeService.stopTimer();
        this.router.navigate(['/ubs/erro']);
      }
    });
  }

  getAditionalPatientData(idPatient: number) {
    this.ubsDataSectionService.getUBSDataSection(idPatient).subscribe((response) => {
      if (response.ubsDataSection) {
        this.patientData.idGender = response.ubsDataSection.idUbsGender;
        this.patientData.householdIncome = response.ubsDataSection.householdIncome;
        this.patientData.ethnicity = response.ubsDataSection.idEthnicityGroup;
      }
      this.utilsUBSService.createPatientData(this.patientData);
    },
      (error) => {
        console.log(error);
      })
  }

  mapToPatientData(data: UBSWebhookPatientStruct) {
    this.patientData.idPatient = data.idPatient;
    this.patientData.cpf = data.cpf;
    this.patientData.cns = data.cns;
    this.patientData.name = data.name;
    this.patientData.birthDate = data.birthDate;
    this.patientData.motherName = data.motherName;
  }

  mapToPatientMedicalRecordData(data: PatientStruct) {
    this.patientData.idPatient = data.idPatient;
    this.patientData.cpf = data.cpf;
    this.patientData.cns = data.cns;
    this.patientData.name = data.patientName;
    this.patientData.birthDate = data.birthDate;
    this.patientData.motherName = data.motherName;
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}