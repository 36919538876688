import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from './company-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { IsNotMeModalComponent } from './pages/login/is-not-me-modal/is-not-me-modal.component';
import { ConfirmPatientModalComponent } from './pages/login/confirm-patient-modal/confirm-patient-modal.component';
import { CPFNotFindedModalComponent } from './pages/login/cpf-not-finded-modal/cpf-not-finded-modal.component';
import { MeasurerComponent } from './pages/measurer/measurer.component';
import { OximeterComponent } from './pages/measurer/oximeter/oximeter.component';
import { LeaveConfirmationModalComponent } from './pages/measurer/leave-confirmation/leave-confirmation-modal.component';
import { ManualOximeterModalComponent } from './pages/measurer/oximeter/manual-oximeter-modal/manual-oximeter-modal.component';
import { BloodPressureComponent } from './pages/measurer/blood-pressure/blood-pressure.component';
import { ManualBloodPressureModalComponent } from './pages/measurer/blood-pressure/manual-blood-pressure-modal/manual-blood-pressure-modal.component';
import { GlucometerComponent } from './pages/measurer/glucometer/glucometer.component';
import { ManualGlucometerModalComponent } from './pages/measurer/glucometer/manual-glucometer-modal/manual-glucometer-modal.component';
import { ThermometerComponent } from './pages/measurer/thermometer/thermometer.component';
import { ManualThermometerModalComponent } from './pages/measurer/thermometer/manual-thermometer-modal/manual-thermometer-modal.component';
import { FinalizationScreenComponent } from './pages/measurer/finalization-screen/finalization-screen.component';
import { NoMeasurerConfirmationModalComponent } from './pages/measurer/finalization-screen/no-measurer-confirmation/no-measurer-confirmation-modal.component';
import { RegisterComponent } from './pages/register/register.component';
import { AnamneseComponent } from './pages/anamnese/anamnese.component';
import { FinishComponent } from './pages/anamnese/finish/finish.component';
import { ConfirmExitModalComponent } from './pages/anamnese/confirm-exit-modal/confirm-exit-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    LoginComponent,
    IsNotMeModalComponent,
    ConfirmPatientModalComponent,
    CPFNotFindedModalComponent,
    MeasurerComponent,
    OximeterComponent,
    LeaveConfirmationModalComponent,
    ManualOximeterModalComponent,
    BloodPressureComponent,
    ManualBloodPressureModalComponent,
    GlucometerComponent,
    ManualGlucometerModalComponent,
    ThermometerComponent,
    ManualThermometerModalComponent,
    FinalizationScreenComponent,
    NoMeasurerConfirmationModalComponent,
    RegisterComponent,
    AnamneseComponent,
    FinishComponent,
    ConfirmExitModalComponent,
  ],
  imports: [
    MatRadioModule,
    CommonModule,
    CompanyRoutingModule,
    SharedModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CdkAccordionModule,
    MatDialogModule,
  ]
})
export class CompanyModule { }
