<div mat-dialog-content class="content">
    <h3><b>CPF não cadastrado</b></h3>
    <div class="row">
        <div class="col-md-12">
            <p>O CPF inserido pode estar errado ou não cadastrado.</p>
            <p>Você pode tentar novamente ou cadastrar seu cpf!</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="clickTryAgain()">
                <span>Tentar novamente</span>
            </button>
            <button type="button" mat-flat-button (click)="clickRegister()" class="btn-secundary btn-block">
                <span>Cadastrar meu CPF</span>
            </button>
        </div>
    </div>
</div>