import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { catchError, retry } from 'rxjs/operators';
import { GenderResponse, LookupResponse } from '../../responses/medical-record/lookup.response';


@Injectable({
    providedIn: 'root'
  })
  export class LookupService extends CommonService {
  
    constructor(private router: Router,
      private httpClient: HttpClient,) {
      super();
    }

public getLookup(): Observable<LookupResponse>{

    let uri = `Lookup`

    return this.httpClient.get<LookupResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }

public getGenders(): Observable<GenderResponse>{

    let uri = `Lookup/GetGenders`

    return this.httpClient.get<GenderResponse>(environment.urlApiMedicalRecord + uri, this.addHeaderHash())
    .pipe(
      catchError(this.handleError)
    )
  }
}