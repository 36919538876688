import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import {APP_BASE_HREF, registerLocaleData} from '@angular/common';
import { WelcomeModule } from './welcome/welcome.module';
import { HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CompanyModule } from './company/company.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UBSHealthPromotionModule } from './ubs-health-promotion/ubs-health-promotion.module';

import localePt from '@angular/common/locales/pt';
import { environment } from 'src/environments/environment';
registerLocaleData(localePt);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    AuthModule,
    WelcomeModule,
    CompanyModule,
    UBSHealthPromotionModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: true,
      enabled: environment.production || environment.staging
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
