<div class="result-box"
    [ngClass]="{
    'measures-green': (!isImcResult && paCathegory == PaCathegoryEnum.noAdditionalRisk) || (isImcResult && (imcCathegory == underweight || imcCathegory == normalWeight)), 
    'measures-yellow': (isImcResult && imcCathegory == overweight) || (isPaResult && paCathegory == PaCathegoryEnum.borderline), 
    'measures-red': (!isImcResult && (paCathegory == PaCathegoryEnum.hypertension)) || (isImcResult && imcCathegory == obesity)}">
    <div class="box-info">
        <span class="result-box-title" i18n>{{title}}</span>

        <div class="result-box-value">{{resultValue}}</div>

        <div class="result-box-separator"
            [ngClass]="{
            'measures-green': (!isImcResult && paCathegory == PaCathegoryEnum.noAdditionalRisk) || (isImcResult && (imcCathegory == underweight || imcCathegory == normalWeight)), 
            'measures-yellow': (isImcResult && imcCathegory == overweight) || (isPaResult && paCathegory == PaCathegoryEnum.borderline), 
            'measures-red': (!isImcResult && (paCathegory == PaCathegoryEnum.hypertension)) || (isImcResult && imcCathegory == obesity) }">
        </div>
    </div>
    <div class="box-info cathegory">
        <span i18n class="result-box-cathegory">{{cathegory}}</span>
        <span class="reference-value" i18n>{{referenceValue}}</span>
    </div>
</div>