export enum WebhookTypeEnum
{
    buscaPacienteTotem = 1,
    buscaPacienteRecepcao = 4,
    buscaPacientePreRegister = 5,
    patientCall = 3,
    endClassification = 6,
    evasion = 7,
    telephoneClassificationEnd = 8,
    totemTicketGenerate = 9,
    uBSBuscarpaciente = 10,
    uBSCriarPaciente = 11,
    uBSSalvarafericoes = 12,
}