export class UBSSearchPatientRequest {
    public name: string;
    public motherName: string;
    public cpf: string;
    public cns: string;
    public birthDate: Date;
    public url: string;
    public token: string;
    public guid: string;
    public isFullTermPatientSearch: boolean;
}