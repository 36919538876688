<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Você realmete é?</h2>
         </div>
         
         <div class="body-modal-list">
            <h4>{{data.patient.patientName}}</h4>
            <p>Data de nascimento: {{data.patient.birthDate | date:'dd/MM/yyyy'}}</p>
         </div>
         <div class="footer-modal-list">
            <button mat-flat-button color="primary" class=" block" (click)="clickConfirm()">Sim, sou eu!</button>
            <button mat-flat-button class="btn-secundary block"(click)="clickCancel()">Não!</button>
        </div>
    </div>
</div>