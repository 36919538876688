import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SyncMeasurerHashService } from 'src/app/shared/services/API/interface-measurer/sync-measurer-hash.service';
import { CallSyncMeasurerPatientService } from 'src/app/shared/services/API/orchestrator-patient/call-sync-measurer-patient.service';
import { MeasureService } from 'src/app/shared/services/API/sync-measurer/measure.service';
import { MeasureRequest } from 'src/app/shared/services/requests/sync-measurer/measure.request';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { UtilsWelcomeService } from '../../../shared/services/session/utils-welcome.service';
import { ConfirmPatientModalComponent } from './confirm-patient-modal/confirm-patient-modal.component';

@Component({
  selector: 'app-list-patient',
  templateUrl: './list-patient.component.html',
  styleUrls: ['./list-patient.component.css']
})
export class ListPatientComponent implements OnInit {

  constructor(
    private callSyncMeasurerPatientService: CallSyncMeasurerPatientService,
    private syncMeasurerHashService: SyncMeasurerHashService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    private utilsWelcomeService: UtilsWelcomeService,
    private measureService: MeasureService,
  ) { }
    
  public listPatient: PatientStruct[];
  public syncMeasurerHash: string;
  public isLoading: boolean;
  public isActive: boolean;
  public searchText: string;
  public idExamType: number;
  private intervalInteretTest;
  private syncInterval;
  public isInternetAccess: boolean;

  ngOnInit(): void {
    this.isLoading = true;
    this.listPatient = this.utilsWelcomeService.getListPatient();
    this.getSyncMeasurerHash();
  }

  ngOnDestroy(): void{
    clearInterval(this.intervalInteretTest);
    clearInterval(this.syncInterval);
  }

  getSyncMeasurerHash(){
    this.syncMeasurerHashService.getHash().subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      
      this.syncMeasurerHash = response.syncMeasurerHash;     
      this.utilsWelcomeService.updateHash(response.syncMeasurerHash);
      this.testInternet();
    },
    (error)=>{
      console.log(error)    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openModal(patient){
    const dialogRef = this.dialog.open(ConfirmPatientModalComponent, {
      data: {
        patient: patient
      },
      minWidth: 350
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.confirm){
        this.router.navigate(['/patient-measurement', {idPatient: patient.idPatient, hash: this.syncMeasurerHash}]);
      }
    });
  }

  public testInternet():void{
    let testFunc = ()=>{
      console.log("testing internet");
      this.isLoading = true;  
    
      if(this.utilsWelcomeService.getListPatient())
        this.listPatient = this.utilsWelcomeService.getListPatient();

      this.callSyncMeasurerPatientService.listPatientSyncMeasurer(this.syncMeasurerHash).subscribe((response)=>{
        this.isInternetAccess = true;
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          this.isInternetAccess = false;
          return;
        }
        
        this.utilsWelcomeService.setListPatient(response.listPatient);
        this.listPatient = response.listPatient;
        
        this.isLoading = false;    
      },
      (error)=>{
        console.log(error)   
        this.isInternetAccess = false;
        //this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      });
    };

    this.intervalInteretTest = setInterval(testFunc, 5000);
  }

  updateListMeasures(){
    let syncMeasurement = () =>{
    
      //Enviar medidas para api se isInternetAccess = true;
      if(this.isInternetAccess 
        && (this.utilsWelcomeService.getMeasureList() && this.utilsWelcomeService.getMeasureList().length > 0)){
        this.sendMeasurements();
      }
    }
    
    this.syncInterval = setInterval(syncMeasurement, 3000)
  }

  sendMeasurements(): void{
    let listMeasureStruct = [];
    let measureRequest: MeasureRequest = new MeasureRequest();
    measureRequest.listMeasureStruct = listMeasureStruct;
    measureRequest.syncMeasurerHash = this.utilsWelcomeService.getHash();
    measureRequest.idPatient = this.utilsWelcomeService.getPatient()?.idPatient;

    this.measureService.sendMeasurements(measureRequest).subscribe((response) =>{
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if(this.utilsWelcomeService.getMeasureList()){
        listMeasureStruct = this.utilsWelcomeService.getMeasureList();
      } 

      listMeasureStruct.forEach((measurement)=>{
        if(!measurement.isServerSync){
          measurement.isServerSync = true;
        }
      })

      this.utilsWelcomeService.storeMeasureList(listMeasureStruct);
    },
    (error)=>{
      console.log(error)    
      // this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }
}
