import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Masks } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { UBSLookupService } from 'src/app/shared/services/API/ubs-health-promotion/ubs-lookup.service';
import { UBSFemaleNames } from 'src/app/shared/services/models/ubs-health-promotion/ubs-female-names.model';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { UbsErrorOutdatedDataModalComponent } from 'src/app/shared/components/ubs-error-outdated-data-modal/ubs-error-outdated-data-modal.component';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-mother-name-verifier',
  templateUrl: './mother-name-verifier.component.html',
  styleUrls: ['./mother-name-verifier.component.css']
})
export class MotherNameVerifierComponent implements OnInit, OnDestroy {

  private title: string = 'mother-name-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    private ubsLookupService: UBSLookupService,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) {
      this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;  
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public listMothers: string[] = [];
  public listFemaleNames: UBSFemaleNames[];
  public firstMotherName: string;
  public tries: number = 0;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.BirthDateRegister;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1)
      this.router.navigate(['/ubs/erro']);
    
    this.model = this.formBuilder.group({
      motherName: ['', [Validators.required]],
    });

    this.patientData = this.utilsUBSService.getPatientData();
    this.patientData.motherName = this.patientData.motherName.toLowerCase();
    this.patientData.motherName = this.patientData.motherName.replace(this.patientData.motherName.charAt(0), this.patientData.motherName.charAt(0).toUpperCase());
    this.firstMotherName = this.patientData.motherName.split(" ")[0];
    this.getFemaleNames();
    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid) {
      return;
    }
    let motherNameVerify = this.model.get('motherName').value;
    
    this.audio.pause();
    if (motherNameVerify == this.firstMotherName){
      this.downtimeService.stopTimer();
      this.saveLastPage();
      this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);
    } else {
      this.openErrorModal();
    }
  }

  playAudio(){
    this.audio.src = `../assets/audio/${this.locale}/mother-name-confirm.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack(){
    this.audio.pause();
    this.downtimeService.stopTimer();
    
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    
    this.router.navigate([lastPage]);
  }

  openErrorModal() {
    this.audio.pause();
    const dialogRef = this.dialog.open(UbsErrorOutdatedDataModalComponent, {
      disableClose: true,
      data: {
        tryAgain: (this.tries < 1) ? true : false
      },
      panelClass: 'error-outdated-modal',
      backdropClass: 'backdrop-error-outdated'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result == 'finish') {
        this.downtimeService.stopTimer();
        this.utilsUBSService.removeListVisitidePages();
        this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
      } else {
        this.tries++;
        this.model.get('motherName').setValue(null);
        this.randomNames();
      }
    });
  }

  randomNames() {
    this.listMothers = [];
    this.listMothers.push(this.firstMotherName);
    while(this.listMothers.length < 4){
      let name = this.listFemaleNames[Math.floor(Math.random() * this.listFemaleNames.length)].ubsFemaleName;
      if(this.listMothers.find(x => x == name)){
        continue;
      }
      this.listMothers.push(name);
    }
    this.listMothers = this.shuffleArray(this.listMothers);
  }

  shuffleArray(arr: any[]) {
    // Loop em todos os elementos
    for (let i = arr.length - 1; i > 0; i--) {
            // Escolhendo elemento aleatório
        const j = Math.floor(Math.random() * (i + 1));
        // Reposicionando elemento
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    // Retornando array com aleatoriedade
    return arr;
  }

  getFemaleNames() {
    this.ubsLookupService.getAllUBSFemaleNames().subscribe((response) => {
      if (response.isError) {
        this.isLoading = false;
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.listFemaleNames = response.listUbsFemaleNames;
      this.randomNames();
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}