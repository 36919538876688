<header>
    <img src="assets/images/logo-branco.png" />
    <span class="title-header-company">{{healthUnitName}}</span>
</header>
<div class="container-body">
    <form class="form" [formGroup]="model" (ngSubmit)="searchByCPF()">
        <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
                <div class="white-box">
                    <h2>Digite seu CPF para verificarmos seu cadastro!</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Insira seu CPF</mat-label>
                                <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
                                <mat-error *ngIf="model.get('cpf').invalid">Informe seu CPF</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button mat-flat-button type="submit" color="primary" class=" btn-block">
                                <span *ngIf="isLoading == false">Avançar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>