import { Injectable } from "@angular/core";
import { UBSRequest } from "../shared/services/requests/orchestrator-patient/ubs.request";
import { PostUbsHealthPromotionSmsEmailRequest } from "../shared/services/requests/srv-email/post-ubs-health-promotion-sms-email.request";
import { WebhookStruct } from "../shared/services/structs/Integration/webhook-struct";
import { UBSStruct } from "../shared/services/structs/ubs-health-promotion/ubs-struct";
import { HealthUnitStruct } from "../shared/services/structs/user/health-unit.struct";

@Injectable({
    providedIn: 'root'
})
export class UtilsUBSService {
    constructor() { }
    getUBSData() {
        let uBSData: UBSConfigData = JSON.parse(sessionStorage.getItem("uBSData"))
        return uBSData;
    }
    createUBSData(uBSData: UBSConfigData) {
        sessionStorage.setItem("uBSData", JSON.stringify(uBSData));
    }
    removeUBSData() {
        sessionStorage.removeItem("uBSData");
    }
    
    createPatientData(data: PatientData) {
        sessionStorage.setItem("patientData", JSON.stringify(data));
    }
    
    getPatientData() {
        let patientData: PatientData = JSON.parse(sessionStorage.getItem("patientData"))
        return patientData;
    }

    removePatientData(){
        sessionStorage.removeItem("patientData");
    }
    
    createRiskFactorsData(data: RiskFactorsData) {
        sessionStorage.setItem("riskFactorsData", JSON.stringify(data));
    }
    
    getRiskFactorsData() {
        let data: RiskFactorsData = JSON.parse(sessionStorage.getItem("riskFactorsData"))
        return data;
    }

    removeRiskFactorsData(){
        sessionStorage.removeItem("riskFactorsData");
    }
    
    createBaseUrl(urlBase: string) {
        localStorage.setItem("baseUrl", JSON.stringify(urlBase));
    }

    getBaseUrl() {
        let baseUrl = sessionStorage.getItem("baseUrl");  
        return baseUrl;
    }

    updatehealthUnit(healthUnit: HealthUnitStruct): void {
        sessionStorage.setItem("healthUnit", JSON.stringify(healthUnit));
    }
    
    getHealthUnit() {
        let healthUnit: HealthUnitStruct = JSON.parse(sessionStorage.getItem("healthUnit"))
        return healthUnit;
    }
    
    createUBSPatientRequest(data: UBSRequest) {
        sessionStorage.setItem("patientUBSRequest", JSON.stringify(data));
    }

    getUBSPatientRequest() {
        let patientUBSRequest: UBSRequest = JSON.parse(sessionStorage.getItem("patientUBSRequest"))
        return patientUBSRequest;
    }

    removeUBSPatientRequest(){
        sessionStorage.removeItem("patientUBSRequest");
    }

    createSendEmailPatientRequest(data: PostUbsHealthPromotionSmsEmailRequest) {
        sessionStorage.setItem("patientSendEmailRequest", JSON.stringify(data));
    }

    getSendEmailPatientRequest() {
        let patientSendEmailRequest: PostUbsHealthPromotionSmsEmailRequest = JSON.parse(sessionStorage.getItem("patientSendEmailRequest"))
        return patientSendEmailRequest;
    }

    removeSendEmailPatientRequest(){
        sessionStorage.removeItem("patientSendEmailRequest");
    }

    createDowntime(downtime: boolean) {
        sessionStorage.setItem("downtime", JSON.stringify(downtime));
    }

    getDowntime() {
        let downtime: boolean = JSON.parse(sessionStorage.getItem("downtime"))
        return downtime;
    }

    removeDowntime(){
        sessionStorage.removeItem("downtime");
    }

    getWebhook() {
        let webhook: WebhookStruct = JSON.parse(sessionStorage.getItem("webhook"))
        return webhook;
    }
    
    createWebhook(data: WebhookStruct) {
        sessionStorage.setItem("webhook", JSON.stringify(data));
    }


    removeListVisitidePages(){
        sessionStorage.removeItem("visitedPages");
    }

    getListVisitidePages() {
        let visitedPages: string[] = JSON.parse(sessionStorage.getItem("visitedPages"))
        return visitedPages;
    }
    
    updateListVisitidePages(data: string[]) {
        sessionStorage.setItem("visitedPages", JSON.stringify(data));
    }
}

export class UBSConfigData {
    public listUBSStruct: UBSStruct[] = [];

    public healthUnitToken: string;
         
    public idHealthUnit: number;             
    
    public ipDevice: string;

    public isClientTest: boolean;
}

export class PatientData {
    public existsInBaseData: boolean;

    public listUBSStruct: UBSStruct[];        
    
    public healthUnitToken: string;               
    
    public idHealthUnit: number;

    public birthDate: Date;
    public height: number;
    public sistolicPressure: number;
    public diastolicPressure: number;
    public weight: number;
    public optIn: boolean = false;
    public idPatient: number;
    public idGender: number;
    public ethnicity: number;
    public imc: number;
    public optin: boolean;
    public cpf: string;
    public searchByCPF: boolean;
    public searchByCNS: boolean;
    public cns: string;
    public name: string;
    public socialName: string;
    public motherName: string;
    public fatherName: string;
    public householdIncome: string;
    public hasDiabetes: boolean;
    public hasHighCholesterol: boolean;
    public hasHypertension: boolean;
    public haveOverweightObesity: boolean;
    public tookHypertensionMedication: boolean;
}

export class RiskFactorsData {
    public haveHighPressure: boolean = false;
    public havePressureRemedy: boolean = false;

    public haveDiabetes: boolean = false;
    public haveTreatedDiabetes: boolean = false;

    public haveHighCholesterol: boolean = false;
    public haveTakeMedicineCholesterol: boolean = false;

    public haveOverweightObesity: boolean = false;
    public haveTrackWeighWithProfessional: boolean = false;
}