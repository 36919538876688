import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-ubs-error-modal',
  templateUrl: './ubs-error-modal.component.html',
  styleUrls: ['./ubs-error-modal.component.css']
})
export class UbsErrorModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOCALE_ID) public locale: string,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UbsErrorModalComponent>,
    private alertService: AlertService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  finishSession(){
    this.matDialogRef.close('finish');
  }

  closeModal() {
    this.matDialogRef.close();
  }

  playAudio(){
    let audio = new Audio();
    audio.src = `/assets/audio/${this.locale}/error-getting-identification.mp3`
    audio.load();
    audio.play();
    audio.remove();
  }
}
