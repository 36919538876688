export class PatientDeviceRequest {
    public idPatient?: number;
    public idHealthUnitCreator?: number;
    public idHealthUnitDevice?: number;
    public patientName: string;
    public cpf: string;
    public birthDate?: Date;
    public datetimeInclusion?: Date;
    public idGender?: number;
    public zipCode?: string;
    public street?: string;
    public neighborhood: string;
    public city: string;
    public state: string;
    public country: string;
    public phone1: string;
    public houseNumber: string;
    public apartmentNumber: string;
    
    public idPatientEmployee?: number;
    public unitBranch: string;
    public sectorEmployee: string;
    public roleEmployee: string;
    public registryEmployee: string;
}