<header>
    <img src="assets/images/logo-branco.png" />
</header>
<div class="container-body">
    <h1 *ngIf="listPatient != null">Clique no seu nome!</h1>
        <div class="card-list" *ngIf="listPatient != null">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of listPatient" (click)="openModal(item)">
                    <div class="card-item">
                        <div class="infos">
                            <span class="primary">{{item.patientName}}</span>
                            <span class="secundary">{{item.birthDate | date:'dd/MM/yyyy'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <h1 class="no-internet" *ngIf="!listPatient">
            Conenctando a internet...
        </h1>
    <app-loading-list *ngIf="isLoading"></app-loading-list>
</div>