import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { WebhookCpfService } from 'src/app/shared/services/API/orchestrator-integration/webhook-cpf.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DowntimeService } from '../../downtime.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UbsLgpdInfoModalComponent } from 'src/app/shared/components/ubs-lgpd-info-modal/ubs-lgpd-info-modal.component';
import { UbsLgpdProtocolModalComponent } from 'src/app/shared/components/ubs-lgpd-protocol-modal/ubs-lgpd-protocol-modal.component';

@Component({
  selector: 'app-lgpd',
  templateUrl: './lgpd.component.html',
  styleUrls: ['./lgpd.component.css']
})
export class LgpdComponent implements OnInit, OnDestroy {

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private webhookService: WebhookCpfService,
    private utilService: UtilsUBSService,
    public matDialogRef: MatDialogRef<UbsLgpdProtocolModalComponent>,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
  ) { }

  private patientData: PatientData = new PatientData();
  public lgpd: boolean = false;
  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public audio = new Audio();
  public journeyStageId: number = JourneyStageEnum.Identificacao;
  public idUbsScreen: number = UbsScreenEnum.Lgpd;
  public uBSConfigData: UBSConfigData;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilService.getUBSData();

    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);

    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }

    this.masks = this.maskService.getMasks();
    this.isLoading = false;

    this.downtimeService.startTimer(900000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  close() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.utilService.removeListVisitidePages();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[0].screenUrl}`]);
  }

  continue() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.patientData.optIn = true;
    this.utilService.createPatientData(this.patientData);
    this.saveLastPage();
    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`])
  }

  openLgpdInfo() {
    this.audio.pause();
    const dialogRef = this.dialog.open(UbsLgpdInfoModalComponent, {
      panelClass: 'modal-lgpd',
      backdropClass: 'backdrop-lgpd'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.lgpd == true) {
        this.continue();
      }
    });
  }

  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/lgpd/lgpd.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  saveLastPage(){
    let visitedPages = this.utilService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilService.updateListVisitidePages(visitedPages);
  }
}
