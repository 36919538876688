<div class="container-out"></div>
<div class="mat-dialog-content ubs">
    <div class="close-button">
        <mat-icon mat-icon aria-hidden="false" aria-label="fechar" (click)="finishSession()">close</mat-icon>
    </div>
    <div class="no-identifier-content">
        <p i18n>
            <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
            Não é possível continuar! <strong>Procure a recepção
                da UBS</strong>
            para atualizar os seus dados e tente novamente mais tarde. </p>
    </div>
    <div class="footer-confirm-modal-list">
        <button mat-flat-button class="btn-try-again" (click)="closeModal()">
            <span i18n>Tentar novamente</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward_ios</mat-icon>
            </div>
        </button>
    </div>
    <div class="button-container">
        <button mat-flat-button class="btn-finish" (click)="finishSession()">
            <span i18n>Encerrar</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward_ios</mat-icon>
            </div>
        </button>
    </div>
</div>