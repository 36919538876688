<div class="top-spacing">
    <div (click)="playAudio()" class="title-error-component">
        <img src="assets/icons/btn-sound.png" /> <span class="title" i18n>ERRO AO MEDIR PESO E ALTURA</span>
    </div>
    <div class="conteiner-red-box">
        <div class="error-img">
            <img src="assets/images/error-round.png" />
        </div>
        <div class="error-message" i18n>
            Tivemos um problema ao medir o seu peso e altura. Por favor, solicite o suporte da equipe da UBS.
        </div>
    </div>
    <div class="action-error-content">
        <button mat-flat-button class="block btn-back" (click)="callRetryMeasurer()">
            <div>
                <mat-icon class="icon-back material-symbols-rounded">arrow_back</mat-icon>
            </div>
            <span i18n>VOLTAR</span>
        </button>
        <button mat-flat-button class="block btn-go btn-ubs" (click)="callEndFlow()">
            <span i18n>ENCERRAR</span>
        </button>
    </div>
</div>