import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { SyncMeasurerHashService } from 'src/app/shared/services/API/interface-measurer/sync-measurer-hash.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patiet-by-cpf.service';
import { SyncMeasureService } from 'src/app/shared/services/API/sync-measurer/get-sync-measure.service';
import { PatientAnamnesisService } from 'src/app/shared/services/API/sync-measurer/patient-anamnesis.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { ConfirmPatientModalComponent } from './confirm-patient-modal/confirm-patient-modal.component';
import { CPFNotFindedModalComponent } from './cpf-not-finded-modal/cpf-not-finded-modal.component';
import { IsNotMeModalComponent } from './is-not-me-modal/is-not-me-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private alertService: AlertService,
    private maskService: MaskService,
    private syncMeasurerHashService: SyncMeasurerHashService,
    private utilsWelcomeService: UtilsWelcomeService,
    private getPatientByCpfService: GetPatientByCpfService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private syncMeasureService: SyncMeasureService,
    private patientAnamnesisService: PatientAnamnesisService) { }

  public model: FormGroup;
  public isLoading: boolean = true;
  public masks: Masks;
  public healthUnitName: string;
  public idHealthUnit: number;
  private syncMeasurerHash: string;


  ngOnInit(): void {
    this.utilsWelcomeService.removePatient();
    this.masks = this.maskService.getMasks();

    this.syncMeasurerHash = this.utilsWelcomeService.getHash();

    this.syncMeasurerHash = "5f9584f1-7b59-4573-836c-ea145819ab23";

    let healthUnit = this.utilsWelcomeService.gethealthUnit();
    this.model = this.formBuilder.group({
      cpf: ['', [Validators.required]],
    });
    if (this.syncMeasurerHash == null && healthUnit == null) {
      this.getSyncMeasurerHash();
    }
    else if (this.syncMeasurerHash != null && healthUnit == null) {
      this.getIdHealthUnit(this.syncMeasurerHash);
    }
    else {
      this.isLoading = false;
      this.healthUnitName = this.utilsWelcomeService.gethealthUnit().healthUnitName;
      this.idHealthUnit = this.utilsWelcomeService.gethealthUnit().idHealthUnit;
    }
  }


  getSyncMeasurerHash() {
    this.syncMeasurerHashService.getHash().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.utilsWelcomeService.updateHash(response.syncMeasurerHash);
      this.getIdHealthUnit(response.syncMeasurerHash);
    },
      (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  searchByCPF() {
    if (this.isLoading) {
      return;
    }
    if (this.model.invalid) {
      this.alertService.show('Erro', "Preencha o campo de CPF", AlertType.error);
      return;
    }

    let cpf: string = this.model.get('cpf').value.replace(/[^0-9]+/g, '');
    this.isLoading = true;

    let healthUnit = this.utilsWelcomeService.gethealthUnit();
    let jsonListIdHealthUnit = null;
    if(healthUnit.listIdHealthUnitInGroup)
      jsonListIdHealthUnit = JSON.stringify(healthUnit.listIdHealthUnitInGroup);

    this.getPatientByCpfService.getPatientByCPF(cpf, this.idHealthUnit, null, null, jsonListIdHealthUnit).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      // verificar se existe na syncpatientmeasurer e se tem 
      if (response.patient) {
        this.findBindWithEquipament(response.patient);
      } else {
        this.dindFindModal();
        this.isLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  findBindWithEquipament(patient: PatientStruct) {
    this.patientAnamnesisService.getQuestionAnswered(patient.idPatient, this.utilsWelcomeService.getHash()).subscribe((response) => {
      this.isLoading = false;
      this.openConfirmModal(patient, response.patientAnamnesis ? true : false);
    },
      (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getIdHealthUnit(hash: string) {
    this.syncMeasureService.getSyncMeasurer(hash).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.getHealthUnit(response.syncMeasurer.idHealthUnit);
      this.utilsWelcomeService.updateSyncMeasurer(response.syncMeasurer);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnit(idHealthUnit: number) {
    this.healthUnitService.getHealthUnit(idHealthUnit).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.healthUnitName = response.healthUnitStruct.healthUnitName;
      this.idHealthUnit = response.healthUnitStruct.idHealthUnit;
      this.utilsWelcomeService.updatehealthUnit(response.healthUnitStruct);
      this.isLoading = false;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  openConfirmModal(patient: PatientStruct, hasData: boolean) {

    const dialogRef = this.dialog.open(ConfirmPatientModalComponent, {
      data: { patient, hasData },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.review) {
        this.router.navigate(['/company/register', { cpf: this.model.get('cpf').value }]);
      } else if (result && result.confirm) {
        this.utilsWelcomeService.updatePatient(patient);
        this.router.navigate(['/company/measure']);
      } else if (result && result.isNotMe) {
        this.openIsNotMe();
      } else {
        this.model.get('cpf').setValue(null);
      }
    });
  }

  openIsNotMe() {
    const dialogRef = this.dialog.open(IsNotMeModalComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.model.get('cpf').setValue(null);
    });
  }

  dindFindModal() {
    const dialogRef = this.dialog.open(CPFNotFindedModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.register) {
        this.router.navigate(['/company/register', { cpf: this.model.get('cpf').value }]);
      }
    });
  }

}
