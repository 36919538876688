import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { OximeterService } from 'src/app/shared/services/API/interface-measurer/oximeter.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { MeasureStruct, Oximeter } from 'src/app/shared/services/structs/sync-measurer/measure.struct';
import { PagesEnum } from '../pages.enum';
import { ManualOximeterModalComponent } from './manual-oximeter-modal/manual-oximeter-modal.component';


@Component({
  selector: 'app-oximeter',
  templateUrl: './oximeter.component.html',
  styleUrls: ['./oximeter.component.css']
})
export class OximeterComponent implements OnInit, OnDestroy {

  constructor(private oximeterService: OximeterService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private utilsWelcomeService: UtilsWelcomeService) { }

  @Output("leaveMeasurer") leaveMeasurer: EventEmitter<any> = new EventEmitter();
  @Output("changePage") changePage: EventEmitter<any> = new EventEmitter();

  public measureWasMade: boolean = false;
  public heartRate: number;
  public oximeter: number;
  private measureStruct: MeasureStruct;
  private syncInterval: any;
  private isRequesting: boolean = false;

  public bloodPressure: number = PagesEnum.bloodPressure;

  ngOnInit(): void {
    let measure = this.utilsWelcomeService.getMeasureList();
    if (measure && measure[0]) {
      this.measureStruct = measure[0];
      if(measure[0].oximeter && measure[0].oximeter.heartRate && measure[0].oximeter.saturation){
        this.heartRate = measure[0].oximeter.heartRate;
        this.oximeter = measure[0].oximeter.saturation;
        this.measureWasMade = true;
      }
    } else {
      this.measureStruct = new MeasureStruct;
    }
    if(!this.measureStruct.oximeter) this.measureStruct.oximeter = new Oximeter;
    this.RestartOximeter();
  }

  callChangePage(page: number) {
    this.changePage.next(page);
  }

  openManualModal() {
    const dialogRef = this.dialog.open(ManualOximeterModalComponent , {
      panelClass: 'modal-manual-oximeter'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.heartRate = result.heartRate;
        this.oximeter = result.oximeter;
        this.measureStruct.oximeter.heartRate = this.heartRate;
        this.measureStruct.oximeter.saturation = this.oximeter;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }
    });
  }


  startOximeter() {
    this.oximeterService.start("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startInterval();
    }, () => {
      this.alertService.show('Erro', "Não foi possível iniciar o Oximetro", AlertType.error);
    });
  }

  RestartOximeter() {
    this.oximeterService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.startOximeter();
    }, () => {
      this.alertService.show('Erro', "Não foi possível reiniciar o Oximetro", AlertType.error);
    });
  }

  ngOnDestroy() {
    clearInterval(this.syncInterval);
    this.oximeterService.stop("x").subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
    });
  }

  readOximeter() {
    if (this.isRequesting) return;
    this.isRequesting = true;
    this.oximeterService.read('x').subscribe((response) => {
      this.isRequesting = false;
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      if (!response.running) {
        clearInterval(this.syncInterval);
        this.RestartOximeter();
      }

      if (response.measurer) {
        this.heartRate = parseInt(response.measurer.split("/")[1]);
        this.oximeter = parseInt(response.measurer.split("/")[0]);
        this.measureStruct.oximeter.heartRate = this.heartRate;
        this.measureStruct.oximeter.saturation = this.oximeter;
        this.utilsWelcomeService.storeMeasureList([this.measureStruct]);
        this.measureWasMade = true;
      }

    });
  }

  startInterval() {
    this.syncInterval = setInterval(() => { this.readOximeter() }, 1000);
  }
}
