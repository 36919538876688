<div class="row">
    <div class="col-12">
        <div class="close-button">
            <mat-icon mat-icon aria-hidden="false" aria-label="sound" (click)="closeModal()">close</mat-icon>
        </div>
    </div>
</div>

<div class="row modal-body-container">
    <div class="col-5">
        <img src="assets/images/end-img.png"/>
    </div>
    <div class="col-7">
        <div class="text-container">
            <div class="title">
                <p i18n>Hoje você cuidou da sua saúde!</p>
            </div>
            
            <div class="body-text">
                <p i18n>Conte com os profissionais de saúde da UBS para saber como manter esse cuidado.</p>
            </div>
        </div>
    </div>
</div>