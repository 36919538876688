<div class="container-out"></div>
<div class="mat-dialog-content">
        <div class="row">
            <div class="col-1">
                <div class="audio-icon">
                <mat-icon mat-icon aria-hidden="false" aria-label="Menu apps" (click)="playAudio()">volume_up</mat-icon>
                </div>
            </div>
            <div class="col-10">
            <span><p i18n class="protocol">Para continuar, você deverá informar seus dados cadastrais (CPF ou CNS, data de nascimento, sexo) e dados de
                saúde. Esses dados serão usados para avaliar riscos cardiovasculares e permitir que a equipe da UBS te ajude
                a cuidar da saúde.</p></span>
            <span><p i18n class="protocol">Os dados ficaram armazenados sob responsabilidade da DTIC/SMS/PMSP, de acordo com as diretrizes da Lei Geral
                de Proteção de Dados (Lei Federal 13.709/2018).</p></span>
            <span class="lgpd" (click)="openLgpdInfo()"><p i18n class="protocol">Para mais informações, clique aqui.</p></span>
            <span><p i18n class="protocol">Ao clicar em continuar, você consente em informar estes dados.</p></span>
        </div>
        <div class="col-1">
            <div class="close-button">
                <mat-icon mat-icon aria-hidden="false" aria-label="sound" (click)="closeModal()">close</mat-icon>
            </div>
        </div>
            <div class="footer-confirm-modal-list">
            <button mat-flat-button class="button-continue" (click)="continue()">
                <span i18n>CONTINUAR</span>
                <div>
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </button>
        </div>
    </div>
</div>