<div mat-dialog-content class="content">
    <div class="row">
        <div class="col-md-12 text-warn">
            <b>Procure o setor de Saúde e Segurança do trabalho para verificar seus dados de cadastro.</b>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button mat-flat-button type="submit" color="primary" class=" btn-block" (click)="clickClose()">
                <span>Ok</span>
            </button>
        </div>
    </div>
</div>