import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { LeaveConfirmationModalComponent } from './leave-confirmation/leave-confirmation-modal.component';
import { PagesEnum } from './pages.enum';


@Component({
  selector: 'app-measurer',
  templateUrl: './measurer.component.html',
  styleUrls: ['./measurer.component.css']
})
export class MeasurerComponent implements OnInit {

  constructor(private utilsWelcomeService: UtilsWelcomeService, private router: Router, private dialog: MatDialog,) { }
  public healthUnitName: string;

  public page: number = PagesEnum.oximeter;
  public oximeter: number = PagesEnum.oximeter;
  public bloodPressure: number = PagesEnum.bloodPressure;
  public thermometer: number = PagesEnum.thermometer;
  public glucometer: number = PagesEnum.glucometer;
  public finalizationScreen: number = PagesEnum.finalizationScreen;
  ngOnInit(): void {
    var unit = this.utilsWelcomeService.gethealthUnit();
    var patient = this.utilsWelcomeService.getPatient();
    this.utilsWelcomeService.storeMeasureList([]);
    if(!unit || !patient){
      this.router.navigate(['/company/login']);
    }

    this.healthUnitName = unit.healthUnitName;
  }


  leaveMeasurer() {
    const dialogRef = this.dialog.open(LeaveConfirmationModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.leave) {
        this.router.navigate(['/company/login']);
      }
    });
  }

  changePage(page: number) {
    console.log(page);
    this.page = page;
  }
}
