import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UbsLgpdProtocolModalComponent } from '../ubs-lgpd-protocol-modal/ubs-lgpd-protocol-modal.component';

@Component({
  selector: 'app-ubs-lgpd-info-modal',
  templateUrl: './ubs-lgpd-info-modal.component.html',
  styleUrls: ['./ubs-lgpd-info-modal.component.css']
})

export class UbsLgpdInfoModalComponent implements OnInit {

  public audio = new Audio();

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UbsLgpdInfoModalComponent>,
    public matDialogRefProtocol: MatDialogRef<UbsLgpdProtocolModalComponent>,
  ) { }

  ngOnDestroy() {
    this.audio.pause();
  }
  
  ngOnInit(): void {

  }
  
  playAudio() {
    this.audio.src = "/assets/audio/pt-BR/lgpd/lgpd-details.mp3"
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  continue() {
    this.audio.pause();
    this.matDialogRef.close({ lgpd: true });
  }

  closeModal() {
    this.audio.pause();
    this.matDialogRef.close({ lgpd: false });
  }
}