import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebhookTypeEnum } from 'src/app/shared/enum/webhook-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { WebhookService } from 'src/app/shared/services/API/integration/webhook.service';
import { UBSHealthPromotionService } from 'src/app/shared/services/API/ubs-health-promotion/ubs.service';
import { GetWebhookResponse } from 'src/app/shared/services/responses/integration/get-webhook.response';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { WebhookStruct } from 'src/app/shared/services/structs/Integration/webhook-struct';
import { environment } from 'src/environments/environment';
import { UBSConfigData, UtilsUBSService } from '../utils-ubs.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {

  constructor(public activatedRoute: ActivatedRoute,
    private uBSHealthPromotionService: UBSHealthPromotionService,
    private alertService: AlertService,
    private router: Router,
    private healthUnitService: HealthUnitService,
    private utilsUBSService: UtilsUBSService,
    private webhookService: WebhookService,
  ) { }

  public idHealthUnit: number;
  onlineOffline: boolean = navigator.onLine;
  public checkedUri: boolean = false;
  public checkedLoading: boolean = false;
  public uri: string;
  public healthUnitToken: string;
  public ubsData: UBSConfigData;
  public webhooks: WebhookStruct[];

  ngOnInit(): void {
    this.ubsData = this.utilsUBSService.getUBSData();
    this.healthUnitToken = this.activatedRoute.snapshot.paramMap.get('healthUnitToken');

    if (this.ubsData && this.ubsData != null && this.ubsData.listUBSStruct.length > 0) {
      let urlBase = `${environment.urlBaseUIDevice}ubs/preview/${this.healthUnitToken}`
      this.utilsUBSService.createBaseUrl(urlBase);
      this.router.navigate(["ubs" + this.ubsData.listUBSStruct[0].screenUrl]);
    }
    else
      this.configClient(this.healthUnitToken);
  }

  configClient(healthUnitToken: string) {
    this.ubsData = new UBSConfigData();
    this.uBSHealthPromotionService.getUBSHealthPromotion(healthUnitToken).subscribe((response) => {
      if (response.isError) {
        this.checkedLoading = true;
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.ubsData.listUBSStruct = response.listUBSStruct;
      this.idHealthUnit = response.uBSConfigStruct.idHealthUnit;
      this.ubsData.idHealthUnit = this.idHealthUnit;
      this.ubsData.ipDevice = response.uBSConfigStruct.ipDevice;
      this.ubsData.isClientTest = response.uBSConfigStruct.isClientTest;
      let urlBase = `${environment.urlBaseUIDevice}ubs/preview/${this.healthUnitToken}`
      this.utilsUBSService.createBaseUrl(urlBase);

      this.getWebhook();
      this.getHealthUnit(this.idHealthUnit)
    },
      (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnit(idHealthUnit: number) {
    this.healthUnitService.getHealthUnit(idHealthUnit).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.utilsUBSService.updatehealthUnit(response.healthUnitStruct);
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  ngOnDestroy(): void {
  }

  getWebhook() {
    this.webhookService.getWebhook(this.idHealthUnit, WebhookTypeEnum.uBSBuscarpaciente).subscribe((response) => {
      if (response.isError) {
        this.checkedLoading = true;
        this.utilsUBSService.removeUBSData();
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.utilsUBSService.createUBSData(this.ubsData);
      if (response.webhook && response.webhook.length > 0) {
        this.webhooks = response.webhook;
        this.utilsUBSService.createWebhook(this.webhooks[0]);
      }
      this.router.navigate(["ubs" + this.ubsData.listUBSStruct[0].screenUrl]);
      this.checkedLoading = true;
    },
      (error) => {
        this.checkedLoading = true;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }
}
