<div class="main-body ubs">

    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="container-body">
        <h1>
            <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                (click)="playSound()" />
            <b i18n="@@titleRiskFactors">MARQUE OS PROBLEMAS DE SAÚDE QUE VOCÊ TIVER</b>
        </h1>
        <div class="coluns-risks">
            <div class="checkbox-group">
                <div class="checkbox-align" (click)="changeHighPressure()">
                    <img *ngIf="!this.riskFactorsData.haveHighPressure" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveHighPressure" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@highPressureRiskFactors">Pressão alta (hipertensão)</span>
                    </div>
                </div>
                <div class="checkbox-align" (click)="changeDiabetes()">
                    <img *ngIf="!this.riskFactorsData.haveDiabetes" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveDiabetes" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@diabetesRiskFactors">Diabetes</span></div>
                </div>
                <div class="checkbox-align" (click)="changeHighCholesterol()">
                    <img *ngIf="!this.riskFactorsData.haveHighCholesterol" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveHighCholesterol" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@highCholesterolRiskFactors">Colesterol alto</span></div>
                </div>
                <div class="checkbox-align" (click)="changeOverweightObesity()">
                    <img *ngIf="!this.riskFactorsData.haveOverweightObesity" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveOverweightObesity" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@overweightObesityRiskFactors">Sobrepeso/Obesidade</span>
                    </div>
                </div>
            </div>
            <div class="checkbox-group">
                <div class="checkbox-align"
                    [ngClass]="{'not-visible': !this.riskFactorsData.haveHighPressure, 'is-visible': this.riskFactorsData.haveHighPressure }"
                    (click)="changePressureRemedy()">
                    <img *ngIf="!this.riskFactorsData.havePressureRemedy" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.havePressureRemedy" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@pressureRemedyRiskFactors">Eu tomo remédio para
                            pressão</span>
                    </div>
                </div>
                <div class="checkbox-align"
                    [ngClass]="{'not-visible': !this.riskFactorsData.haveDiabetes, 'is-visible': this.riskFactorsData.haveDiabetes}"
                    (click)="changeTreatedDiabetes()">
                    <img *ngIf="!this.riskFactorsData.haveTreatedDiabetes" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveTreatedDiabetes" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@diabetesRiskFactors">Eu faço tratamento para
                            diabetes</span>
                    </div>
                </div>
                <div class="checkbox-align"
                    [ngClass]="{'not-visible': !this.riskFactorsData.haveHighCholesterol, 'is-visible': this.riskFactorsData.haveHighCholesterol}"
                    (click)="changeTakeMedicineCholesterol()">
                    <img *ngIf="!this.riskFactorsData.haveTakeMedicineCholesterol" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveTakeMedicineCholesterol" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@highCholesterolRiskFactors">Eu tomo remédio para
                            colesterol</span></div>
                </div>
                <div class="checkbox-align"
                    [ngClass]="{'not-visible': !this.riskFactorsData.haveOverweightObesity, 'is-visible': this.riskFactorsData.haveOverweightObesity}"
                    (click)="changeTrackWeighWithProfessional()">
                    <img *ngIf="!this.riskFactorsData.haveTrackWeighWithProfessional" src="assets/images/nocheck.png">
                    <img *ngIf="this.riskFactorsData.haveTrackWeighWithProfessional" src="assets/images/checked.png">
                    <div class="margin-checkbox"><span i18n="@@overweightObesityRiskFactors">Eu acompanho meu peso com
                            profissional de saúde</span></div>
                </div>
            </div>
        </div>
        <div class="button-container">
            <button mat-flat-button class="block btn-back" (click)="clickBack()">
                <div>
                    <mat-icon class="arrow-icon" aria-hidden="false">arrow_back</mat-icon>
                </div>
                <span *ngIf="isLoading == false" i18n="@@backRiskFactors">Voltar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
            <button mat-flat-button class="block btn-go btn-ubs" (click)="clickGo()">
                <span *ngIf="isLoading == false" i18n="@@goRiskFactors">Avançar</span>
                <div>
                    <mat-icon aria-hidden="false">arrow_forward</mat-icon>
                </div>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>