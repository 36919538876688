import { Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-journey-page',
  templateUrl: './journey-page.component.html',
  styleUrls: ['./journey-page.component.css']
})
export class JourneyPageComponent implements OnInit, OnDestroy {
  title = 'tolifecloud-ui-device'; //olhar como passar esse titulo

  constructor(
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    public router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    @Inject(LOCALE_ID) private locale: string

  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  private thisScreen: UbsScreenEnum = UbsScreenEnum.Journey;
  public isError = false;
  public audio = new Audio();
  private ubsData: UBSConfigData;
  public downtime: boolean;

  ngOnInit(): void {
    console.log(this.router.url)
    this.ubsData = this.utilsUBSService.getUBSData();
    if (!this.ubsData || !this.ubsData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  playSound(choice: string) {
    if (choice == "first")
      this.audio.src = `/assets/audio/${environment.locale}/journey/journey-screen-1.mp3`

    if (choice == "second")
      this.audio.src = `/assets/audio/${environment.locale}/journey/journey-screen-2.mp3`

    this.audio.load();
    this.audio.play();
  }

  clickGo() {
    let screen = this.ubsData.listUBSStruct.findIndex(x => x.idUBSScreen == this.thisScreen.valueOf());
    this.audio.pause();
    this.downtimeService.stopTimer();
    if (screen == -1)
      this.router.navigate(['/ubs/erro']);

    let nextScreen = this.ubsData.listUBSStruct[screen + 1];
    if (!nextScreen)
      this.router.navigate(['/ubs/erro']);

    this.saveLastPage();
    this.router.navigate(['/ubs' + nextScreen.screenUrl]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}