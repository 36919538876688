import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { VerifyDate } from 'src/app/shared/custom-validators/date.validator';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-birth-date-register-identifier',
  templateUrl: './birth-date-register-identifier.component.html',
  styleUrls: ['./birth-date-register-identifier.component.css']
})
export class BirthDateRegisterIdentifierComponent implements OnInit, OnDestroy {

  private title: string = 'birth-date-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) {
      this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;  
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public downtime: boolean;

  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.router.navigate(['/ubs/erro']);
    }
    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.BirthDateRegister;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1) {
      this.router.navigate(['/ubs/erro']);
    }
    
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      birthDate: ['', [Validators.required, VerifyDate()]],
    });

    this.patientData = this.utilsUBSService.getPatientData();
    if(this.patientData){
      let newDate = new Date (this.patientData.birthDate).toLocaleString(this.locale).split(' ')[0];
      this.model.get('birthDate').setValue(newDate);
    }

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
    
    this.isLoading = false;
  }

    
  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid) {
      return;
    }
    let birthDate = this.model.get('birthDate').value;
    let day = birthDate.substring(0, 2); 
    let month = birthDate.substring(2, 4) - 1; // because months in JS start from 0 
    let year = birthDate.substring(4, 8); 
    this.patientData.birthDate = new Date(year, month, day);

    this.utilsUBSService.createPatientData(this.patientData);

    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate([`ubs/mother-complete-name-register`]);
  }

  playAudio(){
    this.audio.src = `../assets/audio/${this.locale}/birth-date-01.mp3`;
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  goBack(){
    this.audio.pause();
    this.downtimeService.stopTimer();

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);

    this.router.navigate([lastPage]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}