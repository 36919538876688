export class PostUbsHealthPromotionSmsEmailRequest{
    public phone: string;
    public email: string;
    public imc: number;
    public degreeImc: string;
    public referenceImc: string;
    public systolicPressure: number;
    public diastolicPressure: number;
    public degreePressure: string;
    public referencePressure: string;
    public counselingTitle: string;
    public counseling: string;
    public timeZoneValue: number;
    public counselingDescription: string;
    public dateConsulting: Date;
    public imcCathegory: number;
    public paCathegory: number;
}