<div class="white-body">
    <div *ngIf="!checkedLoading" class="page-loading">
        <div class="c-loader"></div>
        <h1>Carregando...</h1>
    </div>
    <div *ngIf="!checkedUri && checkedLoading" class="page-not-found">
        <h1>
            <mat-icon mat-icon class="icon-page" aria-hidden="false" aria-label="web_asset_off">web_asset_off</mat-icon>
            Página não encontrada
        </h1>
    </div>
</div>