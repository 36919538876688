export class UBSCreatePatientRequest {
    public name: string;
    public motherName: string;
    public cpf: string;
    public cns: string;
    public birthDate: Date;
    public idGender: number;
    public optIn: boolean;
    public url: string;
    public token: string;
}