import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientData, UtilsUBSService } from 'src/app/ubs-health-promotion/utils-ubs.service';
import { UbsLgpdInfoModalComponent } from '../ubs-lgpd-info-modal/ubs-lgpd-info-modal.component';

@Component({
  selector: 'app-ubs-lgpd-protocol-modal',
  templateUrl: './ubs-lgpd-protocol-modal.component.html',
  styleUrls: ['./ubs-lgpd-protocol-modal.component.css']
})
export class UbsLgpdProtocolModalComponent implements OnInit, OnDestroy {

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<UbsLgpdProtocolModalComponent>,
    public utilsUBSService: UtilsUBSService,
  ) { }

  audio = new Audio();
  private patientData: PatientData = new PatientData();

  ngOnInit(): void {
    this.patientData = this.utilsUBSService.getPatientData();
  }

  continue() {
    this.patientData.optIn = true;
    this.utilsUBSService.createPatientData(this.patientData);
    this.matDialogRef.close({ lgpd: true });
  }

  playAudio() {
    this.audio.src = "/assets/audio/pt-BR/lgpdProtocol.mp3"
    this.audio.load();
    this.audio.play();
    this.audio.remove();
  }

  openLgpdInfo() {
    this.audio.pause();
    const dialogRef = this.dialog.open(UbsLgpdInfoModalComponent, {
      panelClass: 'modal-lgpd'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.lgpd == true) {
        this.patientData.optIn = true;
        this.utilsUBSService.createPatientData(this.patientData);
        this.matDialogRef.close({ lgpd: true });
      }
      else {

      }
    })
  }

  closeModal() {
    this.audio.pause();
    this.matDialogRef.close({ lgpd: false });
  }

  ngOnDestroy() {
    this.audio.pause();
  }
}