<div mat-dialog-container class="mat-dialog-content">
    <div class="close-button">
        <mat-icon mat-icon aria-hidden="false" aria-label="fechar" (click)="finishSession()">close</mat-icon>
    </div>
    <div class="no-identifier-content">
        <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
        <p *ngIf="tryAgain" i18n>
            A opção selecionada não coincide com os dados cadastrados em nosso sistema! Por favor, <strong>procure a recepção
                da UBS</strong>.</p>
        <p *ngIf="!tryAgain" i18n>
            Seus dados estão desatualizados! Por favor, <strong>procure a recepção
                da UBS</strong>
            para saber como atualizar suas informações.</p>
    </div>
    <div class="footer-confirm-modal-list">
        <button mat-flat-button *ngIf="!tryAgain" class="btn-finish-ok" (click)="finishSession()">
            <div>
                <mat-icon class="span-icon" >arrow_back</mat-icon>
            </div>
            <span i18n>OK</span>
        </button>

        <button mat-flat-button *ngIf="tryAgain" class="btn-finish" (click)="finishSession()">
            <div>
                <mat-icon class="span-icon" >arrow_back</mat-icon>
            </div>
            <span i18n>Finalizar</span>
        </button>

        <button mat-flat-button *ngIf="tryAgain" class="btn-try-again" (click)="closeModal()">
            <span i18n>Tentar novamente</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>