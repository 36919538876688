import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UbsLgpdProtocolModalComponent } from 'src/app/shared/components/ubs-lgpd-protocol-modal/ubs-lgpd-protocol-modal.component';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientData, UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';
import { UBSCreatePatientRequest } from 'src/app/shared/services/requests/orchestrator-integration/ubs-create-patient.request';
import { WebhookService } from 'src/app/shared/services/API/integration/webhook.service';
import { HealthUnitStruct } from 'src/app/shared/services/structs/user/health-unit.struct';
import { WebhookTypeEnum } from 'src/app/shared/enum/webhook-type.enum';
import { WebhookStruct } from 'src/app/shared/services/structs/Integration/webhook-struct';
import { UBSWebhookService } from 'src/app/shared/services/API/orchestrator-integration/ubs-webhook.service';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';
import { InfosModalComponent } from './infos-modal/infos-modal.component';
import { UBSGenderEnum } from 'src/app/shared/enum/ubs-gender.enum';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patiet-by-cpf.service';

@Component({
  selector: 'app-gender-select',
  templateUrl: './gender-select.component.html',
  styleUrls: ['./gender-select.component.css']
})
export class GenderSelectComponent implements OnInit, OnDestroy {

  private title: string = 'birth-date-ubs-health-promotion';
  private patientData: PatientData = new PatientData();

  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private webhookService: WebhookService,
    private router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    private downtimeService: DowntimeService,
    public dialog: MatDialog,
    public uBSWebhookService: UBSWebhookService,
    private getPatientByCpfService: GetPatientByCpfService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  private idUbsScreen: number;
  public pageIndex: number;
  public idHealthUnit: number;
  public webhook: WebhookStruct[];
  public ubsCreate: UBSCreatePatientRequest = new UBSCreatePatientRequest();
  public downtime: boolean;
  public maleCisEnum = UBSGenderEnum.maleCis;
  public famaleCisEnum = UBSGenderEnum.famaleCis;
  public maleTransEnum = UBSGenderEnum.maleTrans;
  public femaleTransEnum = UBSGenderEnum.femaleTrans;
  public travestiEnum = UBSGenderEnum.travesti;
  public nonBinaryEnum = UBSGenderEnum.nonBinary;
  public notInformedEnum = UBSGenderEnum.notInformed;


  ngOnInit(): void {
    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct)
      this.router.navigate(['/ubs/erro']);

    this.journeyStageId = JourneyStageEnum.Identificacao;
    this.idUbsScreen = UbsScreenEnum.GenderSelect;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1)
      this.router.navigate(['/ubs/erro']);

    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      gender: ['', [Validators.required]],
    });

    if (this.utilsUBSService.getPatientData()) {
      this.patientData = this.utilsUBSService.getPatientData();

      if (this.patientData.idGender != null) {
        this.model.get('gender').setValue(this.patientData.idGender);
      }
    }

    this.isLoading = false;
    this.getWebhook();

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    if (!this.model.valid)
      return;

    this.audio.pause();
    let gender = parseInt(this.model.get('gender').value);
    this.patientData.idGender = gender;
    this.utilsUBSService.createPatientData(this.patientData);

    this.downtimeService.stopTimer();
    this.mapToCreatePatientUBS();
  }

  playAudio() {
    this.audio.src = `../assets/audio/${environment.locale}/gender-select.mp3`;
    this.audio.load();
    this.audio.play();
  }

  openModalInfo() {
    const dialogRef = this.dialog.open(InfosModalComponent, {
      disableClose: true,
      panelClass: 'modal-infos-gender',
      backdropClass: 'backdrop-infos'
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  goBack() {
    this.downtimeService.stopTimer();
    this.audio.pause();

    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    this.router.navigate([lastPage]);

    // (this.patientData.existsInBaseData)
    //   ? this.router.navigate([lastPage])
    //   : this.router.navigate([`ubs/birth-date-register`]);
  }

  mapToCreatePatientUBS() {
    this.patientData = this.utilsUBSService.getPatientData();
    if (this.patientData != null) {
      if (this.patientData.cpf != null) {
        this.ubsCreate.cpf = this.patientData.cpf;
        this.ubsCreate.name = this.patientData.name;
        this.ubsCreate.motherName = this.patientData.motherName;
      }
      else if (this.patientData.cns != null) {
        this.ubsCreate.cns = this.patientData.cns;
        this.ubsCreate.name = this.patientData.name;
        this.ubsCreate.motherName = this.patientData.motherName;
      }
      else {
        this.ubsCreate.name = this.patientData.name;
        this.ubsCreate.motherName = this.patientData.motherName;
      }
      this.ubsCreate.birthDate = this.patientData.birthDate;
      this.ubsCreate.idGender = this.patientData.idGender;
    }


    if (!this.patientData.existsInBaseData && this.webhook[0]) {
      this.createPatientUBS();
    }
    else if (!this.patientData.existsInBaseData) {
      this.createPatientMedicalRecord();
    }

    this.downtimeService.stopTimer();
    this.saveLastPage();

    this.router.navigate([`ubs${this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl}`]);
  }

  createPatientUBS() {
    this.uBSWebhookService.createPatient(this.ubsCreate, this.webhook[0]).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.patientData.idPatient = response.idPatient;
      this.utilsUBSService.createPatientData(this.patientData);
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  createPatientMedicalRecord() {

    this.getPatientByCpfService.CreatePatient(this.utilsUBSService.getHealthUnit().idHealthUnit, this.ubsCreate).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.patientData.idPatient = response.idPatient;
      this.utilsUBSService.createPatientData(this.patientData);
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getWebhook() {
    let healthUnit: HealthUnitStruct;
    healthUnit = this.utilsUBSService.getHealthUnit();
    this.webhookService.getWebhook(healthUnit.idHealthUnit, WebhookTypeEnum.uBSCriarPaciente).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      this.webhook = response.webhook;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}