<header>
    <img src="assets/images/logo-branco.png" />
    <span class="title-header-company">{{healthUnitName}}</span>
</header>
<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <app-oximeter *ngIf="page == oximeter" (changePage)="changePage($event)" (leaveMeasurer)="leaveMeasurer()">
            </app-oximeter>
            <app-blood-pressure *ngIf="page == bloodPressure" (changePage)="changePage($event)"
                (leaveMeasurer)="leaveMeasurer()"></app-blood-pressure>
            <app-thermometer *ngIf="page == thermometer" (changePage)="changePage($event)" (leaveMeasurer)="leaveMeasurer()">
            </app-thermometer>
            <app-glucometer *ngIf="page == glucometer" (changePage)="changePage($event)" (leaveMeasurer)="leaveMeasurer()">
            </app-glucometer>
            <app-finalization-screen *ngIf="page == finalizationScreen" (changePage)="changePage($event)">
            </app-finalization-screen>
            
        </div>
    </div>
</div>