import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DowntimeService {

  idleDetector: Subject<boolean>;
  constructor() { this.idleDetector = new Subject(); }

  private isSessionExpired = false;
  private timer = null;
  private timeInSeconds = 0;

  watcher() {
    return this.idleDetector.asObservable();
  }

  startTimer(timeInSeconds: number) {
    this.timeInSeconds = timeInSeconds;

    this.timer = setTimeout(() => {
      this.isSessionExpired = true;
      this.idleDetector.next(this.isSessionExpired);
    }, this.timeInSeconds);
  }

  resetTimer() {
    if (!this.isSessionExpired) {

      this.isSessionExpired = false;
      this.stopTimer();
      this.startTimer(this.timeInSeconds);
    }
  }

  stopTimer() {
    clearTimeout(this.timer);
  }
}