<div class="white-box">
    <h1>Tudo certo, já enviamos suas medições para serem analisadas!</h1>
    <div class="row">
        <div class="col-md-6">
            <img class="img-fc" src="assets/images/frequencia-cardiaca.jpg" />
        </div>
        <div class="col-md-6">
            <img class="img-bp" src="assets/images/blood-pressure.jpg" />
        </div>
        <div class="col-md-6">
            <img class="img-t" src="assets/images/Temperatura.jpg" />
        </div>
        <div class="col-md-6">
            <img class="img-g" src="assets/images/Glicose.jpg" />
        </div>
        <div class="col-md-12">
            <h3>Não se esqueça de passar aqui amanhã novamente! Sua saúde também é nossa prioridade.</h3>
        </div>
        <div class="col-md-12">
            <h3>Tenha um ótimo dia...</h3>
        </div>
        <div *ngIf="hadError" class="error-signal col-md-12">
            <p>*Houve um erro ao tentar fazer o envio das suas medidas, se deseja que seja feito uma nova tentativa
                clique no botão abaixo!</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <button *ngIf="wasSended && !hadError" (click)="leaveMeasurer()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Finalizar</span>
            </button>
            <button *ngIf="wasSended && hadError" (click)="leaveMeasurer()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Sair</span>
            </button>
        </div>
        <div class="col-md-4"></div>
        <div *ngIf="hadError" class="col-md-4">
            <button (click)="setActivePatient()" mat-flat-button type="button" color="primary" class=" btn-block">
                <span>Tentar novamente</span>
            </button>
        </div>
    </div>
</div>