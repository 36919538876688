import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { PatientService } from 'src/app/shared/services/API/medical-record/patient.service';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { PatientDeviceRequest } from 'src/app/shared/services/requests/medical-record/patient-device.request';
import { ViaCepService } from 'src/app/shared/services/API/integration/via-cep.service';
import { ClockService } from 'src/app/shared/services/API/orchestrator-queue/clock.service';
import { GetPatientByCpfService } from 'src/app/shared/services/API/medical-record/get-patiet-by-cpf.service';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';
import { SyncMeasurerPatientService } from 'src/app/shared/services/API/sync-measurer/sync-measurer-patient.service';
import { PatientSyncMeasurerStruct } from 'src/app/shared/services/structs/sync-measurer/patient-sync-measurer.struct';
import { ConfirmExitModalComponent } from './confirm-exit-modal/confirm-exit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Gender } from 'src/app/shared/services/responses/medical-record/lookup.response';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private utilsWelcomeService: UtilsWelcomeService,
    private getPatientByCpfService: GetPatientByCpfService,
    private lookupService: LookupService,
    private alertService: AlertService,
    public activatedRoute: ActivatedRoute,
    private maskService: MaskService,
    public clockService: ClockService,
    public syncMeasurerPatientService: SyncMeasurerPatientService,
    public dialog: MatDialog,
    public router: Router,
    public viaCepService: ViaCepService
  ) { }

  public model: FormGroup;
  public isLoading: boolean;
  public isFirstLoading: boolean;
  public isLoadingCep: boolean;
  public isLoadingCpf: boolean;
  public patientAlreadyExists: boolean = false;
  public datetimeStartRegister: Date;
  public idPatientToNext: number;

  public genderList: Gender[];
  public cpf?: string;
  public masks: Masks;
  public healthUnitName: string = "";
  public requiredFields: string[];
  public patientAge: string = null;
  public idHealthUnit: number;
  public idSyncMeasurer: number;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isLoading = true;
    this.isFirstLoading = true;

    this.idSyncMeasurer = this.utilsWelcomeService.getSyncMeasurer().idSyncMeasurer;
    this.healthUnitName = this.utilsWelcomeService.gethealthUnit().healthUnitName;
    this.model = this.formBuilder.group({
      patientName: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      idGender: [''],
      phone1: [''],
      zipCode: [''],
      street: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      apartmentNumber: [''],
      unitBranch: [''],
      sectorEmployee: ['', [Validators.required]],
      roleEmployee: ['', [Validators.required]],
      registryEmployee: ['', [Validators.required]],
      idPatient: [null],
      idPatientEmployee: [null],
    });
    this.populateGenderSelect();
    if (this.activatedRoute.snapshot.paramMap.get('cpf')) {
      this.model.get('cpf').setValue(this.activatedRoute.snapshot.paramMap.get('cpf'));
      this.searchByCPF(this.model.get('cpf').value.replace(/[^0-9]+/g, ''));
    }
  }


  submit() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    let patient = new PatientDeviceRequest();
    patient.idPatient = this.model.get('idPatient').value;
    patient.idPatientEmployee = this.model.get('idPatientEmployee').value;
    patient.patientName = this.model.get('patientName').value;
    patient.cpf = this.model.get('cpf').value ? this.model.get('cpf').value.replace(/[^0-9]+/g, '') : null;
    patient.birthDate = this.formatDateBack(this.model.get('birthDate').value);
    patient.idGender = this.model.get('idGender').value;
    patient.phone1 = this.model.get('phone1').value;
    patient.zipCode = this.model.get('zipCode').value;
    patient.street = this.model.get('street').value;
    patient.neighborhood = this.model.get('neighborhood').value;
    patient.city = this.model.get('city').value;
    patient.state = this.model.get('state').value;
    patient.country = this.model.get('country').value;
    patient.houseNumber = this.model.get('houseNumber').value;
    patient.apartmentNumber = this.model.get('apartmentNumber').value;
    patient.unitBranch = this.model.get('unitBranch').value;
    patient.sectorEmployee = this.model.get('sectorEmployee').value;
    patient.roleEmployee = this.model.get('roleEmployee').value;
    patient.registryEmployee = this.model.get('registryEmployee').value;
    patient.idHealthUnitDevice = this.utilsWelcomeService.gethealthUnit().idHealthUnit;

    for (let item of Object.keys(patient)) {
      patient[item] = patient[item] ? patient[item] : null;
    }

    this.patientService.savePatient(patient).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.verifyAndGoToAnamnese(response.idPatient);
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  mapModel(patient: PatientStruct) {
    if (patient.idPatient) { this.model.get('idPatient').setValue(patient.idPatient); }
    if (patient.idPatientEmployee) { this.model.get('idPatientEmployee').setValue(patient.idPatientEmployee); }
    this.model.get('patientName').setValue(patient.patientName);
    this.model.get('cpf').setValue(patient.cpf);
    this.model.get('birthDate').setValue(this.dataAtualFormatada(patient.birthDate));
    this.model.get('idGender').setValue(patient.idGender ? patient.idGender.toString() : null);
    this.model.get('phone1').setValue(patient.phone1);
    this.model.get('zipCode').setValue(patient.zipCode);
    this.model.get('street').setValue(patient.street);
    this.model.get('neighborhood').setValue(patient.neighborhood);
    this.model.get('city').setValue(patient.city);
    this.model.get('state').setValue(patient.state);
    this.model.get('country').setValue(patient.country);
    this.model.get('houseNumber').setValue(patient.houseNumber);
    this.model.get('apartmentNumber').setValue(patient.apartmentNumber);
    this.model.get('unitBranch').setValue(patient.unitBranch ? patient.unitBranch.toString() : null);
    this.model.get('sectorEmployee').setValue(patient.sectorEmployee ? patient.sectorEmployee.toString() : null);
    this.model.get('roleEmployee').setValue(patient.roleEmployee ? patient.roleEmployee.toString() : null);
    this.model.get('registryEmployee').setValue(patient.registryEmployee ? patient.registryEmployee.toString() : null);

    if (this.model.get('birthDate').value) this.getAge(this.model.get('birthDate').value);
  }

  clearModel() {
    this.model.get('idPatient').setValue(null);
    this.model.get('idPatientEmployee').setValue(null);
    this.model.get('patientName').setValue(null);
    this.model.get('birthDate').setValue(null);
    this.model.get('idGender').setValue(null);
    this.model.get('phone1').setValue(null);
    this.model.get('zipCode').setValue(null);
    this.model.get('street').setValue(null);
    this.model.get('neighborhood').setValue(null);
    this.model.get('city').setValue(null);
    this.model.get('state').setValue(null);
    this.model.get('country').setValue(null);
    this.model.get('houseNumber').setValue(null);
    this.model.get('apartmentNumber').setValue(null);
    this.model.get('unitBranch').setValue(null);
    this.model.get('sectorEmployee').setValue(null);
    this.model.get('roleEmployee').setValue(null);
    this.model.get('registryEmployee').setValue(null);
  }

  verifyAndGoToAnamnese(idPatientToNext: number) {
    let ids: number[] = [];
    ids.push(idPatientToNext);
    this.patientService.getPatient(ids).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      if (response.listPatient[0]) {
        this.utilsWelcomeService.updatePatient(response.listPatient[0]);
        this.patientSyncMeasurer();
        this.goToAnamnese();
      };
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  patientSyncMeasurer() {
    this.syncMeasurerPatientService.patientSyncMeasurer(this.idSyncMeasurer, this.utilsWelcomeService.getPatient().idPatient).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return false;
      }
      if (response.patientSyncMeasurer) {
        this.utilsWelcomeService.updateIdPatientSyncMeasurer(response.patientSyncMeasurer.idPatientSyncMeasurer);
        return true;
      }
      else {
        let patientSyncMeasurerRequest = new PatientSyncMeasurerStruct();
        patientSyncMeasurerRequest.idSyncMeasurer = this.idSyncMeasurer;
        patientSyncMeasurerRequest.idPatient = this.utilsWelcomeService.getPatient().idPatient;
        patientSyncMeasurerRequest.isDeleted = false;

        this.syncMeasurerPatientService.CreatePatientSyncMeasurer(patientSyncMeasurerRequest).subscribe((response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            this.isLoading = false;
            return false;
          }
          if (response.patientSyncMeasurer) {
            this.utilsWelcomeService.updateIdPatientSyncMeasurer(response.patientSyncMeasurer.idPatientSyncMeasurer);
            return true;
          };
        },
          (error) => {
            this.isLoading = false;
            this.alertService.show('Erro inesperado', error, AlertType.error);
            return false;
          });
      }
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return false;
      });
    return false;
  }

  goToAnamnese() {

    this.router.navigate(['/company/anamnese']);

  }

  exit() {
    const dialogRef = this.dialog.open(ConfirmExitModalComponent, {
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/company/login']);
      }
    });
  }


  populateGenderSelect() {
    this.lookupService.getGenders().subscribe((response) => {

      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.genderList = response.listGenders;
    },
      (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getDateTimeStart() {
    this.clockService.getDate().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }
      this.datetimeStartRegister = response.date;
    },
      (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  dataAtualFormatada(dateToFormat: Date) {
    if (dateToFormat) {
      var data = new Date(dateToFormat),
        dia = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0' + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0' + mes : mes,
        anoF = data.getFullYear();

      return diaF + "/" + mesF + "/" + anoF;
    }
    return null;
  }

  formatDateBack(dateUnformatted: string) {
    if (dateUnformatted) {
      let darr = dateUnformatted.split("/");    // ["29", "1", "2016"]
      let dobj = new Date(parseInt(darr[2]), parseInt(darr[1]) - 1, parseInt(darr[0]));
      return dobj;
    }
    return null;
  }

  searchByCPF(cpf?: string) {
    if (!this.idHealthUnit) { this.idHealthUnit = this.utilsWelcomeService.gethealthUnit().idHealthUnit; }
    this.isLoading = true;
    this.isLoadingCpf = true;
    this.isLoading = true;
    let searchCpf: string;
    if (cpf) { searchCpf = cpf }
    else { searchCpf = this.cpf };

    let healthUnit = this.utilsWelcomeService.gethealthUnit();
    let jsonListIdHealthUnit = null;
    if(healthUnit.listIdHealthUnitInGroup)
      jsonListIdHealthUnit = JSON.stringify(healthUnit.listIdHealthUnitInGroup);

    this.getPatientByCpfService.getPatientByCPF(searchCpf, this.idHealthUnit, null, null, jsonListIdHealthUnit).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        this.isLoadingCpf = false;
        this.clearModel();
        return;
      }
      if (response.patient) {
        if (response.patient.idPatient) {
          this.mapModel(response.patient);
        }
      }
      else {
        this.clearModel();
      }
      this.isLoading = false;
      this.isLoadingCpf = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  searchByCEP(cep: string) {
    this.isLoadingCep = true;
    this.isLoading = true;
    this.viaCepService.getAddressByCep(cep).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoadingCep = false;
        this.isLoading = false;
        return;
      }
      this.model.get('zipCode').setValue(response.cep);
      this.model.get('street').setValue(response.logradouro);
      this.model.get('neighborhood').setValue(response.bairro);
      this.model.get('city').setValue(response.localidade);
      this.model.get('state').setValue(response.uf);
      this.model.get('country').setValue("Brasil");
      this.isLoadingCep = false;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  private timeoutKeySearch: any = null;

  onKeySearchCPF(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;

    let cpf = event.target.value.replace(/[^0-9]+/g, '');

    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && cpf.length == 11) {
        $this.searchByCPF(cpf.replace(/[^0-9]+/g, ''));
      }
    }, 1000)
      ;
  }

  onKeySearchCEP(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        $this.searchByCEP(event.target.value);
      }
    }, 1000);
  }

  onKeySearchAge(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13 && event.target.value.length > 5) {
        //$this.calculateAge(event.target.value);
        $this.getAge(event.target.value);
      }
    }, 1000);
  }

  getAge(dateString) {

    var daysInMonth = 30.436875; // Days in a month on average.

    dateString = this.toISOFormat(dateString);
    var dob = new Date(dateString);
    var aad;
    aad = new Date();
    var yearAad = aad.getFullYear();
    var yearDob = dob.getFullYear();
    var years = yearAad - yearDob; // Get age in years.
    dob.setFullYear(yearAad); // Set birthday for this year.
    var aadMillis = aad.getTime();
    var dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1); // Set to previous year's birthday
      dobMillis = dob.getTime();
    }
    var days = (aadMillis - dobMillis) / 86400000;
    var monthsDec = days / daysInMonth; // Months with remainder.
    var months = Math.floor(monthsDec); // Remove fraction from month.
    days = Math.floor(daysInMonth * (monthsDec - months));
    if (days < 0 || months < 0 || years < 0) {
      this.alertService.show("Aviso", "Data de nascimento do paciente não pode ser menor que a data de hoje!", AlertType.warning);
      this.model.get("birthDate").setValue("");
      this.patientAge = "";
      return;
    }
    this.patientAge = `${years}a ${months}m ${days}d`;
    return { years: years, months: months, days: days };
  }

  toISOFormat(dateTimeString) {
    // Primeiro, dividimos a data completa em duas partes:
    //const [date, time] = dateTimeString.split(' ');

    // Dividimos a data em dia, mês e ano:
    //const [DD, MM, YYYY] = date.split('/');
    const [DD, MM, YYYY] = dateTimeString.split('/');

    // Dividimos o tempo em hora e minutos:
    //const [HH, mm] = time.split(':');

    // Retornamos a data formatada em um padrão compatível com ISO:
    return `${YYYY}-${MM}-${DD}`;
  }
}

