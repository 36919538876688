<app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
<div class="white-body-test">
    <div class="title">
        <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound" (click)="playAudio()"/>
        <span i18n>RESULTADOS</span>
    </div>
    <app-result #result [patientData]="patientData" [riskFactorsData]="riskFactorsData" (emitPatientData)="populateShareRequest($event)" (emitAudioSrc)="populateAudioSource($event)"></app-result>
    <div class="result-observation">
        <span class="result-hint" i18n>*Lembre-se, os resultados do seu teste de saúde não são diagnósticos clínicos. Procure seu médico para saber mais.</span>
    </div>
</div>
<button mat-flat-button (click)="submit()" class="btn-go btn-ubs" [disabled]="this.model.invalid">
    <span i18n *ngIf="isLoading == false">FINALIZAR</span>
    <div>
        <mat-icon class="span-icon" >arrow_forward</mat-icon>
    </div>
</button>