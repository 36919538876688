<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="button-close">
            <div class="close-button">
                <mat-icon class="close-icon" mat-icon aria-hidden="false" aria-label="sound"
                    (click)="closeModal()">close</mat-icon>
            </div>
        </div>
        <div class="button-audio">
            <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
                (click)="playAudio()" />
        </div>
        <div class="row text-align">
            <span i18n><b>Cisgênero</b>: pessoa que se reconhece com o gênero designado ao nascimento;</span>
            <span class="margin-text" i18n><b>Transgênero</b> (ou a abreviação "Trans”): pessoa que não se identifica
                com o gênero designado
                ao nascer;</span>
            <span class="margin-text" i18n><b>Travestis</b>: a travesti é uma pessoa que vivencia e reivindica papéis do
                gênero feminino,
                com utilização de pronome equivalente, mas que não necessariamente irá se restringir a construção social
                binária do gênero, podendo variar entre elementos do masculino, feminino ou outras expressões;</span>
            <span class="margin-text" i18n><b>Mulheres trans</b>: são pessoas que se identificam com o gênero feminino,
                reivindicando
                reconhecimento social como tal, não necessariamente estando desconfortável com sua natureza biológica.
                Algumas mulheres trans podem demandar procedimentos cirúrgicos (prótese de silicone, silicone líquido
                industrial, cirurgia de redesignação sexual, vaginoplastia) e/ou ambulatoriais (hormonização) para sua
                transição de gênero, a fim de melhorar sua qualidade de vida e o bem estar biopsicossocial;</span>
            <span class="margin-text" i18n><b>Homens trans</b>: são pessoas que vivenciam e reivindicam reconhecimento
                enquanto homens,
                podendo necessitar ou não de procedimentos e processos ambulatoriais (hormonização) ou cirúrgicos
                (cirurgia de redesignação sexual, neofaloplastia, mamoplastia masculinizadora - mastectomia) para sua
                transição de gênero.</span>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class="modal-btn-back" (click)="goBack()">
            <div>
                <mat-icon class="span-icon">arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
    </div>
</div>