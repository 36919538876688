import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cpf-error-modal',
  templateUrl: './cpf-error-modal.component.html',
  styleUrls: ['./cpf-error-modal.component.css']
})
export class CpfErrorModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CpfErrorModalComponent>,
    public router: Router
  ) { }

  public noCPF: boolean;

  ngOnInit(): void {
    this.noCPF = this.data.noCPF;
  }

  finishSession(){
    this.matDialogRef.close();
  }
}
