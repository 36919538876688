import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsWelcomeService } from 'src/app/shared/services/session/utils-welcome.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css']
})
export class FinishComponent implements OnInit {

  constructor(
    private activateRoute: ActivatedRoute,
    private utilsWelcomeService: UtilsWelcomeService,
    public router: Router,
    ) { }
  public healthUnitName: string = "";

  ngOnInit(): void {
    this.healthUnitName = this.utilsWelcomeService.gethealthUnit().healthUnitName;
  }

  goToMeasurer(){
    this.router.navigate(['/company/measure']);
  }

}
