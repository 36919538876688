import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MeasureRequest } from '../../requests/sync-measurer/measure.request';


@Injectable({
  providedIn: 'root'
})
export class MeasureService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public sendMeasurements(measureRequest: MeasureRequest): Observable<ReturnStruct> {

    let url = `Measure`

    return this.httpClient.post<ReturnStruct>(environment.urlApiSyncMeasurer + url, measureRequest)
    .pipe(
      catchError(this.handleError)
    )
  }
}
