import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { UBSRequest } from 'src/app/shared/services/requests/orchestrator-patient/ubs.request';
import { PostUbsHealthPromotionSmsEmailRequest } from 'src/app/shared/services/requests/srv-email/post-ubs-health-promotion-sms-email.request';
import { PostCpfCnsResponse } from 'src/app/shared/services/responses/orchestrator-integration/post-cpf-cns.response';
import { UtilsUBSService, UBSConfigData, PatientData, RiskFactorsData } from '../../utils-ubs.service';
import { environment } from 'src/environments/environment';
import { ResultComponent } from './result/result.component';
import { MatDialog } from '@angular/material/dialog';
import { DowntimeService } from '../../downtime.service';

@Component({
  selector: 'app-result-resume',
  templateUrl: './result-resume.component.html',
  styleUrls: ['./result-resume.component.css']
})
export class ResultResumeComponent implements OnInit, OnDestroy {

  @ViewChild('result') result: ResultComponent;
  private title: string = 'birth-date-ubs-health-promotion';
  public patientData: PatientData = new PatientData();
  
  constructor(
    private maskService: MaskService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private utilsUBSService: UtilsUBSService,
    public dialog: MatDialog,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string
  ) {
      this.titleService.setTitle($localize`${this.title}`);
  }

  public model: FormGroup;
  public masks: Masks;
  public isLoading = true;
  public journeyStageId: number;  
  public audio = new Audio();
  public uBSConfigData: UBSConfigData;
  public riskFactorsData: RiskFactorsData;
  private idUbsScreen: number;
  public pageIndex: number;
  public patientRequest: PostUbsHealthPromotionSmsEmailRequest;
  public uBSRequest: UBSRequest = new UBSRequest();
  public postCpfCnsResponse: PostCpfCnsResponse;
  public audioSrc: string;
  public downtime: boolean;

  ngOnInit(): void {
    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });

    this.uBSConfigData = this.utilsUBSService.getUBSData();
    if (!this.uBSConfigData || !this.uBSConfigData.listUBSStruct) {
      this.downtimeService.stopTimer();
      this.router.navigate(['/ubs/erro']);
    }

    this.riskFactorsData = this.utilsUBSService.getRiskFactorsData();
    
    this.patientData = this.utilsUBSService.getPatientData();

    this.journeyStageId = JourneyStageEnum.Resultado;
    this.idUbsScreen = UbsScreenEnum.ResultResume;
    this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    if (this.pageIndex == -1) {
      this.downtimeService.stopTimer();
      this.router.navigate(['/ubs/erro']);
    }
    this.masks = this.maskService.getMasks();
    this.model = this.formBuilder.group({
      height: [''],
    });

    if(this.utilsUBSService.getPatientData()){
      this.patientData = this.utilsUBSService.getPatientData();
      this.model.get('height').setValue(this.patientData.height);
    }

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  async submit() {
    if (!this.model.valid) {
      return;
    }
    let height = this.model.get('height').value;
    this.patientData.height = height;

    this.utilsUBSService.createPatientData(this.patientData);
    this.downtimeService.stopTimer();
    await this.openSharingMeasurement();
  }

  playAudio(){
    this.audio.src = `../assets/audio/${environment.locale}/${this.audioSrc}.mp3`;
    this.audio.load();
    this.audio.play();
  }

  populateShareRequest(event: PostUbsHealthPromotionSmsEmailRequest){
    this.patientRequest = event;
  }

  async openSharingMeasurement(){
    await this.result.mapToStruct();
    await this.populateRequest();

    this.downtimeService.stopTimer();
    this.router.navigate(['ubs/sharing-measurement-results']);
  }

  async populateRequest(){

    //dados pessoais
    this.uBSRequest.uBSStruct.cns = this.patientData.cns;
    this.uBSRequest.uBSStruct.patientCpf = this.patientData.cpf;
    this.uBSRequest.uBSStruct.idGender = this.patientData.idGender;
    this.uBSRequest.uBSStruct.idPatient = this.patientData.idPatient;
    this.uBSRequest.uBSStruct.patientBirthDate = this.patientData.birthDate;
    this.uBSRequest.uBSStruct.socialName = this.patientData.socialName;
    this.uBSRequest.uBSStruct.motherName = this.patientData.motherName;
    this.uBSRequest.uBSStruct.name = this.patientData.name;
    this.uBSRequest.uBSStruct.fatherName = this.patientData.fatherName;
    this.uBSRequest.uBSStruct.householdIncome = this.patientData.householdIncome;
    this.uBSRequest.uBSStruct.idEthnicity = this.patientData.ethnicity;

    //dados leitura
    this.uBSRequest.uBSStruct.diastolicPressure = this.patientData.diastolicPressure;
    this.uBSRequest.uBSStruct.systolicPressure = this.patientData.sistolicPressure;
    this.uBSRequest.uBSStruct.height = this.patientData.height;
    this.uBSRequest.uBSStruct.weight = this.patientData.weight;
    this.uBSRequest.uBSStruct.imc = this.patientRequest.imc;
    this.uBSRequest.uBSStruct.paCathegory = this.patientRequest.paCathegory;
    
    //fatores de risco
    this.uBSRequest.uBSStruct.hasDiabetes = this.riskFactorsData.haveDiabetes;
    this.uBSRequest.uBSStruct.hasHighCholesterol = this.riskFactorsData.haveHighCholesterol;
    this.uBSRequest.uBSStruct.hasHypertension = this.riskFactorsData.haveHighPressure;
    this.uBSRequest.uBSStruct.tookHypertensionMedication = this.riskFactorsData.havePressureRemedy;
    this.uBSRequest.uBSStruct.takeMedicineCholesterol = this.riskFactorsData.haveTakeMedicineCholesterol;
    this.uBSRequest.uBSStruct.trackWeighWithProfessional = this.riskFactorsData.haveTrackWeighWithProfessional;
    this.uBSRequest.uBSStruct.treatedDiabetes = this.riskFactorsData.haveTreatedDiabetes;
    this.uBSRequest.uBSStruct.haveOverweightObesity = this.riskFactorsData.haveTrackWeighWithProfessional;
    
    //resto
    this.uBSRequest.uBSStruct.idHealthUnit = this.uBSConfigData.idHealthUnit;
    this.uBSRequest.uBSStruct.listIdHealthUnitInGroup = this.utilsUBSService.getHealthUnit().listIdHealthUnitInGroup;
    this.uBSRequest.uBSStruct.optin = this.patientData.optIn;

    this.utilsUBSService.createUBSPatientRequest(this.uBSRequest);
  }

  populateAudioSource(event: string){
    this.audioSrc = event;
  }
}