<div class="container-body ubs">
    <h1>
        <img class="audio-icon" src="assets/icons/btn-sound.png" aria-hidden="false" aria-label="sound"
            (click)="playSound()" />
        <span i18n>TODOS OS DADOS COLETADOS COM SUCESSO</span>
    </h1>
    <div class="checkbox-group">
        <div id="txt-decription">
            <div class="icon-check">
                <mat-icon>check</mat-icon>
            </div>
            <h2 i18n>Conseguimos coletar sua identificação, pressão arterial, peso e altura com sucesso! Clique em
                avançar para ver os resultados.</h2>
        </div>
    </div>
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon aria-hidden="false">arrow_back</mat-icon>
            </div>
            <span *ngIf="isLoading == false"><span i18n>Voltar</span></span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
        <button mat-flat-button class="block btn-go btn-ubs" (click)="submit()">
            <span *ngIf="isLoading == false"><span i18n>Avançar</span></span>
            <div>
                <mat-icon aria-hidden="false">arrow_forward</mat-icon>
            </div>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>