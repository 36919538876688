import { Injectable } from "@angular/core";
import { PatientStruct } from "../structs/medical-record/patient.struct";
import { MeasureStruct } from "../structs/sync-measurer/measure.struct";
import { SyncMeasurerStruct } from "../structs/sync-measurer/sync-measure.struct";
import { HealthUnitStruct } from "../structs/user/health-unit.struct";

@Injectable({
    providedIn: 'root'
})
export class UtilsWelcomeService {
    constructor() { }


    updatehealthUnit(healthUnit: HealthUnitStruct): void {
        sessionStorage.setItem("healthUnit", JSON.stringify(healthUnit));
    }

    removehealthUnit(): void {
        sessionStorage.removeItem("healthUnit");
    }

    gethealthUnit(): HealthUnitStruct {
        let healthUnit: HealthUnitStruct = JSON.parse(sessionStorage.getItem("healthUnit"))
        return healthUnit;
    }

    updateSyncMeasurer(syncMeasurer: SyncMeasurerStruct): void {
        sessionStorage.setItem("syncMeasurer", JSON.stringify(syncMeasurer));
    }

    removeSyncMeasurer(): void {
        sessionStorage.removeItem("syncMeasurer");
    }

    getSyncMeasurer(): SyncMeasurerStruct {
        let syncMeasurer: SyncMeasurerStruct = JSON.parse(sessionStorage.getItem("syncMeasurer"))
        return syncMeasurer;
    }

    getIdPatientSyncMeasurer(): number {
        let idPatientSyncMeasurer: number = JSON.parse(sessionStorage.getItem("idPatientSyncMeasurer"))
        return idPatientSyncMeasurer;
    }

    updateIdPatientSyncMeasurer(idPatientSyncMeasurer: number): void {
        sessionStorage.setItem("idPatientSyncMeasurer", JSON.stringify(idPatientSyncMeasurer));
    }

    removeIdPatientSyncMeasurer(): void {
        sessionStorage.removeItem("idPatientSyncMeasurer");
    }

    updatePatient(patient: PatientStruct) {
        sessionStorage.setItem("patient", JSON.stringify(patient));
    }

    removePatient() {
        sessionStorage.removeItem("patient");
    }

    getPatient() {
        let patient: PatientStruct = JSON.parse(sessionStorage.getItem("patient"))
        return patient;
    }

    updateHash(hash: string) {
        sessionStorage.setItem("hash", JSON.stringify(hash));
    }
    removeHash() {
        sessionStorage.removeItem("hash");
    }
    getHash() {
        let hash: string = JSON.parse(sessionStorage.getItem("hash"))
        return hash;
    }
    setListPatient(listPatient: PatientStruct[]) {
        localStorage.setItem('listPatient', JSON.stringify(listPatient));
    }

    getListPatient(): PatientStruct[] {
        let listPatient: PatientStruct[] = JSON.parse(localStorage.getItem("listPatient"))
        return listPatient;
    }

    storeMeasureList(listMeasureStruct: MeasureStruct[]) {
        localStorage.setItem("listMeasureStruct", JSON.stringify(listMeasureStruct));
    }

    getMeasureList() {
        let listMeasureStruct: MeasureStruct[] = JSON.parse(localStorage.getItem("listMeasureStruct"))
        return listMeasureStruct;
    }
}