import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JourneyStageEnum } from 'src/app/shared/enum/journey-stage.enum';
import { UbsScreenEnum } from 'src/app/shared/enum/ubs-screen.enum';
import { UBSStruct } from 'src/app/shared/services/structs/ubs-health-promotion/ubs-struct';
import { environment } from 'src/environments/environment';
import { DowntimeService } from '../../downtime.service';
import { UBSConfigData, UtilsUBSService } from '../../utils-ubs.service';

@Component({
  selector: 'app-success-measurements',
  templateUrl: './success-measurements.component.html',
  styleUrls: ['./success-measurements.component.css']
})
export class SuccessMeasurementsComponent implements OnInit, OnDestroy {

  private title: string = 'success-measurements';

  constructor(private utilsUBSService: UtilsUBSService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private titleService: Title,
    private downtimeService: DowntimeService,
    @Inject(LOCALE_ID) public locale: string

  ) { this.titleService.setTitle($localize`${this.title}`); }

  public isLoading: boolean = false;
  private thisScreen: UbsScreenEnum = UbsScreenEnum.SuccessMeasurements;
  public order: number;
  onlineOffline: boolean = navigator.onLine;
  public ubsData: UBSConfigData;
  public idUbsScreen: number;
  public ubsStruct: UBSStruct;
  public uBSConfigData: UBSConfigData;
  public isError = false;
  public pageIndex: number;
  public audio = new Audio();
  public downtime: boolean;

  ngOnInit(): void {
    this.idUbsScreen = UbsScreenEnum.SuccessMeasurements;

    this.uBSConfigData = this.utilsUBSService.getUBSData();

    if (this.uBSConfigData && this.uBSConfigData != null && this.uBSConfigData.listUBSStruct.length > 0)
      this.pageIndex = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.idUbsScreen);
    else
      this.router.navigate(['/ubs/erro']);

    this.downtimeService.startTimer(300000);
    this.downtimeService.watcher().subscribe((res) => {
      if (res) {
        if (alert) {
          this.utilsUBSService.createDowntime(this.downtime = true);
          this.downtimeService.stopTimer();
          this.router.navigateByUrl('ubs/welcome');
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.audio.pause();
    this.downtimeService.stopTimer();
  }

  submit() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    this.saveLastPage();
    this.router.navigate(["ubs" + this.uBSConfigData.listUBSStruct[this.pageIndex + 1].screenUrl]);
  }

  playSound() {
    this.audio.src = `../../../assets/audio/${environment.locale}/success-measurements.mp3`;
    this.audio.load();
    this.audio.play();
  }

  goBack() {
    this.audio.pause();
    this.downtimeService.stopTimer();
    let screen = this.uBSConfigData.listUBSStruct.findIndex(x => x.idUBSScreen == this.thisScreen.valueOf());
    if (screen == -1)
      this.router.navigate(['/ubs/erro']);

    let lastScreen = this.uBSConfigData.listUBSStruct[screen - 1];
    if (!lastScreen)
      this.router.navigate(['/ubs/erro']);


    let visitedPages = this.utilsUBSService.getListVisitidePages();
    let lastPage = visitedPages.pop()
    this.utilsUBSService.updateListVisitidePages(visitedPages);
    this.router.navigate([lastPage]);

    //this.router.navigate(['ubs' + lastScreen.screenUrl]);
  }

  endFlowMeasurer() {
    this.downtimeService.stopTimer();
    let lastScreen = this.uBSConfigData.listUBSStruct[0];
    if (!lastScreen)
      this.router.navigate(['/ubs/erro']);

    this.router.navigate(['ubs' + lastScreen.screenUrl]);
  }

  saveLastPage(){
    let visitedPages = this.utilsUBSService.getListVisitidePages();
    if(!visitedPages)
      visitedPages = [];
    visitedPages.push(this.router.url);
    this.utilsUBSService.updateListVisitidePages(visitedPages);
  }
}