<div mat-dialog-content class="content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <h3>Insira o valor medido no aparelho</h3>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Insira o valor da Frequência Cardíaca</mat-label>
                    <input matInput type="text" formControlName="heartRate" [mask]="masks.threeDigits">
                    <span matSuffix>bpm</span>
                    <mat-error *ngIf="model.get('heartRate').invalid">Informe a frequência!</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline">
                    <mat-label>Insira o valor da Saturação</mat-label>
                    <input matInput type="text" formControlName="oximeter" [mask]="masks.threeDigits">
                    <span matSuffix>%</span>
                    <mat-error *ngIf="model.get('oximeter').invalid">Informe a saturação!</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button mat-flat-button type="submit" color="primary" class=" btn-block">
                    <span>Confirmar</span>
                </button>
                <a mat-flat-button type=" button" class="btn-secudary btn-block" (click)="clickClose()">
                    <span>Cancelar</span>
                </a>
            </div>
        </div>
    </form>
</div>