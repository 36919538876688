<div class="container ubs">
    <app-journey-stage [journeyStageId]="journeyStageId"></app-journey-stage>
    <div class="white-body-test">
        <div class="row">
            <div class="col-12">
                <div class="audio-icon">
                    <img src="assets/images/audio-icon.png" (click)="playAudio()"/>
                </div>
                <div class="title">
                    <span i18n>QUAL É A SUA ALTURA?</span>
                </div>
            </div>        
        </div>
        
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <mat-form-field >
                <input matInput type="text" formControlName="height" [mask]="masks.height"
                placeholder="Exemplo 1,65m"  inputmode="numeric">
                <mat-hint><span class="span-hint" i18n>Se você não souber, deixe em branco</span></mat-hint>
            </mat-form-field>
        </form>
    </div>
    
    <div class="button-container">
        <button mat-flat-button class="block btn-back" (click)="goBack()">
            <div>
                <mat-icon class="span-icon" >arrow_back</mat-icon>
            </div>
            <span i18n *ngIf="isLoading == false">VOLTAR</span>
        </button>
        <button mat-flat-button (click)="submit()" class="block btn-go btn-ubs" [disabled]="this.model.invalid">
            <span i18n *ngIf="isLoading == false">AVANÇAR</span>
            <div>
                <mat-icon class="span-icon" >arrow_forward</mat-icon>
            </div>
        </button>
    </div>
</div>