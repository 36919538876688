
export class PatientAnamnesisStruct {
    public idPatient: number;
    public datetimeInclusion?: Date;
    public listQuestionAnswer: QuestionAnswerStruct[];
}

export class QuestionAnswerStruct{
    public idQuestion: number;
    public question?: string;
    public listAnswer: AnswerStruct[];
    public othersDescription?: string;
}

export class AnswerStruct{
    public idAnswer?: number;
    public answer?: string;
}